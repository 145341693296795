import gql from 'graphql-tag';

export default gql`
  mutation testDestinationMutation(
    $input: RelayTestDestinationDestinationInput!
  ) {
    testDestination(input: $input) {
      success
    }
  }
`;
