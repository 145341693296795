import { useQuery } from '@apollo/react-hooks';
import { Destination } from '@sonic-web-dev/types';
import { destinations as destinationsQuery } from '@/graphql/queries';

interface CustomDestination extends Omit<Destination, 'destinationType'> {
  destinationType?: 'email' | 'url' | 'api';
}

const useDestinations = (agentID: string) => {
  const { data, loading, error, refetch } = useQuery(destinationsQuery, {
    variables: {
      agentID,
    },
  });

  return {
    loading,
    error,
    refetch,
    destinations:
      ((data || {}) as { destinations: CustomDestination[] })?.destinations ||
      [],
  };
};

export default useDestinations;
