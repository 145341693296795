import { getImageUrl } from '@/utils/get-host';
import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Media,
} from 'reactstrap';

interface AuthHeaderProps {
  cardTitle: string;
}

const AuthHeader: React.FunctionComponent<AuthHeaderProps> = ({
  children,
  cardTitle,
}) => {
  return (
    <div className="h-100 d-flex w-100">
      <div className="bg-secondary w-100 d-md-none d-lg-flex align-items-center justify-content-center d-xs-none d-sm-none px-5">
        {!window.location.href.includes('ushamarketplace') ? (
          <Media
            src={getImageUrl('logo.png')}
            className="w-100 mb-5"
            style={{ maxWidth: 1000 }}
          />
        ) : (
          <div>
            <h1>USHA Marketplace</h1>
          </div>
        )}
      </div>

      <div className="auth-triangle d-xs-none d-sm-none d-md-none d-lg-block" />
      <Container className="w-100 h-100 d-flex justify-content-lg-center justify-content-md-start align-items-center flex-column">
        {!window.location.href.includes('ushamarketplace') ? (
          <Media
            src={getImageUrl('square.png')}
            className="mb-3 d-lg-none d-xl-none d-md-block"
            style={{ height: 100 }}
          />
        ) : (
          <Media className="mt-5 d-lg-none d-xl-none d-md-block" tag="h2">
            USHA Marketplace
          </Media>
        )}
        <Card style={{ maxWidth: 500, minWidth: 500 }} className="mt-md-3">
          <CardHeader>
            <CardTitle tag="h5" className="py-2 h4 mb-0">
              {cardTitle}
            </CardTitle>
          </CardHeader>
          <CardBody>{children}</CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AuthHeader;
