import gql from 'graphql-tag';

export default gql`
  query getTextdripCampaigns($apiToken: String!) {
    getTextdripCampaigns(apiToken: $apiToken) {
      title
      tdCampaignID
    }
  }
`;
