import gql from 'graphql-tag';
import { TransferFragment } from '../fragments';

export default gql`
  mutation transferMutation($input: RelayCreditTransferByEmailAgentInput!) {
    creditTransferByEmail(input: $input) {
      ...TransferFragment
    }
  }
  ${TransferFragment}
`;
