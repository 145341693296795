/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { useNotifications, useUser } from '@/hooks';
import moment from 'moment';
import Progress from 'react-progressbar';
import { FaEdit, FaTrashAlt, FaExclamationTriangle } from 'react-icons/fa';

import ButtonIcon from '@/components/ButtonIcon';
import { ButtonToggle } from '@/components';
import { campaignTogglePause } from '@/graphql/mutations';
import formatCurrencyForDisplay from '@/areas/MyAgents/Utils';
import {
  getSpendingPeriodsString,
  getProgress,
  getProgressColor,
  sumSpendingPeriods,
} from '../Utils';

const getPriceDescription = (priceTier: any): string => {
  if (priceTier.dynamicPrice) {
    return `Price: ${formatCurrencyForDisplay(
      priceTier.minPrice
    )} - ${formatCurrencyForDisplay(
      priceTier.maxPrice
    )} (Avg: ${formatCurrencyForDisplay(priceTier.price)})`;
  }
  if (priceTier.liveTransfers) {
    return `Call Price: ${formatCurrencyForDisplay(
      priceTier.liveTransferPrice
    )}`;
  }
  return `${formatCurrencyForDisplay(priceTier.price)}`;
};

const CampaignTableRow = (props: any) => {
  const {
    id,
    campaignName,
    agentID,
    campaignID,
    spendingPeriods = [],
    navigateToEditCampaignPage,
    paused,
    setCurrentCampaign,
    openModal,
    updatingPause,
    priceTier,
    sidelineIntegration,
    destinationErrorMessage,
  } = props;

  const { create: createNotification } = useNotifications();
  const { user } = useUser();

  const [togglePause, { loading: updatingSingle }] = useMutation(
    campaignTogglePause,
    {
      update(cache, data) {
        const {
          campaignTogglePause: { value },
        } = data.data as any;

        cache.writeData({
          id,
          data: {
            paused: value,
          },
        });

        createNotification({
          message: 'Successfully updated',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
          timeout: 1000,
        });
      },

      onError: (error) =>
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          color: 'danger',
          type: 'alert',
          action: 'MESSAGE',
          timeout: 3000,
        }),
    }
  );

  const currentMoment = moment();

  const currentDailySpend = 0;
  const maxDailySpend = 0;

  const progress = spendingPeriods.map((period) =>
    getProgress(period, currentMoment)
  );

  const hasSpendingPeriods = useMemo(() => spendingPeriods.length > 0, [
    spendingPeriods.length,
  ]);

  const toggleSwitch = (): void => {
    if (user.totalCredit <= 0 && paused) {
      createNotification({
        message:
          'You have $0.00 in your account, please add funds or consider enabling auto-funding.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
        timeout: 4000,
      });
      return;
    }
    if (hasSpendingPeriods)
      togglePause({
        variables: {
          input: {
            IDs: [campaignID],
            on: !paused,
          },
        },
      });
  };

  return (
    <tr>
      <td>
        {campaignName}
        <br />
        <span className="text-muted">
          <i>{priceTier.description}</i>{' '}
        </span>
        <br />
        <span className="text-muted">{getPriceDescription(priceTier)}</span>
      </td>
      <td className="text-center" data-testid={`toggle-switch-${campaignID}`}>
        <ButtonToggle
          active={!paused && hasSpendingPeriods}
          loading={updatingPause || updatingSingle}
          onToggle={toggleSwitch}
          disabled={!hasSpendingPeriods}
        />
      </td>
      <td className="text-left">
        {!paused &&
          progress.map(([dailySpend, maxSpend]: any[], idx: number) => {
            const completed = (dailySpend / maxSpend) * 100;

            return (
              <div
                key={idx}
                style={{
                  backgroundColor: 'lightgray',
                  marginBottom: '5px',
                }}
                title={`${formatCurrencyForDisplay(
                  dailySpend
                )} / ${formatCurrencyForDisplay(maxSpend)}`}
              >
                <Progress
                  color={getProgressColor(
                    completed,
                    dailySpend === 0 && maxSpend === 0
                  )}
                  completed={completed}
                />
              </div>
            );
          })}
        {hasSpendingPeriods ? (
          <>
            {getSpendingPeriodsString(spendingPeriods)}
            {currentDailySpend
              ? ` (${formatCurrencyForDisplay(
                  maxDailySpend - currentDailySpend
                )} remaining today)`
              : ''}
          </>
        ) : (
          <div className="d-flex align-items-center">
            <FaExclamationTriangle className="mr-2" color="#F39C12" /> NO
            SCHEDULE SET
          </div>
        )}
        {sidelineIntegration ? (
          <div style={{ color: 'yellow' }}> Sideline Integrated </div>
        ) : (
          ''
        )}
        {destinationErrorMessage ? (
          <div style={{ color: 'red' }}>
            {' '}
            <FaExclamationTriangle className="mr-2" color="red" />{' '}
            {`${destinationErrorMessage}`}{' '}
          </div>
        ) : (
          ''
        )}
      </td>
      <td>
        <Button
          color={hasSpendingPeriods ? 'secondary' : 'success'}
          onClick={() => {
            openModal(`edit_spending_period:${campaignID}`);
            setCurrentCampaign();
          }}
          style={{ marginRight: '15px' }}
        >
          {hasSpendingPeriods
            ? 'Edit spending periods'
            : 'Add spending periods'}
        </Button>
      </td>
      <td>
        {formatCurrencyForDisplay(sumSpendingPeriods(spendingPeriods))}/wk
      </td>
      <td>
        <ButtonGroup>
          <ButtonIcon
            color="primary"
            iconTag={FaEdit}
            onClick={navigateToEditCampaignPage}
            title="Edit campaign"
          />
          <ButtonIcon
            color="danger"
            disabled={!paused}
            iconTag={FaTrashAlt}
            data-testid={`delete-${campaignID}`}
            onClick={() => {
              openModal('delete_campaign');
              setCurrentCampaign();
            }}
            title={
              paused
                ? 'Delete campaign'
                : 'You must first pause the campaign before it can be deleted'
            }
          />
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default CampaignTableRow;
