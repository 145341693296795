import gql from 'graphql-tag';
import { NetworkFragment } from '@/graphql/fragments';

export default gql`
  query network($networkID: String!) {
    network(networkID: $networkID) {
      ...NetworkFragment
    }
  }
  ${NetworkFragment}
`;
