import gql from 'graphql-tag';

const NetworkFragment = gql`
  fragment NetworkFragment on Network {
    __typename
    id
    brokerID
  }
`;
export default NetworkFragment;
