export { default as Vars } from './env-var';

export const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrencyForDisplay = (centsInteger: number): string =>
  `$${(centsInteger / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const MS_PER_DAY = 86400000;

export const MS_PER_YEAR = MS_PER_DAY * 365;

export const MS_PER_99_YEARS = MS_PER_YEAR * 99;

// Because I dont feel like renaming everything within the project
let n;
const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;
if (isSupported()) {
  n = Notification;
}
export const BrowserNotification = n;

export const bulkPrice = (tier: any, total: number): number => {
  if (!tier?.priceBreakPoints) {
    return tier.price;
  }
  const { priceBreakPoints } = tier;
  let discountedPrice = tier.price;
  priceBreakPoints.forEach((bp: any) => {
    if (total >= bp.breakPoint) {
      discountedPrice = bp.breakPointPrice;
    }
  });
  return discountedPrice;
};
