import React, { useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';

import { NotificationContext } from '@/context';
import { campaignPagination } from '@/graphql/queries';
import { deleteCampaignMutation } from '@/graphql/mutations';
import { useCampaignsNetwork } from '@/hooks';

const DeleteCampaignModal: React.FC<any> = ({ closeModal, campaign }) => {
  const { campaignName, campaignID } = campaign;

  const { create } = useContext(NotificationContext);
  const { networkID } = useCampaignsNetwork();

  const [deleteCampaign] = useMutation(deleteCampaignMutation, {
    update(cache) {
      const currentData: any = cache.readQuery({
        query: campaignPagination,
        variables: {
          networkID,
        },
      });

      cache.writeQuery({
        query: campaignPagination,
        data: {
          campaigns: currentData.campaigns.filter(
            (campaign: any) => campaign.campaignID !== campaignID
          ),
        },
        variables: {
          networkID,
        },
      });

      create({
        message: 'Successfully deleted',
        timeout: 1000,
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
    },
  });
  const Delete = () => {
    deleteCampaign({
      variables: {
        input: {
          campaignID,
        },
      },
    });
    closeModal();
  };

  return (
    <Modal isOpen toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Confirm campaign deletion</ModalHeader>
      <ModalBody>
        <span>
          Are you sure you want to delete{' '}
          <strong className="text-danger">{campaignName}</strong>?
        </span>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={closeModal}>Dismiss</Button>
          <Button
            data-testid={`delete-campaign-${campaignID}`}
            color="danger"
            onClick={Delete}
          >
            Delete campaign
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteCampaignModal;
