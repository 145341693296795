import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { profileNodeQuery } from '@/graphql/queries';
import { useUser } from '@/hooks';

const useProfile = (): any => {
  const match = useRouteMatch<{
    id: string;
  }>('/profile/:id');
  const id = match?.params.id;

  const history = useHistory();
  const queryResult = useQuery(profileNodeQuery, {
    variables: {
      id,
    }, // TODO
    skip: id === 'me',
  });

  const { user, loading: loadingUser, error } = useUser();

  if (!queryResult.loading && id !== 'me' && !queryResult.data) {
    // TODO - maybe notify user this is bad
    history.push('/');
  }

  if (id === 'me') {
    return {
      data: user,
      loading: loadingUser,
      error,
    };
  }

  return {
    data: queryResult.data?.node,
    loading: queryResult.loading,
    error: queryResult.error,
  };
};

export default useProfile;
