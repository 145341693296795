import { StringSchema } from 'yup';

export const getPrivacyPolicy = (corpID: string): string => {
  if (corpID === 'leadtopia') {
    return 'https://s3.amazonaws.com/docs.leadtopia.com/policies/Leadtopia+Privacy+Policy.pdf';
  }
  if (corpID === 'americafirst') {
    return 'https://s3.amazonaws.com/docs.americafirstleads.com/policies/AmericaFirstLeads_Privacy_Policy.pdf';
  }
  return 'https://s3.amazonaws.com/docs.sonicwebdev.com/privacy-policy.pdf';
};

export const getTermsAndConditions = (corpID: string): string => {
  if (corpID === 'leadtopia') {
    return 'https://s3.amazonaws.com/docs.leadtopia.com/policies/Leadtopia+Terms+%26+Conditions.pdf';
  }
  if (corpID === 'americafirst') {
    return 'https://s3.amazonaws.com/docs.americafirstleads.com/policies/AmericaFirstLeads_Terms_and_Conditions.pdf';
  }

  return 'https://s3.amazonaws.com/docs.sonicwebdev.com/terms-and-conditons.pdf';
};

export const getDataDisclosure = (corpID: string): string => {
  if (corpID === 'leadtopia') {
    return 'https://s3.amazonaws.com/docs.leadtopia.com/policies/Leadtopia+Data+Disclosure.pdf';
  }
  if (corpID === 'americafirst') {
    return 'https://s3.amazonaws.com/docs.americafirstleads.com/policies/AmericaFirstLeads_Data_Disclosure.pdf';
  }
  return 'https://s3.amazonaws.com/docs.sonicwebdev.com/data-disclosure.pdf';
};
