/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Row,
  Col,
  InputGroup,
  Button,
  Spinner,
  ButtonProps,
} from 'reactstrap';
import qs from 'querystring';
import { Storage } from 'aws-amplify';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import {
  FaSort,
  FaSortDown,
  FaSortUp,
  FaCalendarMinus,
  FaSync,
} from 'react-icons/fa';

import { Formik } from 'formik';
import { SelectField } from '@availity/select';
import { DateRangeField } from '@availity/date';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { leadPagination, leadCampaigns, reports } from '@/graphql/queries';
import {
  LeadsRefundModal,
  LeadsShowExportModal,
  TableOptionsModal,
} from '@/modals';
import { availableColumns } from '@/utils/leadTableHelpers';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import {
  SearchControls,
  FilterSelect,
  SearchSummary,
  LoadingComponent,
} from '@/components';
import { useNotifications, useUser } from '@/hooks';
import { useHistory, useLocation } from 'react-router';
import TableRow from './components/TableRow';

// const leadTypeOptions = [
//   {
//     label: 'Marketplace',
//     value: 'vendor',
//   },
//   {
//     label: 'Agent Direct',
//     value: 'agentDirect',
//   },
//   {
//     label: 'Live Transfer',
//     value: 'liveTransfer',
//   },
// ];

const initialColumns = localStorage.getItem('leads-table-columns')?.split(',');

const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

const RefreshButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { loading } = usePagination();

  return (
    <Button {...props} color="primary" className="mr-3" disabled={loading}>
      {loading ? <Spinner size="sm" /> : <FaSync />}
    </Button>
  );
};

const headerTitle = ({
  totalCredit,
  liveTransferOnlyView,
  callerReadyAccountStatus,
  callerReadyStatus,
  lastCallerReadyChargeResult,
}: any): any => {
  if (!liveTransferOnlyView) {
    return 'My Leads';
  }
  if (
    callerReadyAccountStatus === 'paused' &&
    lastCallerReadyChargeResult === 'Success'
  ) {
    return <span style={{ color: 'red' }}>Your account is paused</span>;
  }

  if (
    callerReadyAccountStatus === 'paused' &&
    lastCallerReadyChargeResult === 'Failure'
  ) {
    return (
      <span style={{ color: 'red' }}>
        Account paused due to a payment issue
      </span>
    );
  }

  if (callerReadyStatus === 'paused') {
    return (
      <span style={{ color: 'orange' }}>
        Calling paused due to a low balance -{' '}
        {formatCurrencyForDisplay(totalCredit)}
      </span>
    );
  }

  return `My Calls - Available Credit: ${formatCurrencyForDisplay(
    totalCredit
  )}`;
};

const Leads: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSearch = useMemo(() => qs.parse(location.search.slice(1)), []);
  const { newLeadCount, create: createNotification } = useNotifications();
  const [refreshCount, setRefreshCount] = useState(0);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedLeadID, setSelectedLeadID] = useState(undefined); // For lead refund
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const [elasticQuery, setElasticQuery] = useState({});

  const { data: rData = {}, loading: reportLoading } = useQuery(reports);

  const { reports: reportData = [] } = rData;
  const liveTransferOnlyView = location.pathname === '/live-transfers';

  const noGeneratingReports = reportData.every(
    ({ status }: any) => status !== 'GENERATING'
  );

  const {
    user: {
      agentID,
      role,
      marketplace,
      network,
      callerReadyAccountStatus,
      callerReadyStatus,
      totalCredit,
      lastCallerReadyChargeResult,
    },
    loading,
  } = useUser();

  const corpID = network?.corpID;

  const getLeadTypeOption = (): any => {
    if (liveTransferOnlyView) {
      return [
        {
          label: 'Live Transfer',
          value: 'liveTransfer',
        },
      ];
    }
    return [
      {
        label: 'Marketplace',
        value: 'vendor',
      },
      {
        label: 'Agent Direct',
        value: 'agentDirect',
      },
      {
        label: 'Live Transfer',
        value: 'liveTransfer',
      },
    ];
  };

  const exportItems = async (): Promise<void> => {
    setShowExportModal(true);
    const filePath = `leads/exports/searchPayload-${moment().format()}.json`;
    Storage.vault.put(
      filePath,
      JSON.stringify({
        ...elasticQuery,
        fields: localStorage
          .getItem('leads-table-columns')
          ?.split(',')
          .filter((item: string) => item !== 'result'),
      }),
      {
        level: 'private',
        contentType: 'application/json',
        bucket: `report-filters-${Vars.env}`,
        region: 'us-east-1',
      }
    );
  };

  if (loading) return <LoadingComponent />;
  return (
    <Formik<{
      size: number;
      searchText: string;
      sort: any;
      dateRange: any;
      campaign?: any;
      // rightFilter?: any;
      columns: string[];
      leadType?: string;
    }>
      initialValues={{
        size: localStorage.getItem('leads-table-page-size')
          ? // eslint-disable-next-line radix
            parseInt(localStorage.getItem('leads-table-page-size')!)
          : 25,
        searchText: initialSearch.q || '',
        sort: {
          soldDate: 'DESC',
        },
        dateRange: {
          startDate: moment({ hour: 0 })
            .subtract(6, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment({ hour: 0 }).format('YYYY-MM-DD'),
        },
        columns: availableColumns(liveTransferOnlyView)
          .filter(
            (aC) =>
              !initialColumns ||
              initialColumns.find(
                (c) =>
                  c === aC.dataField &&
                  (!aC.visible || aC.visible(role, corpID))
              )
          )
          .map((aC) => aC.dataField),
        campaign: undefined,
        leadType: liveTransferOnlyView ? 'liveTransfer' : undefined,
        // rightFilter: undefined,
      }}
      validationSchema={yup.object().shape({
        size: yup.number(),
        searchText: yup.string(),
        dateRange: dateRange({
          min: moment().subtract(120, 'day').format('MM/DD/YYYY'),
          max: moment().add(1, 'day').format('MM/DD/YYYY'),
        }),
        sort: yup.object(),
        campaign: yup.string().nullable(), // campaignID
        // rightFilter: yup.string().nullable(), // vendorName || none for transfers
        leadType: yup.string().nullable(),
      })}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, errors }) => {
        const client = useApolloClient();

        useEffect(() => {
          localStorage.setItem(
            'leads-table-columns',
            values.columns.toString()
          );
        }, [values.columns]);

        useEffect(() => {
          localStorage.setItem('leads-table-page-size', values.size.toString());
        }, [values.size]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          if (errors && errors.dateRange) {
            return {
              totalCount: 0,
              items: [],
            };
          }

          const soldDate = {
            gte:
              values.dateRange.startDate ||
              moment({ hour: 0 }).subtract(5, 'years').format(),
            lte: values.dateRange.endDate || moment({ hour: 0 }).format(),
          };
          console.log(location.pathname);
          const filter = {
            q: values.searchText,
            campaignID: values.campaign?.campaignID,
            // vendorName: values.rightFilter?.key || undefined,
            leadType:
              values.leadType ||
              (liveTransferOnlyView ? 'liveTransfer' : undefined),
            soldDate,
          };
          console.log(filter);
          setElasticQuery({
            type: corpID === 'leadtopia' ? 'lead-leadtopia' : 'lead',
            folderID: agentID,
            agentID, // add back for now so we can be safe for new and old
            filter: {
              agentID,
              dateField: 'soldDate',
              startDate:
                values.dateRange.startDate ||
                moment({ hour: 0 }).subtract(5, 'years').format(),
              endDate: values.dateRange.endDate || moment({ hour: 0 }).format(),
              ...filter,
            },
          });
          const response = await client.query<any>({
            query: leadPagination,
            fetchPolicy: 'network-only',
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter,
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.leadPagination.items;

          return {
            totalCount: response.data.leadPagination.count,
            items: currentPageData,
          };
        };

        const getSortIcon = (dataField: string): React.ReactNode => {
          const { [dataField]: sort } = values.sort;

          if (sort === 'DESC') {
            return <FaSortDown />;
          }

          if (sort === 'ASC') {
            return <FaSortUp />;
          }

          return <FaSort />;
        };

        const updateSort = (dataField: string): void => {
          const { [dataField]: sort, ...restSort } = values.sort;

          if (!sort) {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'DESC',
            });
          } else if (sort === 'DESC') {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'ASC',
            });
          } else {
            setFieldValue('sort', restSort);
          }
        };

        const displayColumns = useMemo(
          () =>
            availableColumns(liveTransferOnlyView).filter((aC) =>
              values.columns.find(
                (c) =>
                  c === aC.dataField &&
                  (!aC.visible || aC.visible(role, corpID))
              )
            ),
          [values.columns]
        );

        return (
          <>
            <Pagination
              items={fetchItems}
              itemsPerPage={values.size}
              watchList={[values.sort, selectedLeadID]}
              resetParams={[
                values.size,
                values.campaign,
                // values.rightFilter,
                values.leadType,
                values.searchText,
                values.dateRange,
                newLeadCount,
                refreshCount,
              ]}
            >
              <Card>
                <CardHeader>
                  <CardTitle
                    tag="h3"
                    className="d-flex justify-content-between align-items-center mb-0"
                  >
                    {headerTitle({
                      totalCredit,
                      liveTransferOnlyView,
                      callerReadyAccountStatus,
                      callerReadyStatus,
                      lastCallerReadyChargeResult,
                    })}
                    <div>
                      <RefreshButton
                        title="Refresh Leads"
                        className="mr-3"
                        onClick={() => setRefreshCount((i) => i + 1)}
                      />
                      <UncontrolledButtonDropdown>
                        <DropdownToggle color="dark" caret>
                          Actions
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            disabled={reportLoading}
                            onClick={() => {
                              if (errors && errors.dateRange) {
                                createNotification({
                                  message:
                                    'Please fix search range before exporting',
                                  type: 'alert',
                                  action: 'MESSAGE',
                                  color: 'danger',
                                  timeout: 3000,
                                });
                              } else if (!noGeneratingReports) {
                                createNotification({
                                  message:
                                    'You can only generate one report at a time.',
                                  type: 'alert',
                                  action: 'MESSAGE',
                                  color: 'danger',
                                  timeout: 3000,
                                });
                              } else {
                                exportItems();
                              }
                            }}
                          >
                            {reportLoading || !noGeneratingReports
                              ? 'Export Running'
                              : 'Export'}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <SearchControls
                    openTableOptionsModal={() =>
                      setTableOptionsModal((o) => !o)
                    }
                    advancedSearch={
                      <Row style={{ maxWidth: 530 }}>
                        {/* <Col xs={6}>
                          <FilterSelect
                            label="Vendor"
                            placeholder="Filter By Vendor"
                            className="left-filter-select"
                            name="rightFilter"
                            labelKey="key"
                            raw
                            query={leadVendors}
                          />
                        </Col> */}
                        {liveTransferOnlyView ? null : (
                          <>
                            <Col xs={6}>
                              <FilterSelect
                                label="Campaign"
                                placeholder="Filter By Campaign"
                                className="left-filter-select"
                                name="campaign"
                                labelKey="campaignName"
                                raw
                                query={leadCampaigns}
                              />
                            </Col>
                            <Col xs={6}>
                              <div className="d-flex align-items-center">
                                <InputGroup className="d-flex justify-content-end w-auto">
                                  <SelectField
                                    label="Type"
                                    className="left-filter-select"
                                    placeholder="Lead Type..."
                                    options={getLeadTypeOption()}
                                    name="leadType"
                                    isClearable
                                  />
                                </InputGroup>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col xs={12}>
                          <DateRangeField
                            className="leads-date-range"
                            name="dateRange"
                            id="dateRange"
                            label="Date Range"
                            customArrowIcon="-"
                            min={{ value: 120, units: 'day' }}
                            max={{ value: 1, units: 'day' }}
                            calendarIcon={
                              <div>
                                <FaCalendarMinus />
                              </div>
                            }
                            datepickerProps={{
                              renderMonthElement: undefined,
                            }}
                            ranges={[
                              'Today',
                              'Last 7 Days',
                              'Last 30 Days',
                              'Last 120 Days',
                            ]}
                          />
                        </Col>
                      </Row>
                    }
                  />
                  <SearchSummary className="d-flex justify-content-between py-3" />
                  <div style={{ overflowX: 'auto' }}>
                    <Table striped size="sm" responsive>
                      <thead>
                        <tr>
                          <th aria-label="break-label" style={{ width: 45 }} />
                          <th
                            style={{
                              fontSize: 16,
                              textAlign: 'center',
                            }}
                            className="align-middle px-4"
                          >
                            Status
                          </th>
                          {displayColumns.map((column: any) => (
                            <th
                              style={{
                                fontSize: 16,
                                textAlign: 'center',
                              }}
                              className="align-middle px-4"
                            >
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => updateSort(column.dataField)}
                                onKeyDown={(e: React.KeyboardEvent) =>
                                  e.charCode === 13 &&
                                  updateSort(column.dataField)
                                }
                              >
                                {column.displayName}{' '}
                                {getSortIcon(column.dataField)}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <PaginationContent
                        itemKey="leadID"
                        component={TableRow}
                        containerTag="tbody"
                        columns={displayColumns}
                        setSelectedLeadID={setSelectedLeadID}
                      />
                    </Table>
                  </div>
                  <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                    There are no items left to view
                  </NoItemsMessage>
                </CardBody>
              </Card>
            </Pagination>

            <LeadsShowExportModal
              isOpen={showExportModal}
              toggle={() => setShowExportModal((o) => !o)}
            />
            <LeadsRefundModal
              isOpen={!!selectedLeadID}
              toggle={() => setSelectedLeadID(undefined)}
              leadID={selectedLeadID}
            />
            <TableOptionsModal
              availableColumns={availableColumns(liveTransferOnlyView)}
              isOpen={showTableOptionsModal}
              toggle={() => setTableOptionsModal((o) => !o)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Leads;
