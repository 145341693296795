import gql from 'graphql-tag';
import { AgentFragment } from '@/graphql/fragments';

export default gql`
  query currentAgent {
    userCurrent {
      ...AgentFragment
    }
  }
  ${AgentFragment}
`;
