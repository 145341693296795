import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { Button, Card, CardTitle, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import AuthHeader from './AuthHeader';

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
class CustomForgotPassword extends RequireNewPassword {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['requireNewPassword'];
  }

  public showComponent(): JSX.Element {
    return (
      <AuthHeader cardTitle="Change Password">
        <Formik
          onSubmit={async (values) => {
            const user = this.props.authData;
            // eslint-disable-next-line prefer-destructuring
            const requiredAttributes = user.challengeParam.requiredAttributes;

            try {
              const u = await Auth.completeNewPassword(
                user,
                values.password,
                requiredAttributes
              );

              if (u.challengeName === 'SMS_MFA') {
                this.changeState('confirmSignIn', u);
              } else if (u.challengeName === 'MFA_SETUP') {
                this.changeState('TOTPSetup', u);
              } else {
                this.checkContact(u);
              }

              this.changeState('signIn');
            } catch (error) {
              this.error(error);
            }
          }}
          initialValues={{
            password: '',
          }}
          validationSchema={yup.object().shape({
            password: yup
              .string()
              .required('Please enter a new Password.')
              .min(6, 'Your password must be more than 6 characters'),
          })}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <BlockUI blocking={isSubmitting}>
                <Field
                  name="password"
                  type="password"
                  onChange={this.handleInputChange}
                  placeholder="Password"
                  label="New password"
                />
              </BlockUI>
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                color="primary"
                size="lg"
                type="submit"
                block
              >
                Change
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <div className="d-flex flex-column pt-2">
          <span>
            <Button color="link" onClick={() => this.changeState('signIn')}>
              Back to Sign In
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomForgotPassword;
