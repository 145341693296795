import React, { useMemo } from 'react';
import { useUser } from '@/hooks';
import { Vars } from '@/utils';

const getHiddenEl = (corpID: string) => {
  if (corpID === 'sonic') {
    return (
      <div
        className="position-absolute w-100"
        style={{ height: 40, top: 73, backgroundColor: '#232323' }}
      />
    );
  }
  return null;
};

const Home: React.FunctionComponent = () => {
  const {
    user: {
      firstName,
      role,
      corpID,
      flags: { allowManagerDashboard = false },
    },
  } = useUser();

  const iframeUrl = useMemo(() => {
    // if (corpID === 'sonic') {
    //   return Vars.sonicIframeUrl;
    // }
    return role !== 'user' && allowManagerDashboard
      ? Vars.iframeUrl
      : undefined;
  }, [role, corpID, allowManagerDashboard]);

  return (
    <div className="d-flex justify-content-center flex-column h-100 w-100">
      {iframeUrl ? (
        <>
          {getHiddenEl(corpID)}
          <iframe
            frameBorder="0"
            className="h-100"
            src={iframeUrl}
            title="iframe_dashboard"
          />
        </>
      ) : (
        firstName && (
          <>
            <h3 className="text-center mt-5">Hello, {firstName}</h3>
            <h3 className="mt-5 text-center">
              New Analytics Dashboard Coming Soon
            </h3>
          </>
        )
      )}
    </div>
  );
};

export default Home;
