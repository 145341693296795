import gql from 'graphql-tag';
import { AgentServiceFragment } from '../fragments';

export default gql`
  query paginationQuery(
    $perPage: Int
    $page: Int
    $filter: AgentServiceCollectionFilters
    $orderBy: AgentServiceOrderInput
  ) {
    agentServicePagination(
      perPage: $perPage
      page: $page
      filter: $filter
      orderBy: $orderBy
    ) {
      count
      elasticQuery
      items {
        ...AgentServiceFragment
      }
      pageInfo {
        pageCount
        currentPage
      }
    }
  }
  ${AgentServiceFragment}
`;
