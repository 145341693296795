/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Alert,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from 'reactstrap';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useUser } from '@/hooks';
import { updateCallInfoMutation } from '@/graphql/mutations';
import { FaPhone, FaPhoneSlash, FaMoneyBillAlt } from 'react-icons/fa';
import classNames from 'classnames';

interface NotificationType {
  dismiss: () => void;
  color: string;
  id: string;
  message: string;
}

interface LiveTransferNotification extends NotificationType {
  liveTransferData: {
    tierID: string;
    leadID: string;
    phone: string;
    campaignID: string;
    brokerID: string;
    spendingPeriodID: string;
    spendingPeriodQueueID: string;
    callSid: string;
    price: number;
    tierName: string;
    campaignName: string;
    leadSource: string;
  };
  key: string;
}

export const TransferProfile: React.FunctionComponent<{
  key: string;
  firstName: string;
  lastName: string;
  state: string;
  phoneNumber: string;
  timeout: number;
  liveTransferScript: string;
  dismiss: () => {};
}> = ({
  key,
  firstName,
  lastName,
  phoneNumber,
  state,
  liveTransferScript,
  timeout = 5000,
  dismiss = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = useCallback(() => {
    setIsOpen(false);
    dismiss();
  }, []);

  // setTimeout(toggle, timeout);

  return (
    <Modal
      key={key}
      isOpen={isOpen}
      toggle={toggle}
      size={liveTransferScript ? 'xl' : 'lg'}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        <div className="d-flex align-items-center">
          Call Information{' '}
          <span className="ml-2" role="img" aria-label="phone">
            ☎️
          </span>
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        {!firstName && !lastName && !phoneNumber && !state ? (
          <div className="d-flex align-items-center justify-content-center">
            No Data
          </div>
        ) : (
          <Row>
            <Col xs={12} className="mb-3">
              <i className="text-muted">Info about your call</i>
            </Col>
            <Col xs={4}>
              <label>First Name</label>
              <Input value={firstName || 'N/A'} />
            </Col>
            <Col xs={4}>
              <label>Last Name</label>
              <Input value={lastName || 'N/A'} />
            </Col>
            <Col xs={4}>
              <label>Phone Number</label>
              <Input value={phoneNumber || 'N/A'} />
            </Col>
            <Col xs={4}>
              <label>State</label>
              <Input value={state || 'N/A'} />
            </Col>
            <Col
              xs={12}
              className={classNames(!liveTransferScript && 'd-none')}
            >
              <label className="h4 mb-2">Script</label>
              <Input
                value={liveTransferScript || 'N/A'}
                style={{ minHeight: 350 }}
                type="textarea"
                className="py-2"
              />
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};
const Notification: React.FunctionComponent<NotificationType> = ({
  dismiss,
  id,
  color,
  message,
}) => (
  <Alert
    id={`${id}-alert-message-id`}
    color={color}
    key={id}
    toggle={dismiss}
    style={{ width: 275 }}
  >
    {message}
  </Alert>
);

export const LiveTransferNotification: React.FunctionComponent<LiveTransferNotification> = ({
  dismiss,
  key = '',
  color,
  message,
  liveTransferData,
}) => {
  const {
    user: { agentID },
  } = useUser();
  useEffect(() => {
    const audio = new Audio(
      'https://twilio-wait-url.s3.amazonaws.com/live-transfer.mp3'
    );

    audio.play().catch(() => 1);
  }, []);

  const [updateCall] = useMutation(updateCallInfoMutation);

  const acceptCall = () => {
    dismiss();
    updateCall({
      variables: {
        input: {
          action: 'answer',
          data: {
            ...liveTransferData,
            agentID,
            agentAcceptTimeStamp: moment().valueOf(),
          },
        },
      },
    });
  };

  const declineCall = () => {
    dismiss();
    updateCall({
      variables: {
        input: {
          action: 'decline',
          data: {
            ...liveTransferData,
            agentID,
          },
        },
      },
    });
    dismiss();
  };

  return (
    <Alert
      id={`${liveTransferData.leadID}-standard-lead-purchased`}
      color={color}
      key={key}
      style={{ width: 275 }}
    >
      <div className="d-flex justify-content-center align-items-center">
        {message}{' '}
        <div className="text-success d-inline-block ml-1">
          <FaMoneyBillAlt size={20} />
        </div>
      </div>
      {liveTransferData.tierName && (
        <Row className="mt-3 d-flex justify-content-center">
          <Col className="text-truncate" xs={12}>
            <span className="text-success font-weight-bold">{`$${(
              liveTransferData.price / 100
            ).toFixed(2)} `}</span>
            {`${liveTransferData.tierName}`}
          </Col>
        </Row>
      )}
      {liveTransferData.campaignName && (
        <Row className="mt-3 d-flex justify-content-center">
          <Col xs={12} className="text-truncate">
            <span className="font-weight-bold">Campaign</span>
            {`: ${liveTransferData.campaignName}`}
          </Col>
        </Row>
      )}
      {liveTransferData.leadSource && (
        <Row className="mt-3 d-flex justify-content-center">
          <Col xs={12} className="text-truncate">
            <span className="font-weight-bold">Lead Source</span>
            {`: ${liveTransferData.leadSource}`}
          </Col>
        </Row>
      )}
      <Row className="mt-3 d-flex justify-content-center">
        <Col>
          <button
            type="button"
            className="text-white bg-success border border-success rounded d-flex align-items-center justify-content-center"
            onClick={acceptCall}
          >
            <FaPhone /> Accept
          </button>
        </Col>
        <Col>
          <button
            type="button"
            className="text-white bg-danger border border-danger rounded d-flex align-items-center justify-content-center"
            onClick={declineCall}
          >
            <FaPhoneSlash /> Decline
          </button>
        </Col>
      </Row>
    </Alert>
  );
};

export default Notification;
