import React, { useState, useEffect } from 'react';
import { Form } from '@availity/form';
import { Storage } from 'aws-amplify';
import * as yup from 'yup';
import { SelectField } from '@availity/select';
import { DateRangeField } from '@availity/date';
import moment from 'moment';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Row,
  Col,
} from 'reactstrap';
import { dateRange } from '@availity/yup';
import { FaCalendarMinus } from 'react-icons/fa';
import { Vars } from '@/utils';
import { leadVendors, reports } from '@/graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { LeadsShowExportModal } from '@/modals';
import { reportOptions } from './reportContstants';

interface ReportControlsProps {
  agentID: string;
}
const ReportControls: React.SFC<ReportControlsProps> = ({ agentID }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { data: rData = {}, loading } = useQuery(reports);

  const { reports: reportData = [] } = rData;

  const [showExportModal, setShowExportModal] = useState(false);

  const toggleDropdown = () => setDropdownOpen((o) => !o);

  // const { data = {}, refetch } = useQuery(leadVendors, {
  //   variables: {
  //     filter: {
  //       soldDate: {
  //         gte: moment({ hour: 0 }).subtract(29, 'days').format(),
  //         lte: moment({ hour: 0 }).format(),
  //       },
  //     },
  //   },
  // });

  if (loading) return null;

  const noGeneratingReports = reportData.every(
    ({ status }: any) => status !== 'GENERATING'
  );

  return (
    <div style={{ marginBottom: 10 }}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="d-none"
      >
        <DropdownToggle split caret>
          Report Generator{' '}
        </DropdownToggle>
        <DropdownMenu className="p-4" style={{ width: 600 }}>
          <Row xs={12}>
            <Form
              initialValues={{
                reportType: '',
                vendorName: '',
                dateRange: {
                  startDate: moment({ hour: 0 }).subtract(29, 'days').format(),
                  endDate: moment({ hour: 0 }).format(),
                },
              }}
              validationSchema={yup.object().shape({
                reportType: yup.string().required('This field is required.'),
                vendorName: yup.string().when('reportType', {
                  is: 'vendorRefunds',
                  then: yup.string().required('This field is required.'),
                  otherwise: yup.string(),
                }),
                dateRange: yup.object().shape({
                  endDate: yup.string().isRequired(),
                  startDate: yup.string().isRequired(),
                }),
              })}
              onSubmit={(values) => {
                const { reportType, vendorName, dateRange } = values;
                const filePath = `leads/exports/searchPayload-${moment().format()}.json`;

                const { startDate = '', endDate = '' } = dateRange as any;

                Storage.vault.put(
                  filePath,
                  JSON.stringify({
                    type: reportType,
                    filter: {
                      vendorName,
                      startDate,
                      endDate,
                      brokerID: agentID,
                    },
                    agentID, // add back for now so we can be safe for new and old
                    folderID: agentID,
                  }),
                  {
                    level: 'private',
                    contentType: 'application/json',
                    bucket: `report-filters-${Vars.env}`,
                    region: 'us-east-1',
                  }
                );
                setShowExportModal(true);
                toggleDropdown();
              }}
            >
              {({ values: { dateRange }, setFieldValue }) => {
                // useEffect(() => {
                //   refetch({
                //     filter: {
                //       soldDate: {
                //         gte: dateRange.startDate,
                //         lte: dateRange.endDate,
                //       },
                //     },
                //   });
                //   setFieldValue('vendorName', '');
                // }, [dateRange.endDate, dateRange.startDate]);
                return (
                  <Row style={{ marginLeft: 10, marginRight: 10 }}>
                    <Row>
                      <Col xs={12}>
                        <DateRangeField
                          className="leads-date-range"
                          name="dateRange"
                          id="dateRange"
                          label="Date Range"
                          customArrowIcon="-"
                          calendarIcon={
                            <div>
                              <FaCalendarMinus />
                            </div>
                          }
                          datepickerProps={{
                            renderMonthElement: undefined,
                          }}
                          ranges
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <SelectField
                          name="reportType"
                          label="Report Type"
                          className="left-filter-select"
                          valueKey="reportType"
                          placeholder="Select report type..."
                          options={reportOptions}
                          getOptionLabel={(opt: any) => {
                            return opt.label;
                          }}
                        />
                      </Col>
                      {/* <Col xs={6}>
                        <SelectField
                          name="vendorName"
                          label="Vendor"
                          className="left-filter-select"
                          valueKey="key"
                          placeholder="Select vendor..."
                          options={data.agg || []}
                          getOptionLabel={(opt: any) => {
                            return opt.key;
                          }}
                        />
                      </Col> */}
                      <Col xs={12}>
                        <Button
                          type="submit"
                          size="md"
                          color="primary"
                          disabled={!noGeneratingReports}
                        >
                          Generate
                        </Button>
                        {!noGeneratingReports && (
                          <span className="text-danger ml-2">
                            You can only generate one report at a time.
                          </span>
                        )}
                      </Col>
                    </Row>
                  </Row>
                );
              }}
            </Form>
          </Row>
        </DropdownMenu>
      </Dropdown>
      <LeadsShowExportModal
        toggle={() => setShowExportModal((o) => !o)}
        isOpen={showExportModal}
      />
    </div>
  );
};

export default ReportControls;
