import gql from 'graphql-tag';
import { CreditBucketFragment } from '@/graphql/fragments';

export default gql`
  query creditBucketsQuery {
    creditBuckets {
      ...CreditBucketFragment
    }
  }
  ${CreditBucketFragment}
`;
