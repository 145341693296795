import React from 'react';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { useDestinations, useUser } from '@/hooks';

const DestinationSelect: React.FC<SelectFieldProps<any>> = ({ ...props }) => {
  const { user } = useUser();
  const { destinations, loading: destLoading } = useDestinations(user.agentID);
  console.log(user.destinations);
  return (
    <SelectField
      additional={{
        page: 1,
      }}
      isLoading={destLoading}
      getOptionLabel={(opt: any) => {
        return `${opt.destinationName || opt.label}`;
      }}
      defaultOptions
      raw
      options={[...(destinations || []), ...(user.destinations || [])]}
      {...props}
    />
  );
};

export default DestinationSelect;
