import gql from 'graphql-tag';

export default gql`
  subscription globalNotification {
    notification: globalNotification {
      action
      data
      timeout
      notificationID
    }
  }
`;
