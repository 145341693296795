import gql from 'graphql-tag';

const CreditBucketFragment = gql`
  fragment CreditBucketFragment on CreditBucket {
    id
    __typename
    agentID
    creditBucketID
    balance
    description
  }
`;
export default CreditBucketFragment;
