import React from 'react';
import classnames from 'classnames';
import { Badge, BadgeProps } from 'reactstrap';
import { formatCurrencyForDisplay } from '@/utils';

interface PriceBadgeProps extends BadgeProps {
  price: number;
}

const PriceBadge: React.SFC<PriceBadgeProps> = ({
  price,
  className,
  style,
  ...props
}) => {
  const isPositive = price >= 0;

  return (
    <Badge
      style={{
        fontSize: 13,
        ...style,
      }}
      className={classnames('pt-1', className)}
      color="success"
      pill
      {...props}
    >
      {isPositive ? '+' : '-'}
      {formatCurrencyForDisplay(price)}
    </Badge>
  );
};

export default PriceBadge;
