/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useLocation } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Card,
  CardTitle,
  Container,
  FormGroup,
  CardHeader,
} from 'reactstrap';
import { uuid } from 'uuidv4';
import { Form, Field } from '@availity/form';
import LoadingButton from '@gopro16/button';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { updateCampaignMutation } from '@/graphql/mutations';
import { campaignPagination, campaignByID } from '@/graphql/queries';
import {
  LoadingComponent,
  BackButton,
  PriceTierCompliance,
  FilterCard,
} from '@/components';
import {
  useUser,
  usePriceTiers,
  useNotifications,
  useCampaignsNetwork,
} from '@/hooks';
import { DestinationsCard } from './components';

const CampaignForm: React.FunctionComponent<RouteComponentProps<{
  id: string;
}>> = ({ match, history }) => {
  const { create } = useNotifications();
  const { networkID, changeNetwork } = useCampaignsNetwork();

  let { activeTiers = [] } = history.location?.state || {};

  const campaignNodeID = match.params.id;
  const { data: campaignData, loading: campaignLoading } = useQuery(
    campaignByID,
    {
      skip: campaignNodeID === 'new',
      variables: {
        id: campaignNodeID,
      },
    }
  );
  const { user, loading: agentLoading } = useUser();

  const campaign = campaignData?.node;

  activeTiers = activeTiers.filter((t) => {
    return t !== campaign?.campaignTierID;
  });

  useEffect(() => {
    if (
      campaign?.networkID &&
      ((networkID === '' && campaign.networkID !== user.networkID) ||
        networkID !== campaign.networkID)
    ) {
      changeNetwork(campaign.networkID);
    }
  }, [campaign, changeNetwork, networkID, user.networkID]);
  const { data: tierData } = usePriceTiers();

  const [modifyCampaign, { loading: updating }] = useMutation(
    updateCampaignMutation,
    {
      update(cache, data) {
        const { updateCampaign } = data.data as any;

        try {
          const currentData: any = cache.readQuery({
            query: campaignPagination,
            variables: {
              networkID,
            },
          });
          if (
            currentData.campaigns.find((c: any) => c.id === updateCampaign.id)
          ) {
            cache.writeData({
              id: updateCampaign.id,
              data: {
                ...updateCampaign,
              },
            });
          } else {
            cache.writeQuery({
              query: campaignPagination,
              data: {
                campaigns: [...currentData.campaigns, updateCampaign],
              },
              variables: {
                networkID,
              },
            });
          }
        } catch (_) {
          // no-op... just means we refreshed the page here
        }
        create({
          message: 'Successfully updated',
          color: 'success',
          type: 'alert',
          action: 'MESSAGE',
          timeout: 1000,
        });

        history.push('/campaigns');
      },
      onError: (error) =>
        create({
          message: error.message.replace('GraphQL error:', ''),
          color: 'danger',
          type: 'alert',
          action: 'MESSAGE',
          timeout: 3000,
        }),
    }
  );

  useEffect(() => {
    if (!campaignLoading && campaignNodeID !== 'new' && !campaign) {
      history.push('/campaigns');
    }
  }, [campaignData, campaign, campaignLoading, campaignNodeID, history]);

  const onSubmit = (values: any) => {
    // if the campaignID exists then we are modifying
    const {
      campaignName,
      proportionalDestination,
      destinations,
      regions,
      ageInt,
      incomeInt: { min, max },
      householdInt,
      priceTierID,
      sidelineIntegration,
    } = values;

    const minInt = parseInt((min / 100).toFixed(), 10);
    const maxInt = parseInt((max / 100).toFixed(), 10);

    modifyCampaign({
      variables: {
        input: {
          update: {
            campaignTierID: priceTierID,
            campaignID: campaign?.campaignID || uuid(),
            campaignName,
            proportionalDestination: !!proportionalDestination,
            destinations: destinations.map((d: any) => d.uiID),
            regions,
            ageInt,
            incomeInt: { min: minInt, max: maxInt },
            householdInt,
            paused: campaign ? campaign.paused : true,
            sidelineIntegration: !!sidelineIntegration,
          },
        },
      },
    });
  };

  if (agentLoading || campaignLoading) return <LoadingComponent />;

  const getIncome = (income: any): any => {
    if (income) {
      const { min, max } = income;
      const minInt = min * 100;
      const maxInt = max * 100;
      return { min: minInt, max: maxInt };
    }

    return { min: 100, max: 999999999 };
  };

  return (
    <Container>
      <Card className="mb-3">
        <CardHeader className="d-flex align-items-center">
          <BackButton color="primary" path="/campaigns" className="mr-2" />
          <CardTitle tag="h5" className="py-2 h4 mb-0">
            {campaign?.campaignName ? campaign.campaignName : 'Create Campaign'}
          </CardTitle>
        </CardHeader>
      </Card>
      <Form
        initialValues={{
          agreed: false,
          searchText: '',
          campaignName: campaign?.campaignName || '',
          priceTierID: campaign?.campaignTierID || undefined,
          excludeContactOwner: campaign?.excludeContactOwner,
          campaignTierID: campaign?.campaignTierID || '',
          proportionalDestination: campaign?.proportionalDestination || false,
          regions:
            campaign?.regions ||
            (user.corpID === 'leadtopia' ? [] : user.network.allowedStates),
          // TODO - Removed because this is only for one agent atm in legacy
          // ageRanges: filters ? getAgeRanges(filters) : AGE_RANGES,
          ageInt: campaign?.ageInt || { min: 18, max: 99 },
          incomeInt: getIncome(campaign?.incomeInt),
          householdInt: campaign?.householdInt || { min: 1, max: 9 },
          destinations: campaign?.destinations || [],
          requiredDestination: 'none',
          sidelineIntegration: campaign?.sidelineIntegration || false,
        }}
        validationSchema={yup.object().shape({
          campaignName: yup.string().required('This field is required.'),
          regions: yup.array().of(yup.string()),
          ageInt: yup.object(),
          incomeInt: yup.object(),
          householdInt: yup.object(),
          proportionalDestination: yup.boolean(),
          sidelineIntegration: yup.boolean(),
          agreed: yup.boolean().oneOf([true], 'You must agree to the terms.'),
          priceTierID: yup
            .string()
            .required('This field is required.')
            .test(
              'isLiveTransfer',
              'Cannot change from a non Live Transfer Tier.',
              (selectedTierID) => {
                if (!campaign?.campaignTierID) {
                  return true;
                }
                const selectedTier = tierData?.tiers.find(
                  (t) => t.priceTierID === selectedTierID
                );
                if (!selectedTier) {
                  return true;
                }
                const oldTier = tierData?.tiers.find(
                  (t) => t.priceTierID === campaign!.campaignTierID
                );
                return !!oldTier.liveTransfers === !!selectedTier.liveTransfers;
              }
            ),
          destinations: yup
            .array()
            .of(yup.object())
            .when(
              ['priceTierID'],
              (priceTierID: string, schema: yup.ArraySchema<any>) => {
                const selectedTier = tierData?.tiers.find(
                  (t) => t.priceTierID === priceTierID
                );

                if (
                  selectedTier?.allowedDestinationTypes &&
                  selectedTier.allowedDestinationTypes.length > 0
                ) {
                  return schema.test(
                    'allowDestinationTypes',
                    `You can only select destinations of type ${selectedTier.allowedDestinationTypes.toString(
                      ', '
                    )} for the selected tier.`,
                    (array: any[] = []) => {
                      if (
                        array.some((dest) =>
                          selectedTier.allowedDestinationTypes.every(
                            (type: string) => {
                              console.log(
                                `${dest.destID}: ${type} ${
                                  dest.destID.length < 38 && type === 'email'
                                }`
                              );
                              if (dest.destID.length < 38 && type === 'email') {
                                return false;
                              }
                              return !dest.destID.includes(type);
                            }
                          )
                        )
                      ) {
                        return false;
                      }
                      return true;
                    }
                  );
                }
                if (
                  selectedTier?.requiredDestination !== 'none' &&
                  selectedTier?.requiredDestination
                ) {
                  const requiredDestType = (): string => {
                    switch (selectedTier?.requiredDestination) {
                      case 'vSoft':
                        return 'Vanilla Soft';
                      case 'iSales':
                        return 'iSalesCRM';
                      case 'email':
                        return 'Email';
                      case 'api':
                        return 'API';
                      default:
                        return 'Error';
                    }
                  };

                  return schema.test(
                    'requiredDestination',
                    `Must include destination type: ${requiredDestType()}.`,
                    (array: any[] = []) => {
                      // If this array isnt empty, the destination array is missing a required destination
                      const destArray = (
                        destination: string,
                        crm?: boolean
                      ): boolean => {
                        return (
                          array.filter((dest) => {
                            let expression =
                              dest.destinationType === destination;
                            if (crm) {
                              expression = dest.destID.includes(destination);
                            }
                            return expression;
                          }).length !== 0
                        );
                      };

                      switch (selectedTier?.requiredDestination) {
                        case 'vSoft':
                          return destArray('vanillaSoftDirect', true);
                        case 'iSales':
                          return destArray('iSalesCRM', true);
                        case 'onlysales':
                          return destArray('onlysales', true);
                        case 'convoso':
                          return destArray('convoso', true);
                        case 'email':
                          return destArray('email');
                        case 'api':
                          return destArray('api');
                        default:
                          return false;
                      }
                    }
                  );
                }

                if (!selectedTier?.isAged) {
                  return schema.test(
                    'mustBeOfSize',
                    'You must have at least 1 destination',
                    (array: any[] = []) => {
                      if (array.length === 0) {
                        return false;
                      }
                      return true;
                    }
                  );
                }

                if (selectedTier?.allowedDestinationTypes?.length > 0) {
                  return schema.test(
                    'mustBeOfSize',
                    'You must have at least 1 destination',
                    (array: any[] = []) => {
                      if (array.length === 0) {
                        return false;
                      }
                      return true;
                    }
                  );
                }

                return schema;
              }
            ),
        })}
        onSubmit={onSubmit}
      >
        <PriceTierCompliance type="campaign" activeTiers={activeTiers} />
        {user.flags.allowSideline ? (
          <Card body className="mb-3">
            <CardTitle tag="h4" className="lead text-uppercase">
              Extension Integrations
            </CardTitle>
            <FormGroup>
              <Field
                label="Use Sideline for Text Messaging?"
                name="sidelineIntegration"
                type="checkbox"
              />
            </FormGroup>
          </Card>
        ) : null}
        <DestinationsCard />
        <FilterCard type="campaign" />
        <FormGroup>
          <LoadingButton
            type="submit"
            data-testid="form-submit"
            block
            disabled={updating}
            loading={updating}
            color="primary"
            size="lg"
          >
            Submit
          </LoadingButton>
        </FormGroup>
      </Form>
    </Container>
  );
};

export default CampaignForm;
