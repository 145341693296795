import gql from 'graphql-tag';
import { CampaignFragment } from '@/graphql/fragments';

export default gql`
  query campaignPagination($networkID: String) {
    campaigns(networkID: $networkID) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`;
