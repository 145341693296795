import React from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  ModalHeader,
  CardHeader,
  Button,
} from 'reactstrap';
import { useUser, useNotifications } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { creditTransferByEmail } from '@/graphql/mutations';
import { agentTransferPagination } from '@/graphql/queries';
import { MoneyField } from '@/components';
import { formatCurrencyForDisplay } from '@/utils';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';

const TransfersTransferMoneyModal: React.SFC<ModalProps> = (props) => {
  const { user } = useUser();
  const { create: createNotification } = useNotifications();
  const [transferCredit] = useMutation(creditTransferByEmail, {
    onError: (error) =>
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      }),
    onCompleted: () => {
      createNotification({
        message: 'Successfully transfered credit.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });

      props.onCompleted();
    },
  });
  return (
    <Modal {...props}>
      <Form
        onSubmit={(values) => {
          if (values.email === user.email) {
            createNotification({
              message: 'You cannot transfer money to yourself!',
              type: 'alert',
              action: 'MESSAGE',
              color: 'danger',
            });
            return;
          }

          transferCredit({
            variables: {
              input: {
                email: values.email,
                value: values.transferAmount,
              },
            },
          });
        }}
        initialValues={{
          email: '',
          transferAmount: 0,
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .required('This field is required.')
            .email('Must be a valid email.'),
          transferAmount: yup
            .number()
            .min(0, 'Must be a positive price.')
            .max(user.totalCredit, "Can't transfer more credit than you have."),
        })}
      >
        {({ values }) => (
          <>
            <ModalHeader wrapTag={CardHeader}>Transfer Money</ModalHeader>
            <ModalBody>
              <div className="mb-2">
                Available Credit: {formatCurrencyForDisplay(user.totalCredit)}
              </div>
              <Field
                name="email"
                type="email"
                label="Email"
                placeholder="Email..."
              />
              <MoneyField
                name="transferAmount"
                label="Amount"
                minimum={0}
                size="md"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                className={values.transferAmount === 0 ? 'd-none' : ''}
              >
                Transfer
              </Button>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default TransfersTransferMoneyModal;
