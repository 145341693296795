import { tierQuery } from '@/graphql/queries';
import { useQuery } from '@apollo/react-hooks';

export default (priceTierID: string): object => {
  const { data } = useQuery(tierQuery, {
    variables: { id: priceTierID },
    skip: !priceTierID,
  });
  return data?.tier;
};
