import React from 'react';
import {
  ListGroupItem,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import { Agent, Lead } from '@sonic-web-dev/types';
import { formatCurrencyForDisplay } from '@/utils';

interface RefundListItemProps {
  serviceCaseID: string;
  agentID: string;
  leadID: string;
  networkID: string;
  leadPhone: string;
  modified: string;
  refundOption: string;
  refundReason: string;
  refundType: string;
  soldDate: string;
  status: string;
  created: string;
  type: string;
  amount: number;
  summary: string;
  agent: Agent;
  updatedBy: Agent;
  lead: Lead;
  className?: string;
  color?: string;
}

const RefundListItem: React.FunctionComponent<RefundListItemProps> = ({
  serviceCaseID,
  refundType,
  status,
  refundOption,
  refundReason,
  amount,
  lead,
  modified,
  summary,
  created,
  type,
  className,
  color,
}) => {
  return (
    <ListGroupItem
      color={color}
      className={classnames(
        'mb-3 d-flex align-items-center',
        'border-4 border-top-0 border-bottom-0 border-right-0',
        {
          'border-danger': refundType === 'DENY',
          'border-primary': status === 'pending',
          'border-success': refundType === 'FULL',
          'border-warning': refundType === 'PARTIAL',
        },
        className
      )}
      tag={Row}
      noGutters
    >
      <Col xs={6} className="mb-2">
        Case {serviceCaseID}
      </Col>
      <Col xs={6} className="mb-2">
        Created {moment(created).format('MM/DD/YY hh:mm A')}
      </Col>
      <Col xs={4}>
        <h4>Lead</h4>
        <div>
          Price:{' '}
          <span className="text-success">
            {lead ? formatCurrencyForDisplay(lead.price! * 100) : 'Missing'}
          </span>
        </div>
        {lead ? (
          <>
            <div>{lead.phone}</div>
            <div>{lead.email}</div>
          </>
        ) : (
          'Missing'
        )}
      </Col>
      <Col xs={4}>
        <h4>Refund Details</h4>
        <div>{refundOption}</div>
        <div>{refundReason}</div>
        <div>{type}</div>
      </Col>
      {status !== 'pending' ? (
        <Col xs={3}>
          <h4>Result</h4>
          <div>Updated {moment(modified).format('MM/DD/YY hh:mm A')}</div>
          <div>
            {refundType === 'DENY' ? (
              <span className="text-danger">Denied</span>
            ) : (
              <>
                Refunded{' '}
                <span className="text-warning">
                  {formatCurrencyForDisplay(amount)}
                </span>
              </>
            )}
          </div>
          {summary ? (
            <div className="cursor-pointer">
              <span id={`view-summary-${serviceCaseID}`}>View Summary</span>
              <UncontrolledPopover
                target={`view-summary-${serviceCaseID}`}
                trigger="hover"
              >
                <PopoverHeader>Summary</PopoverHeader>
                <PopoverBody>{summary}</PopoverBody>
              </UncontrolledPopover>
            </div>
          ) : (
            'No Summary'
          )}
        </Col>
      ) : (
        <Col xs={3} className="text-center font-weight-bold">
          Pending Review. Please check back later.
        </Col>
      )}
    </ListGroupItem>
  );
};

export default RefundListItem;
