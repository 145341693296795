import gql from 'graphql-tag';

export default gql`
  mutation togglePause($input: RelayTogglePauseCampaignInput!) {
    campaignTogglePause(input: $input) {
      success
      value
    }
  }
`;
