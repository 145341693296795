import React from 'react';
import { RiseLoader } from 'react-spinners';

const getColor = () => {
  return '#1285ff';
};

const LoadingComponent: React.SFC<{
  loading?: boolean | true;
  long?: boolean;
}> = ({ loading, long }) => {
  return (
    <div className="d-flex justify-content-center flex-column my-4">
      {long && <div className="mb-5 h5">Still Loading...</div>}
      <RiseLoader color={getColor()} loading={loading} />
    </div>
  );
};

LoadingComponent.defaultProps = {
  loading: true,
};

export default LoadingComponent;
