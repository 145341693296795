import gql from 'graphql-tag';
import VendorPartial from './vendorPartial';

const VendorFragment = gql`
  fragment VendorFragment on Vendor {
    ${VendorPartial}
  }
`;

export default VendorFragment;
