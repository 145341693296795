import React from 'react';
import { Card, CardTitle, FormGroup } from 'reactstrap';
import { ErrorMessage } from 'formik';
import Destinations from '@/areas/Destinations';
import { Field } from '@availity/form';
import { useUser } from '@/hooks';

const DestinationsCard: React.SFC = () => {
  const { user } = useUser();
  return (
    <Card body className="mb-3">
      <CardTitle tag="h4" className="lead text-uppercase">
        Destinations
      </CardTitle>
      <FormGroup>
        {user.flags.allowProportionalDestinations ? (
          <Field
            label="Proportional Destination?"
            name="proportionalDestination"
            type="checkbox"
          />
        ) : null}
        <Destinations />
        <div className="text-danger">
          <ErrorMessage name="destinations" />
        </div>
      </FormGroup>
    </Card>
  );
};

export default DestinationsCard;
