import gql from 'graphql-tag';

export default gql`
  query tierTimeoutQuery(
    $networkID: String!
    $agentID: String!
    $priceTierID: String!
  ) {
    tierTimeout(
      networkID: $networkID
      agentID: $agentID
      priceTierID: $priceTierID
    )
  }
`;
