import gql from 'graphql-tag';

export default gql`
  subscription liveTransferNotification {
    notification: liveTransferNotification {
      message
      timeout
      color
      type
      id
      liveTransferData {
        tierID
        leadID
        phone
        campaignID
        brokerID
        spendingPeriodID
        spendingPeriodQueueID
        callSid
        networkID
      }
    }
  }
`;
