import React from 'react';
import {
  SignIn,
  SignUp,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
} from '@/components/Auth';
import { Container, Alert } from 'reactstrap';

import {
  Authenticator,
  Greetings,
  SignIn as OGSignIn,
  SignUp as OGSignUp,
  ConfirmSignUp as OGConfirmSignUp,
  VerifyContact as OGVerifyContact,
  ForgotPassword as OGForgotPassword,
  RequireNewPassword as OGRequireNewPassword,
} from 'aws-amplify-react';

const AmplifyAuthenticator: React.FunctionComponent = ({ children }) => (
  <Authenticator
    errorMessage={(message: string) => (
      <Container className="px-0 mt-5" style={{ maxWidth: 500 }}>
        <Alert color="danger">{message}</Alert>
      </Container>
    )}
    hide={[
      Greetings,
      OGSignIn,
      OGSignUp,
      OGVerifyContact,
      OGConfirmSignUp,
      OGForgotPassword,
      OGRequireNewPassword,
    ]}
  >
    <SignUp />
    <SignIn />
    <RequireNewPassword />
    <ConfirmSignUp />
    <ForgotPassword />
    {children}
  </Authenticator>
);

export default AmplifyAuthenticator;
