import React from 'react';
import { FaSync } from 'react-icons/fa';
import SwitchButton from './SwitchButton';

interface ButtonToggleProps {
  active: boolean;
  loading: boolean;
  disabled?: boolean;
  onToggle: () => void;
}
const ButtonToggle: React.FC<ButtonToggleProps> = ({
  active,
  loading,
  onToggle,
  disabled,
}) => {
  if (loading) {
    return <FaSync className="loading-sync" size={24} />;
  }

  return (
    <SwitchButton
      width={70}
      checked={!!active}
      onlabel="On"
      disabled={!!disabled}
      offlabel="Off"
      onstyle="success"
      offstyle="danger"
      onChange={onToggle}
    />
  );
};

export default ButtonToggle;
