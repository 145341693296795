import {
  testDestinationMutation,
  updateDestination as updateDestinationMutation,
} from '@/graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import useDestinations from './useDestinations';
import useNotifications from './useNotifications';

const useDestination = (agentID: string, destinationID?: string) => {
  const { destinations, loading, error, refetch } = useDestinations(agentID);
  const { create: createNotification } = useNotifications();
  const destination = destinations.find((d) => d.destID === destinationID);
  const [updateDestination, { loading: updating }] = useMutation(
    updateDestinationMutation,
    {
      update() {
        if (destinationID !== 'custom') {
          createNotification({
            message: 'Successfully updated destination',
            type: 'alert',
            action: 'MESSAGE',
            color: 'success',
            timeout: 1000,
          });
        } else {
          createNotification({
            message: 'Successfully created destination',
            type: 'alert',
            action: 'MESSAGE',
            color: 'success',
            timeout: 1000,
          });
        }
        refetch({
          agentID,
        });
      },
    }
  );

  const [testDestination, { loading: testingDestination }] = useMutation(
    testDestinationMutation,
    {
      variables: {
        input: {
          destAddress: destinationID,
        },
      },
      update() {
        createNotification({
          message: 'Successfully sent test',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
          timeout: 3000,
        });
      },
    }
  );

  return {
    loading,
    error,
    updating,
    update: updateDestination,
    test: testDestination,
    testing: testingDestination,
    destination,
  };
};

export default useDestination;
