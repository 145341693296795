import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { Field } from '@availity/form';
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { formatCurrencyForDisplay, bulkPrice } from '@/utils';
import Button from '@/components/LoadingButton';
import { useLazyQuery } from 'react-apollo';
import { availableLeadCount } from '@/graphql/queries';
import { useCampaignsNetwork } from '@/hooks';
import { filters } from '@/areas/BulkPurchase';
import { FaQuestionCircle } from 'react-icons/fa';

const BulkPurchaseModal: React.FC<{ isOpen: boolean; toggle: () => void }> = ({
  isOpen,
  toggle,
}) => {
  const [{ value: tier }] = useField('priceTier');
  const [{ value: total }] = useField('estimatedLeadCount');
  const [{ value: requested }] = useField('requestedLeadCount');
  const {
    values,
    setFieldValue,
    isValid,
    submitForm,
    isSubmitting,
    errors,
  } = useFormikContext();

  const idPrefix = 'bulk-purchase-modal';

  const { networkID } = useCampaignsNetwork();
  const [
    fetchAvailableLeads,
    { data, loading },
  ] = useLazyQuery(availableLeadCount, { fetchPolicy: 'network-only' });

  // Disable both useEffects below for testing
  useEffect(() => {
    if (isOpen) {
      if (tier?.skipAvailableEstimate) {
        setFieldValue('estimatedLeadCount', requested);
      } else {
        setFieldValue('estimatedLeadCount', 0);
        fetchAvailableLeads({
          variables: {
            input: {
              networkID,
              priceTierID: tier?.priceTierID,
              filters: filters(values),
            },
          },
        });
      }
    }
    if (!isOpen) {
      setFieldValue('estimatedLeadCount', 1);
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && data.availableLeadCount.total < values?.requestedLeadCount) {
      setFieldValue('estimatedLeadCount', data.availableLeadCount.total);
    }
    if (data && data.availableLeadCount.total >= values.requestedLeadCount) {
      setFieldValue('estimatedLeadCount', values.requestedLeadCount);
    }
  }, [data, values, setFieldValue]);

  if (!tier) return null;
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="sm">
        <ModalHeader>
          Confirm Order{' '}
          <FaQuestionCircle
            className="ml-1 cursor-pointer"
            id="bulk-table-estimated-price-tooltip"
          />
          <UncontrolledTooltip target="bulk-table-estimated-price-tooltip">
            Your account will be charged for the amount of leads requested. If
            the amount of leads processed is less than the amount requested,
            your account will be refunded with the difference.
          </UncontrolledTooltip>
        </ModalHeader>
        <ModalBody>
          {isSubmitting ? (
            <div className="pl-5 ml-3 my-1">
              <Spinner className="mb-2 ml-5" color="primary" />
            </div>
          ) : (
            <Row>
              <Col>
                <Field
                  id={`${idPrefix}-price-tier-name`}
                  name="priceTier.name"
                  label="Price Tier"
                  disabled
                />
                <Field
                  id={`${idPrefix}-price-tier-price`}
                  name="priceTier.price"
                  value={formatCurrencyForDisplay(bulkPrice(tier, requested))}
                  label="Price"
                  disabled
                />
                <Field
                  id={`${idPrefix}-requested-leads`}
                  name="requestedLeadCount"
                  label="Requested Leads"
                  disabled
                />
                {loading ? (
                  <div className="pl-5 ml-3 my-1">
                    <Spinner className="mb-2 ml-5" color="primary" />
                  </div>
                ) : (
                  <>
                    {tier?.skipAvailableEstimate ? null : (
                      <Field
                        id={`${idPrefix}-available-leads`}
                        name="estimatedLeadCount"
                        label="Available Leads"
                        disabled
                      />
                    )}

                    <Field
                      id={`${idPrefix}-estimated-price`}
                      name="totalPrice"
                      label="Estimated Total Price"
                      value={formatCurrencyForDisplay(
                        bulkPrice(tier, total) * total
                      )}
                      disabled
                    />
                  </>
                )}
              </Col>
            </Row>
          )}
          {errors?.estimatedLeadCount && !loading ? (
            <div className="mb-2 text-danger">{errors?.estimatedLeadCount}</div>
          ) : null}
          <Row className="mt-2">
            <Button
              id={`${idPrefix}-reset-button`}
              color="secondary"
              className="ml-auto mr-2"
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button
              id={`${idPrefix}-submit-button`}
              color="primary"
              onClick={submitForm}
              className="mr-3"
              disabled={!isValid}
            >
              Confirm
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BulkPurchaseModal;
