import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { agentPagination } from '@/graphql/queries';

const AgentSelect: React.SFC<SelectFieldProps<any>> = ({ ...props }) => {
  const client = useApolloClient();

  const loadOptions = (...args) => {
    const [inputValue, , additional = {}] = args;
    const { page } = additional;

    return client
      .query({
        query: agentPagination,
        variables: {
          page: page || 1,
          perPage: 25,
          filter: {
            q: inputValue || '',
          },
          orderBy: {
            firstName: 'ASC',
          },
        },
      })
      .then((response) => {
        const items = response.data.agentPagination.items
          .map((a: any) => a.destinations)
          .reduce((acc: any[], dests: any[]) => acc.concat(dests), []);

        return {
          options: items,
          hasMore: response.data.agentPagination.pageInfo.hasNextPage,
          additional: {
            ...additional,
            page: page + 1,
          },
        };
      });
  };

  return (
    <SelectField
      pagination
      additional={{
        page: 1,
      }}
      debounceTimeout={350}
      delay={350}
      getOptionLabel={(opt: any) => {
        return `${opt.label}`;
      }}
      valueKey="agentID"
      defaultOptions
      raw
      loadOptions={loadOptions}
      {...props}
    />
  );
};

export default AgentSelect;
