import gql from 'graphql-tag';
import { ConnectionVsoftFragment } from '@/graphql/fragments';

export default gql`
  query getVsoftConnection {
    vsoftConnections {
      ...ConnectionVsoftFragment
    }
  }
  ${ConnectionVsoftFragment}
`;
