import gql from 'graphql-tag';
import { ReportFragment } from '@/graphql/fragments';

export default gql`
  query reports {
    reports {
      ...ReportFragment
    }
  }
  ${ReportFragment}
`;
