import { Field } from '@availity/form';
import { FieldArray, useField } from 'formik';
import React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Badge, Button, Col, Label, Row } from 'reactstrap';

interface HeaderProps {
  name: string;
  label?: string;
}

const KeyValueArrayField: React.FC<HeaderProps> = ({ name, label }) => {
  const [{ value: headers }] = useField(name);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          {label && (
            <Label className="d-flex align-items-center">
              {label}{' '}
              <Badge
                className="cursor-pointer ml-2"
                color="primary"
                size="sm"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
              >
                <FaPlus />
              </Badge>
            </Label>
          )}
          {headers.map((_: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={index}>
              <Col xs={5}>
                <Field
                  name={`${name}.${index}.key`}
                  // size="sm"
                  placeholder="Key"
                />
              </Col>
              <Col xs={5}>
                <Field
                  name={`${name}.${index}.value`}
                  // size="sm"
                  placeholder="Value"
                />
              </Col>
              <Col xs={{ size: 1, offset: 1 }}>
                <Button
                  color="danger"
                  // size="sm"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      )}
    />
  );
};

export default KeyValueArrayField;
