/* eslint-disable no-script-url */
/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useRef, useState } from 'react';
import { formatCurrencyForDisplay } from '@/utils';
import classNames from 'classnames';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import { FaDownload } from 'react-icons/fa';
import { Badge, Button } from 'reactstrap';
import { Tooltip } from '@/components';
import { TableRowProps } from '../../Leads/components/TableRow';

const getStatusBadge = (
  status: any,
  error: string,
  id: string
): ReactElement => {
  const formattedError = error?.toLowerCase().trim();
  if (formattedError === 'no leads found') {
    return (
      <Badge
        style={{ fontSize: 13 }}
        pill
        id={id}
        color={classNames({
          warning: status === 'pending',
        })}
      >
        No leads found{' '}
        <Tooltip
          id="bulkNoLeads"
          helpMessage="Your filter request did not return any matching leads"
        />{' '}
      </Badge>
    );
  }
  return (
    <Badge
      style={{ fontSize: 13 }}
      pill
      id={id}
      color={classNames({
        success: status === 'completed',
        primary: status === 'processing',
        warning: status === 'pending',
        danger: status === 'failed',
      })}
    >
      {classNames({
        Completed: status === 'completed',
        Processing: status === 'processing',
        Pending: status === 'pending',
        Failed: status === 'failed',
      })}
    </Badge>
  );
};

const TableRow: React.FC<TableRowProps> = ({ columns, ...purchase }) => {
  const downloadLink = useRef<any>();
  const [link, setLink] = useState<any>('');

  return (
    <tr key={purchase.leadBulkID}>
      {columns.map((column, key: number) => {
        const { dataField } = column;
        const displayValue = (): any => {
          switch (dataField) {
            case 'created':
              return moment(purchase[dataField])
                .utcOffset('-05:00')
                .format('h:mm:ss, MM/DD/YYYY');
            case 'status':
              return getStatusBadge(
                purchase[dataField],
                purchase.error,
                purchase.leadBulkID
              );
            case 'price':
              return formatCurrencyForDisplay(purchase[dataField]);
            case 'estimatedPrice': {
              if (purchase.status !== 'completed') {
                return 0;
              }
              return formatCurrencyForDisplay(purchase.totalAmountCharged);
            }
            case 'requestedLeadCount': {
              if (purchase.status !== 'completed') {
                return 0;
              }
              return purchase.completedLeadCount;
            }
            case 'completedLeadsCSVFileName': {
              const bulkLeadReportKey = `${purchase.agentID}/bulk/completed/${purchase.priceTierID}/${purchase.addedDate}/${purchase[dataField]}`;
              return purchase.status === 'completed' ? (
                <div>
                  <Button
                    id={`${purchase.leadBulkID}-download-button`}
                    size="sm"
                    disabled={!purchase[dataField]}
                    color={
                      purchase.status === 'completed' && purchase[dataField]
                        ? 'primary'
                        : 'secondary'
                    }
                    className="ml-1"
                    onClick={() => {
                      Storage.get(bulkLeadReportKey)
                        .then((data: any) => {
                          setLink(data);
                          return downloadLink.current.click();
                        })
                        // eslint-disable-next-line no-console
                        .catch((error) => console.error(error));
                    }}
                  >
                    <FaDownload />
                  </Button>
                  <a
                    ref={downloadLink}
                    href={link}
                    download={purchase[dataField]}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    &nbsp;
                  </a>
                </div>
              ) : null;
            }
            default:
              return purchase[dataField];
          }
        };
        return (
          <td
            className="align-middle py-2 no-wrap-centered"
            key={`${key}-${dataField}`}
          >
            {displayValue()}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
