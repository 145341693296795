import { useUser } from '@/hooks';
import React, { createContext, useState, useEffect } from 'react';

export const CampaignsNetworkContext = createContext({
  networkID: '',
  changeNetwork: (nId: string) => {},
});

export const CampaignsNetworkProvider: React.FC = (props) => {
  const { user } = useUser();
  const [networkID, setNetworkID] = useState(user.networkID);

  useEffect(() => {
    if (user) {
      setNetworkID(user.networkID);
    }
  }, [user]);

  return (
    <CampaignsNetworkContext.Provider
      value={{ networkID, changeNetwork: setNetworkID }}
      {...props}
    />
  );
};
