import React from 'react';

interface NotificationIconProps {
  color?: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
}

const NotificationIcon: React.SFC<NotificationIconProps> = ({
  color = 'danger',
}) => (
  <div style={{ position: 'relative', height: 0, width: 0 }}>
    <div
      className={`bg-${color}`}
      style={{
        position: 'absolute',
        top: -8,
        left: -2,
        borderWidth: 20,
        borderRadius: 5,
        height: 6,
        width: 6,
      }}
    />
  </div>
);

export default NotificationIcon;
