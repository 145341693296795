import React from 'react';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { Field } from '@availity/form';
import { useField } from 'formik';

const CleanCard: React.FC = () => {
  const [{ value: rejectDuplicates }] = useField('rejectDuplicates');

  return (
    <Card body className="mb-3 h-100">
      <CardTitle tag="h4" className="lead text-uppercase">
        Clean
      </CardTitle>
      <Row>
        <Col xs={12}>
          <Field
            name="rejectDuplicates"
            groupClassName="bg-dark text-white"
            label={
              <>
                Check for duplicates{' '}
                {/* <PriceBadge
                      price={network!.vendorLayerPrices?.rejectDuplicates || 0}
                    /> */}
              </>
            }
            type="checkbox"
          />
          {rejectDuplicates && (
            <Field
              label="Phone numbers should be considered duplicates ( Days )"
              name="phoneDupeDays"
              type="number"
              groupClassName="bg-dark text-white"
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default CleanCard;
