/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { PaginationControls, DebouncedSearch } from '@/components';

const roles = [
  {
    label: 'User',
    value: 'USER',
  },
  {
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
];

export interface SearchControlsProps {
  showManager?: boolean | true;
  inputPlaceholder?: string;
}

const SearchControls: React.FunctionComponent<SearchControlsProps> = ({
  inputPlaceholder = 'Search by keyword...',
}) => (
  <div className="d-flex py-3">
    <InputGroup>
      <DebouncedSearch
        name="searchText"
        placeholder={inputPlaceholder}
        style={{
          maxWidth: 550,
        }}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <FaSearch size={14} />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
    <div className="d-flex">
      <PaginationControls
        className="d-flex justify-content-center"
        listClassName="mb-0"
        pageRange={3}
        marginPages={0}
        directionLinks
        endLinks
      />
    </div>
  </div>
);

export default SearchControls;
