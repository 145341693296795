import gql from 'graphql-tag';
import { VendorLayerFragment } from '../fragments';

export default gql`
  mutation upsertVendorLayer($input: CreateVendorLayerInput) {
    upsertVendorLayer(input: $input) {
      ...VendorLayerFragment
    }
  }
  ${VendorLayerFragment}
`;
