import gql from 'graphql-tag';
import { BulkPurchaseFragment } from '../fragments';

export default gql`
  query agentBulkPagination($agentID: String!, $key: String) {
    agentBulkPagination(agentID: $agentID, key: $key) {
      items {
        ...BulkPurchaseFragment
      }
      key
      totalCount
    }
  }
  ${BulkPurchaseFragment}
`;
