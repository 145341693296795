import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';

import { updateDestination as updateDestinationMutation } from '@/graphql/mutations';
import { destinations as destinationsQuery } from '@/graphql/queries';
import { uuid } from 'uuidv4';
import { NotificationContext } from '@/context';
import { Form, Field } from '@availity/form';
import { SelectField } from '@availity/select';
import { Destination } from '@sonic-web-dev/types';
import { useDestinations, useUser } from '@/hooks';

interface FormProps {
  destination: Destination;
  onCreate: () => void;
  insert?: (obj: any) => void;
  replace?: (obj: any) => void;
}

const DestinationForm: React.SFC<FormProps> = ({
  destination,
  onCreate,
  insert,
  replace,
}) => {
  const {
    destID,
    label,
    destination: _destination,
    destinationType,
    destinationName,
  } = destination;

  const { create } = useContext(NotificationContext);
  const { user } = useUser();
  const { destinations } = useDestinations(user.agentID);

  const [updateDestination] = useMutation(updateDestinationMutation, {
    update(cache, data) {
      const { updateDestination } = data.data;

      const destinations: any = cache.readQuery({
        query: destinationsQuery,
        variables: {
          agentID: user.agentID,
        },
      });

      const exists = destinations.destinations.find(
        ({ destID }: Destination) => {
          return updateDestination.destID === destID;
        }
      );

      if (exists) {
        if (replace) {
          replace!({
            label: updateDestination.destinationName,
            destinationType: updateDestination.destinationType,
            destination: updateDestination.destination,
            uiID: updateDestination.uiID,
            destID: updateDestination.destID,
            agentID: updateDestination.agentID,
          });
        }
        create({
          message: 'Successfully updated destination',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
          timeout: 1000,
        });
      } else {
        create({
          message: 'Successfully created destination',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
          timeout: 1000,
        });

        if (insert) {
          insert({
            label: updateDestination.destinationName,
            destinationType: updateDestination.destinationType,
            destination: updateDestination.destination,
            uiID: updateDestination.uiID,
            destID: updateDestination.destID,
            agentID: updateDestination.agentID,
          });
        }
      }
      cache.writeQuery({
        query: destinationsQuery,
        variables: {
          agentID: user.agentID,
        },
        data: {
          destinations: destinations.destinations,
        },
      });
      onCreate();
    },
  });

  return (
    <Form
      initialValues={{
        destinationName: label || destinationName || '',
        destination: _destination || '',
        destinationType: destinationType?.toUpperCase() || 'EMAIL',
      }}
      onSubmit={async ({ destination, destinationType, destinationName }) => {
        const body = {
          destID: destID || uuid(),
          destinationName,
          destination,
          destinationType: destinationType.toLowerCase(),
          status: 'active',
        };

        updateDestination({
          variables: {
            input: {
              destination: {
                ...body,
              },
            },
          },
        });
      }}
      validationSchema={yup.object().shape({
        destinationName: yup.string().required('This field is required.'),
        destinationType: yup
          .string()
          .oneOf(['EMAIL'])
          .required('This field is required.'),
        destination: yup
          .string()
          .required('This field is required.')
          .when(
            ['destinationType'],
            (destinationType: string, schema: yup.StringSchema) => {
              if (destinationType === 'EMAIL') {
                return schema.email('Email must be valid.');
              }
              return schema;
            }
          )
          .test(
            'duplicate',
            'Email destination already exists.',
            function testDuplicate(email: string) {
              if (email === _destination) return true;
              return !destinations
                .filter((n) => n.destinationType === 'email')
                .map((n) => n.destination)
                .includes(email);
            }
          ),
      })}
    >
      {() => (
        <>
          <Field
            name="destinationName"
            placeholder="Destination Name..."
            label="Destination Name"
            type="text"
          />
          <div
            className={
              destinationType?.toUpperCase() === undefined || 'EMAIL'
                ? 'd-none'
                : ''
            }
          >
            <SelectField
              label="Destination Type"
              name="destinationType"
              labelKey="label"
              valueKey="value"
              options={[
                {
                  label: 'Email',
                  value: 'EMAIL',
                },
              ]}
            />
          </div>
          <Field
            name="destination"
            type="email"
            label="Email"
            placeholder="example@somewhere.com"
          />
          <Button color="primary" size="lg" block type="submit">
            Save
          </Button>
        </>
      )}
    </Form>
  );
};

export default DestinationForm;
