import gql from 'graphql-tag';
import { PriceTierFragment } from '../fragments';

export default gql`
  query tierQuery($id: ID!) {
    tier(id: $id) {
      ...PriceTierFragment
    }
  }
  ${PriceTierFragment}
`;
