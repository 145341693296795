import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Button,
} from 'reactstrap';
import Amplify, { Auth } from 'aws-amplify';

const isLeadtopia = window.location.href.includes('leadtopia');
const isAmericafirst = window.location.href.includes('americafirstleads');

export const DenyApproved: React.FunctionComponent<{ isSonic: boolean }> = ({
  isSonic,
}) => {
  const apolloClient = useApolloClient();

  return (
    <Card>
      <CardBody>
        <CardTitle>Approval required</CardTitle>
        <CardText>
          {isSonic
            ? 'Welcome to Sonic Leads. Your account is pending approval by our Relationship Team. There is nothing you need to do, our team has been notified and will confirm via email when completed.'
            : `Hello, Your account must be approved before you can use marketplace services. ${
                isLeadtopia || isAmericafirst ? 'An admin' : 'Your region admin'
              } has been notified, and there is no further action needed.`}
        </CardText>
      </CardBody>
      <CardFooter>
        <Button
          color="primary"
          onClick={() => {
            Amplify.Auth.signOut()
              .then(() => apolloClient.resetStore())
              .catch((error: any) => console.error(error));
          }}
        >
          Return to Sign In Page
        </Button>
      </CardFooter>
    </Card>
  );
};

export const DenySonic: React.FunctionComponent<{ isSonic: boolean }> = ({
  isSonic,
}) => {
  const apolloClient = useApolloClient();

  if (
    window.location.href.includes('leadtopia') ||
    window.location.href.includes('americafirstleads')
  ) {
    Amplify.Auth.signOut()
      .then(() => apolloClient.resetStore())
      .catch((error: any) => console.error(error));
    return (
      <Card>
        <CardBody>
          <CardTitle>Oops</CardTitle>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Oops</CardTitle>
        <CardText>
          {`Looks like you logged in with the wrong user for your ${
            isSonic ? 'Sonic Leads' : 'Marketplace'
          }
          account. If you are trying to access ${
            isSonic ? 'Sonic Leads' : 'Marketplace'
          } please try again with
          a different email.`}
        </CardText>
        <CardText>
          If you are trying to access{' '}
          {isSonic ? 'the Marketplace' : 'Sonic Leads'}, please use{' '}
          {isSonic ? (
            <a href="https://app.ushamarketplace.com">
              app.ushamarketplace.com
            </a>
          ) : (
            <a href="https://app.sonicleads.com">app.sonicleads.com</a>
          )}
        </CardText>
      </CardBody>
      <CardFooter>
        <Button
          color="primary"
          onClick={() => {
            Amplify.Auth.signOut()
              .then(() => apolloClient.resetStore())
              .catch((error: any) => console.error(error));
          }}
        >
          Return to Sign In Page
        </Button>
      </CardFooter>
    </Card>
  );
};

export const DenyBeta: React.FunctionComponent = () => {
  const apolloClient = useApolloClient();
  return (
    <Card>
      <CardBody>
        <CardTitle>Approval Required</CardTitle>
        <CardText>
          Your account must be approved before you can use the beta version of
          marketplace.
        </CardText>
      </CardBody>
      <CardFooter>
        <Button
          color="primary"
          onClick={() => {
            Amplify.Auth.signOut()
              .then(() => apolloClient.resetStore())
              .catch((error: any) => console.error(error));
          }}
        >
          Return to Sign In Page
        </Button>
      </CardFooter>
    </Card>
  );
};
