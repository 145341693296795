import gql from 'graphql-tag';

export default gql`
  mutation addCredit($cardId: String, $amount: Float, $brokerID: String) {
    accountAddCredit(cardId: $cardId, amount: $amount, brokerID: $brokerID) {
      credit
      charge {
        amount
        status
        id
        captured
        source {
          last4
        }
        created
      }
    }
  }
`;
