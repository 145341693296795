import React, { useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FaUserFriends, FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import MoneyInput from '@/components/MoneyInput';
import formatCurrencyForDisplay from '../Utils';

interface RowProps {
  navigateToViewProfile: (arg: any) => {};
  transferStore: any;
  setAmount: (amout: number, id: string) => any;
  color: string;
  id: string;
  agentID: string;
  firstName: string;
  lastName: string;
  email: string;
  totalCredit: number;
  contributedCredit: number;
  team: string;
  role: 'admin' | 'manager' | 'super_admin' | 'user';
}

const AgentTableRow: React.SFC<RowProps> = ({
  id,
  navigateToViewProfile,
  color,
  firstName,
  lastName,
  email,
  totalCredit,
  contributedCredit,
  agentID,
  team = '',
  role,
  setAmount,
  transferStore,
}) => {
  const transferAmount = transferStore[id] || 0;
  const setFinalCredit = (amount: number): any =>
    setAmount(amount - totalCredit, id);
  const setTransferAmount = (amount: number): any => setAmount(amount, id);

  let transferErrorMessage;

  if (
    totalCredit >= contributedCredit &&
    contributedCredit + transferAmount < 0
  ) {
    transferErrorMessage = 'You cannot withdraw more than you contributed.';
  } else if (
    totalCredit < contributedCredit &&
    totalCredit + transferAmount < 0
  ) {
    transferErrorMessage = 'You cannot withdraw more than the agent has.';
  } else {
    transferErrorMessage = '';
  }

  return (
    <tr>
      <td className="pl-4 text-left">
        <ButtonGroup size="sm">
          <Button tag={Link} to={`/profile/${id}`} outline title="View Profile">
            <FaEye color={color} />
          </Button>
        </ButtonGroup>
      </td>
      <td>
        {team}
        {team !== '' && team !== null && <br />}
        <i>
          {firstName} {lastName}
        </i>
      </td>

      <td>{email}</td>
      <td style={{ minWidth: '72px' }}>
        {formatCurrencyForDisplay(totalCredit)}
      </td>
      <td style={{ minWidth: '72px' }}>
        {formatCurrencyForDisplay(contributedCredit)}
      </td>
      <td>
        <InputGroup size="sm" style={{ minWidth: '100px' }}>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <MoneyInput
            minimum={-contributedCredit}
            onChange={setTransferAmount}
            value={transferAmount}
          />
        </InputGroup>
      </td>
      <td>
        <FormGroup style={{ margin: 0 }}>
          <InputGroup size="sm" style={{ minWidth: '100px' }}>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <MoneyInput
              minimum={totalCredit - contributedCredit}
              onChange={setFinalCredit}
              value={totalCredit + transferAmount}
            />
          </InputGroup>
          <FormText
            color="danger"
            style={{
              height: '19px',
              position: 'relative',
            }}
          >
            <span
              style={{
                position: 'absolute',
                right: 0,
                whiteSpace: 'nowrap',
              }}
            >
              {transferErrorMessage}
            </span>
          </FormText>
        </FormGroup>
      </td>
    </tr>
  );
};

export default AgentTableRow;
