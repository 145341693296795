import React, { useState } from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Input,
  Table,
  Form,
  FormGroup,
  Label,
  FormText,
} from 'reactstrap';
// import { Form, FormGroup } from '@availity/form';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { currentAgentQuery, creditBuckets } from '@/graphql/queries';
import { updateAutoFundSettings } from '@/graphql/mutations';
import { usePageLoader, useUser, useNotifications } from '@/hooks';
import { ButtonToggle, Tooltip, MoneyField } from '@/components';

interface TransferSideBarProps {
  transferStore: any;
}

interface Buckets {
  [key: string]: boolean;
}

interface BalanceRow {
  key: string;
  title: string;
  balance: number;
  disable: boolean;
  selected?: boolean;
  onChange: any;
}

const makeRow = ({
  title,
  balance,
  key,
  selected,
  disable,
  onChange,
}: BalanceRow) => {
  if (!disable) {
    return (
      <FormGroup check style={{ padding: 20 }}>
        <Label check>
          <Input
            type="checkbox"
            checked={selected}
            onChange={(event) => onChange(key, event.target.value)}
          />{' '}
          {`${title}`}
        </Label>
        <FormText>{`Current balance: ${formatCurrencyForDisplay(
          balance
        )}`}</FormText>
      </FormGroup>
    );
  }
};

const CreditBalanceSideBar: React.FunctionComponent<TransferSideBarProps> = ({
  transferStore,
}) => {
  //const { create: createNotification } = useNotifications();

  const [selectedBuckets, setSelectedBuckets] = useState<Buckets>();

  const { user: currentAgent = {} as any, loading: loadingUser } = useUser();
  const { data: data = {}, refetch } = useQuery(creditBuckets, {
    variables: {
      agentID: currentAgent.agentID,
    },
  });

  const { creditBuckets: creditBucketData = [] } = data;
  const totalBalance = creditBucketData.reduce((total, b) => {
    return total + b.balance;
  }, 0);
  const availableTransferBalance = creditBucketData
    .filter((b) => {
      return selectedBuckets && selectedBuckets[b.creditBucketID];
    })
    .reduce((total, b) => {
      return total + b.balance;
    }, 0);

  const totalTransferAmount = (): number => {
    return Object.entries(transferStore).reduce((sum, [_, value]) => {
      sum += parseInt(value as string, 10);
      return sum;
    }, 0);
  };
  const totalAgentsToTransfer = (): number => {
    return Object.entries(transferStore).length;
  };

  console.log(totalTransferAmount() - availableTransferBalance);
  console.log(!(availableTransferBalance - totalTransferAmount() > -1));

  return (
    <Card
      body
      className="d-flex flex-row justify-content-between align-content-stretch"
      // style={{
      //   width: 250,
      //   position: 'fixed',
      //   right: 24,
      //   height: 'fit-content',
      // }}
    >
      {/* <CardHeader>Transfer to Agents</CardHeader> */}

      {/* <hr style={{ backgroundColor: 'gray' }} /> */}
      <div style={{ width: '35%' }}>
        <Form>
          <span>
            <i>Select Bucket(s)</i>
            <Tooltip
              id="sidecardbucket"
              helpMessage={`Select which buckets you want to transfer funds from.
                Tranfsers are processed in FIFO order and conlt only be sent/retrieved from one bucket type.
                Please see our help docs for help transfering credits between buckets`}
            />{' '}
          </span>
          <div className="d-flex flex-wrap">
            {[...creditBucketData, ...creditBucketData].map((c: any) => {
              let disable = false;
              const selectedBucketIDs = Object.keys(
                selectedBuckets ? selectedBuckets : {}
              );
              const chosenBuckets = selectedBucketIDs.filter((id) => {
                return selectedBuckets ? selectedBuckets[id] : false;
              });

              const pieces = c.creditBucketID.split(':');
              const part =
                pieces[0].length === 9
                  ? `${pieces[0]}:${pieces[1]}`
                  : pieces[0];

              if (chosenBuckets.length > 0) {
                if (!chosenBuckets.find((b) => b.includes(part))) {
                  disable = true;
                  console.log('should disable ' + c.creditBucketID);
                }
              }

              return makeRow({
                title: c.description,
                balance: c.balance,
                key: c.creditBucketID,
                disable: disable,
                selected: selectedBuckets
                  ? selectedBuckets[c.creditBucketID]
                  : false,
                onChange: (key, value) => {
                  if (selectedBuckets) {
                    const selectedBucketIDs = Object.keys(selectedBuckets);
                    const bucketIDPieces = key.split(':');
                    const hashPart =
                      bucketIDPieces[0].length === 9
                        ? `${bucketIDPieces[0]}:${bucketIDPieces[1]}`
                        : bucketIDPieces[0];
                    console.log(selectedBuckets);
                    console.log(key);
                    console.log(hashPart);
                    console.log(selectedBucketIDs);
                    console.log(
                      Object.values(selectedBuckets).reduce((active, b) => {
                        if (b) {
                          return true;
                        }
                        return active;
                      }, false)
                    );

                    if (
                      Object.values(selectedBuckets).reduce((active, b) => {
                        if (b) {
                          return true;
                        }
                        return active;
                      }, false)
                    ) {
                      if (
                        selectedBucketIDs.find((b) => {
                          console.log(selectedBuckets[b]);
                          return b.includes(hashPart) && selectedBuckets[b];
                        })
                      ) {
                      } else {
                        console.log('should not check');
                        return;
                      }
                    }
                    setSelectedBuckets({
                      ...selectedBuckets,
                      [key]: selectedBuckets[key]
                        ? !selectedBuckets[key]
                        : true,
                    });
                  } else {
                    setSelectedBuckets({
                      [key]: true,
                    });
                  }
                },
              });
            })}
            {/* <hr style={{ backgroundColor: 'gray' }} /> */}
          </div>
        </Form>
      </div>

      <div>
        <span>
          <i>Balance Summary</i>
          <Tooltip
            id="sidecardbalance"
            helpMessage="Your current balance and the available based on your bucket selections below"
          />{' '}
        </span>
        <br />
        <table style={{ width: '100%' }}>
          <tr style={{ color: 'green' }}>
            <td>Total:</td>
            <td style={{ textAlign: 'right' }}>
              {formatCurrencyForDisplay(totalBalance)}
            </td>
          </tr>
          <tr style={{ color: 'yellow' }}>
            <td>Available to Transfer:</td>
            <td style={{ textAlign: 'right' }}>
              {formatCurrencyForDisplay(
                availableTransferBalance - totalTransferAmount()
              )}
            </td>
          </tr>
        </table>
      </div>

      <div>
        <i>Transfer Summary</i>
        <br />
        <table style={{ width: '100%' }}>
          <tr>
            <td>Total Agents:</td>
            <td style={{ textAlign: 'right' }}>{totalAgentsToTransfer()}</td>
          </tr>
          <tr>
            <td>Total Amount:</td>
            <td style={{ textAlign: 'right' }}>
              {formatCurrencyForDisplay(totalTransferAmount())}
            </td>
          </tr>
        </table>
        <hr style={{ backgroundColor: 'gray' }} />
        <ButtonGroup>
          <Button
          ///disabled={disableReset}
          //onClick={reset}
          >
            Reset
          </Button>
          <Button
            color="primary"
            disabled={
              !(availableTransferBalance - totalTransferAmount() > -1) ||
              totalTransferAmount() < 1
            }
            // onClick={() => setIsOpen(true)}
          >
            Transfer
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  );
};

export default CreditBalanceSideBar;
