import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Button, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import url from 'url';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import aws4 from 'aws4';
import { Vars } from '@/utils';
import AuthHeader from './AuthHeader';

const isSonic = window.location.href.includes('sonicleads');

const sonicResetPassword = async (username: string): Promise<void> => {
  const uri = `${Vars.uri}/reset-password`;
  const options = {
    method: 'POST',
    body: JSON.stringify({
      username,
    }),
  };

  const creds = await Auth.currentCredentials();
  const credentials = {
    secretAccessKey: creds.secretAccessKey,
    accessKeyId: creds.accessKeyId,
    sessionToken: creds.sessionToken,
  };
  const signable: any = {};
  const urlObject = url.parse(uri);
  signable.host = urlObject.host;
  signable.path = urlObject.path;
  ['method', 'body', 'headers', 'region', 'service'].forEach((key: string) => {
    signable[key] = options[key];
  });

  aws4.sign(signable, credentials);
  options.headers = signable.headers;
  // options.data = options.body;
  // delete options.body;
  await fetch(uri, options);
};

class CustomForgotPassword extends ForgotPassword {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
  }

  public showComponent(): JSX.Element {
    return (
      <AuthHeader cardTitle="Reset your password">
        {this.state.delivery ? (
          <Formik
            onSubmit={async (values) => {
              let { username } = this.state;

              username = isSonic
                ? username.toLowerCase().replace('@', '+1@')
                : username.toLowerCase();
              try {
                await Auth.forgotPasswordSubmit(
                  username,
                  values.code,
                  values.password
                );

                this.changeState('signIn');
                this.setState({ delivery: null });
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              code: '',
              password: '',
            }}
            validationSchema={yup.object().shape({
              code: yup.string().required('Please enter code'),
              password: yup.string().required('Please enter a new Password.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <div className="mb-4">
                  A Code was sent to the email associated to this account.
                </div>
                {!this.state.delivery?.sonic && (
                  <>
                    <BlockUI blocking={isSubmitting}>
                      <Field
                        id="code"
                        name="code"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        placeholder="Enter your code"
                        label="Code"
                      />
                      <Field
                        name="password"
                        type="password"
                        onChange={this.handleInputChange}
                        placeholder="Password"
                        label="New password"
                      />
                    </BlockUI>
                    <LoadingButton
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      color="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      Save
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            onSubmit={async (values) => {
              try {
                const username = isSonic
                  ? values.username.toLowerCase().replace('@', '+1@')
                  : values.username.toLowerCase();

                if (isSonic) {
                  await sonicResetPassword(username);

                  this.setState({
                    delivery: { sonic: true },
                    username: values.username,
                  });
                } else {
                  const data = await Auth.forgotPassword(username);

                  this.setState({
                    delivery: data.CodeDeliveryDetails,
                    username: values.username,
                  });
                }
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              username: '',
            }}
            validationSchema={yup.object().shape({
              username: yup.string().required('Please enter an email.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <BlockUI blocking={isSubmitting}>
                  <Field
                    id="username"
                    name="username"
                    type="email"
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      event.target.value = value.toLowerCase();
                      this.handleInputChange(event);
                    }}
                    placeholder="Enter your email"
                    label="Email"
                  />
                </BlockUI>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  color="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  Send Code
                </LoadingButton>
              </Form>
            )}
          </Formik>
        )}
        <div className="d-flex flex-column pt-2">
          <span>
            <Button color="link" onClick={() => this.changeState('signIn')}>
              Back to Sign In
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomForgotPassword;
