import React from 'react';
import { Alert, Button } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ErrorBoundaryProps extends RouteComponentProps {
  allowDismiss?: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  {
    hasError: boolean;
  }
> {
  public constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): any {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: any): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public resetErrorBoundary = (): void => {
    const { history } = this.props;
    this.setState({
      hasError: false,
    });
    history.push('/');
  };

  public render(): React.ReactNode {
    const { children, allowDismiss } = this.props as any;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Alert
          color="danger"
          className="d-flex justify-content-between align-items-center"
          style={{
            height: 'fit-content',
          }}
        >
          <span>Something went wrong.</span>
          {allowDismiss && (
            <Button onClick={() => this.resetErrorBoundary()}>Dismiss</Button>
          )}
        </Alert>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
