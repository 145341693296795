import React from 'react';
import { usePagination } from '@availity/pagination';

const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

export default NoItemsMessage;
