import gql from 'graphql-tag';

export default gql`
  mutation addCredit(
    $amount: Float
    $losingAssignmentID: losingAssignmentID
    $gainingAssignmentID: gainingAssignmentID
  ) {
    accountAddCredit(
      amount: $amount
      losingAssignmentID: $losingAssignmentID
      gainingAssignmentID: $gainingAssignmentID
    ) {
      creditBuckets {
        ...CreditBucketFragment
      }
    }
  }
`;
