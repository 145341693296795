import gql from 'graphql-tag';

import VendorPartial from './vendorPartial';

const VendorLayerFragment = gql`
  fragment VendorLayerFragment on VendorLayer {
    __typename
    id
    layerID
    vendorID
    destinations {
      destID
      agentID
      label
      destinationType
      destination
      uiID
    }
    vendor {
      ${VendorPartial}
    }
    leadQualityTier
    addonPrice
    vendorSourceCampaign
    blockDuplicates
    passDuplicates
    vendorPrice
    rejectDisconnected
    rejectDuplicates
    rejectDNC
    enhanceProfile
    minimumRefresh
  }
`;

export default VendorLayerFragment;
