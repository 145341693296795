import { useQuery } from '@apollo/react-hooks';
import { agentPagination } from '@/graphql/queries';

const useNetworkAgents = (agentID?: string): [any[], boolean] => {
  const { data: networkAgentsData, loading } = useQuery(agentPagination, {
    variables: {
      filter: {
        network: true,
        q: '',
      },
      orderBy: {
        firstName: 'ASC',
      },
    },
  });
  return [
    networkAgentsData?.agentPagination.items.filter(
      (a) => a.agentID !== agentID
    ) || [],
    loading,
  ];
};

export default useNetworkAgents;
