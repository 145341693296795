import gql from 'graphql-tag';
import { DestinationFragment } from '@/graphql/fragments';

export default gql`
  query getMyAgentEmailDestinations($agentID: String!) {
    getMyAgentEmailDestinations(agentID: $agentID) {
      ...DestinationFragment
    }
  }
  ${DestinationFragment}
`;
