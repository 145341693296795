import gql from 'graphql-tag';
import { CampaignFragment } from '../fragments';

export default gql`
  query leadQuery {
    agg: leadCount(field: "campaignID") {
      node {
        ...CampaignFragment
      }
    }
  }
  ${CampaignFragment}
`;
