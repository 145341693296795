import React from 'react';
import { Button } from 'reactstrap';

const ButtonIcon = ({ color, iconTag: IconTag, ...otherProps }) => (
  <Button color={color} outline {...otherProps}>
    <IconTag />
  </Button>
);

export default ButtonIcon;
