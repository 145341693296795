import gql from 'graphql-tag';

import { SpendingPeriodFragment } from '@/graphql/fragments';

export default gql`
  mutation updateSpendingPeriodsMutation(
    $input: RelayUpdateSpendingPeriodsSpendingPeriodInput!
  ) {
    updateSpendingPeriods(input: $input) {
      success
      values {
        ...SpendingPeriodFragment
      }
    }
  }
  ${SpendingPeriodFragment}
`;
