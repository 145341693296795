import { useContext } from 'react';
import {
  NotificationContext,
  NotificationContextType,
} from '@/context/NotificationContext';

const useNotifications = (): NotificationContextType =>
  useContext(NotificationContext);

export default useNotifications;
