import gql from 'graphql-tag';

export default gql`
  subscription liveTransferCallInfoNotification {
    notification: liveTransferCallInfoNotification {
      id
      timeout
      phoneNumber
      firstName
      lastName
      state
      type
    }
  }
`;
