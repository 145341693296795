import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useNotifications, useUser } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { currentAccountQuery } from '@/graphql/queries';
import { addOrUpdateCard } from '@/graphql/mutations';
import { getPrivacyPolicy } from '@/utils/documents';
import Payment from './Payment';

const CreditCardModal: React.SFC<{
  corpID: string;
  isOpen: boolean;
  toggle: () => void;
}> = ({ corpID, isOpen, toggle }) => {
  const { create: createNotification } = useNotifications();
  const { user } = useUser();
  const [addOrUpdateCreditCard, { data, loading: updating }] = useMutation(
    addOrUpdateCard,
    {
      update(cache, { data: { accountAddOrUpdateCard } }) {
        const { account } = cache.readQuery({
          query: currentAccountQuery,
        }) as any;

        cache.writeQuery({
          query: currentAccountQuery,
          data: {
            account: {
              ...account,
              card: accountAddOrUpdateCard,
            },
          },
        });
      },
      onCompleted: () => {
        createNotification({
          message: 'Successfully updated card',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
        toggle();
      },
      onError: (error) =>
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
          color: 'danger',
        }),
    }
  );

  // If error on update then in mutation push createNotication
  const errorMessage = '';

  const businessAddress = (corpID: string) => {
    let businessName = 'Sonic Development & Leads';
    if (corpID === 'leadTopia') {
      businessName = 'LeadTopia';
    }
    if (corpID === 'americafirst') {
      businessName = 'America First Leads';
    }
    return (
      <span className="float-right small text-muted">
        {businessName}
        <br />
        100 E Pine St
        <br />
        Suite 110
        <br />
        Orlando FL 32801
        <br />
        United States
      </span>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New Card</ModalHeader>
      <ModalBody>
        <Payment
          onCardAdded={(cardId: string) => {
            addOrUpdateCreditCard({
              variables: {
                cardId,
              },
            });
          }}
          updatingCard={updating}
          corpID={corpID}
        />
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </ModalBody>
      <ModalFooter>
        <Container>
          <Row className="mb-3">
            <Col>
              <Button
                className="float-right"
                color="secondary"
                onClick={toggle}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="small text-muted">
                This business and website are subject to, and comply with, the
                PCI standards. Your information is secure. You can review our
                privacy policy{' '}
                <a
                  href={getPrivacyPolicy(user?.corpID)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
                .
              </span>
            </Col>
          </Row>
          <Row>
            <Col>{businessAddress(user?.corpID)}</Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  );
};

export default CreditCardModal;
