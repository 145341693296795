import gql from 'graphql-tag';

export default gql`
  mutation deleteCampaignMutation($input: RelayDeleteCampaignCampaignInput!) {
    deleteCampaign(input: $input) {
      __typename
      campaignID
    }
  }
`;
