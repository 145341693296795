import gql from 'graphql-tag';
// import { LeadFragment } from '@/graphql/fragments';

export default gql`
  query nodeQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Lead {
        leadID
        phones(verify: true) {
          phone
          isPrimary
          carrier
          timeZone
          confidenceScore
          status
          type
        }
      }
    }
  }
`;
