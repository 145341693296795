/* eslint-disable no-console */
import { useEffect, useState } from 'react';

const useExtension = (): any => {
  const [isSynced, setIsSynced] = useState<boolean>(false);
  const [crmData, setCRMData] = useState<any>({});
  const [iSalesKeys, setISalesKeys] = useState<any>([]);

  const clearSync = () => {
    setIsSynced(false);
    setISalesKeys([]);
  };

  useEffect(() => {
    window.addEventListener('message', (event: any) => {
      if (event.origin === window.location.origin) {
        const { data = {} } = event;
        if (data.crms) {
          console.log('[SYNC_DATA] Listening for messages...');
          console.log(data);
          const {
            crms: { iSalesCRM },
          } = data;
          if (iSalesCRM) {
            const { vendors } = iSalesCRM;
            setISalesKeys(vendors);
          }
          setCRMData(data);
          setIsSynced(true);
        }
      }
      return true;
    });
  }, []);

  return { crmData, iSalesKeys, isSynced, clearSync };
};

export default useExtension;
