import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import {
  CampaignsDeleteCampaignModal,
  CampaignsEditSpendingPeriodModal,
  ProfileAddCreditModal,
} from '@/modals';
import CampaignTable from './Table/CampaignTable';

const CampaignPage = () => {
  const history = useHistory();
  const navigate = (campaignID: string, activeTiers: string) => {
    history.push(campaignID, { activeTiers });
  };

  const [openModal, setOpenModal] = useState('');

  const [currentCampaign, setCurrentCampaign] = useState<any>({});

  const openEditModal = openModal.startsWith('edit_spending_period');
  const openCreditModal = openModal.startsWith('add_credit');
  const opentDeleteModal = openModal.startsWith('delete_campaign');
  return (
    <div>
      {openEditModal && (
        <CampaignsEditSpendingPeriodModal
          campaign={currentCampaign.campaign}
          closeModal={() => setOpenModal('')}
          isOpen={openEditModal}
          saving={false}
        />
      )}

      {opentDeleteModal && (
        <CampaignsDeleteCampaignModal
          campaign={currentCampaign.campaign}
          closeModal={() => setOpenModal('')}
        />
      )}
      <ProfileAddCreditModal
        isOpen={openCreditModal}
        toggle={() => setOpenModal('')}
      />
      <CampaignTable
        navigate={navigate}
        setOpenModal={setOpenModal}
        setCurrentCampaign={setCurrentCampaign}
      />
    </div>
  );
};

export default withRouter(CampaignPage);
