import RefundListItem from '@/areas/Profile/components/RefundListItem';
import React from 'react';
import { ListGroup, TabPane, TabPaneProps } from 'reactstrap';

const LeadRefundTab: React.FC<TabPaneProps> = ({ lead, ...tabPaneProps }) => {
  return (
    <TabPane {...tabPaneProps}>
      <ListGroup>
        {lead ? (
          <RefundListItem color="default" lead={lead} {...lead.serviceCase} />
        ) : null}
      </ListGroup>
    </TabPane>
  );
};

export default LeadRefundTab;
