import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import { useFormikContext } from 'formik';
import { MoneyField } from '@/components';
import formatCurrencyForDisplay from '@/areas/MyAgents/Utils';
import { Field } from '@availity/form';
import {
  getSpendingPeriodString,
  minutesToTime,
  timeToMinutes,
} from '@/areas/Campaigns/Utils';
import {
  PriceTier,
  SpendingPeriod as SpendingPeriodITC,
} from '@sonic-web-dev/types';

const SpendingPeriod: React.SFC<
  SpendingPeriodITC & {
    period: number;
    isLiveTransfer: boolean;
    removeSpendingPeriod: (index: number) => void;
    price: number;
    maxLeadCountPerDay: number;
  }
> = ({
  isLiveTransfer,
  maxLeadCountPerDay,
  price,
  spendingPeriodID,
  campaignID,
  dowSun,
  dowMon,
  dowTue,
  dowWed,
  dowThu,
  dowFri,
  dowSat,
  endTime,
  startTime,
  period,
  removeSpendingPeriod,
  throttleNoMoreThan = 1,
  throttleLeadsPer = 60000,
}) => {
  const {
    values: { spendingPeriods, removeOperations },
    errors,
    setFieldValue,
  } = useFormikContext<{ spendingPeriods: any[]; removeOperations: any[] }>();
  const spendingPeriod = spendingPeriods[period];
  const FIELD_PREFIX = `spendingPeriods.${period}`;
  const attributePrefix = 'edit-spending-period-modal';

  const MIN_DAILY_SPEND = 100;

  const isThrottleZero = throttleNoMoreThan === 0 || throttleLeadsPer === 0;

  const remainingMaxSpend =
    maxLeadCountPerDay > 0
      ? spendingPeriods.reduce((t, v) => {
          return t - v.dailySpendLimit;
        }, maxLeadCountPerDay * (price || 100)) || 99999999999999999
      : 99999999999999999;

  const setDelaySpendBy = (field: string, value: number | string): void => {
    const delaySpendBy = Math.round(throttleLeadsPer / throttleNoMoreThan);

    setFieldValue(`${FIELD_PREFIX}.${field}`, parseInt(value as string, 10));
    setFieldValue(`${FIELD_PREFIX}.delaySpendBy`, delaySpendBy);
  };

  const toggleSpendingPeriodDow = (dow: string): void => {
    setFieldValue(`${FIELD_PREFIX}.dow${dow}`, !spendingPeriod[`dow${dow}`]);
  };

  const throttleLeadsPerChangeHandler = (value: number | string): void => {
    // Does not allow user to manually type anything below 1 or above 60
    if (value > 60 || !value === '') return;

    setFieldValue(
      `${FIELD_PREFIX}.throttleLeadsPer`,
      (value as number) * 60000
    );
  };

  const setThrottle = (command?: string) => {
    const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

    switch (command) {
      case 'reset':
        setFieldValue(`${FIELD_PREFIX}.throttleLeadsPer`, 0);
        setFieldValue(`${FIELD_PREFIX}.throttleNoMoreThan`, 0);
        break;
      default:
        setFieldValue(`${FIELD_PREFIX}.throttleLeadsPer`, 60000);
        setFieldValue(`${FIELD_PREFIX}.throttleNoMoreThan`, 1);
    }

    const delaySpendBy = Math.round(
      nextSpendingPeriods[period].throttleLeadsPer /
        nextSpendingPeriods[period].throttleNoMoreThan
    );

    setFieldValue(`${FIELD_PREFIX}.delaySpendBy`, delaySpendBy);
  };

  const toggleLeadThrottling = (): void => {
    if (isThrottleZero) {
      setThrottle();
    } else {
      setThrottle('reset');
    }
  };

  const throttleNoMoreThanChangeHandler = (value: string): void => {
    // Makes sure that input is a valid character
    if (!value.match(/([1-9](\d)*|^$)/)) return;
    setFieldValue(`${FIELD_PREFIX}.throttleNoMoreThan`, value);
  };

  return (
    <div>
      <div>{getSpendingPeriodString(spendingPeriod)}</div>
      <MoneyField
        id={`${attributePrefix}-daily-spend-limit`}
        size="md"
        name={`${FIELD_PREFIX}.dailySpendLimit`}
        maximum={remainingMaxSpend}
      />
      <ButtonGroup className="my-3 w-100">
        {[
          { day: 'Sun', boolean: dowSun },
          { day: 'Mon', boolean: dowMon },
          { day: 'Tue', boolean: dowTue },
          { day: 'Wed', boolean: dowWed },
          { day: 'Thu', boolean: dowThu },
          { day: 'Fri', boolean: dowFri },
          { day: 'Sat', boolean: dowSat },
        ].map(({ day, boolean }) => (
          <Button
            key={day}
            active={boolean}
            color={boolean ? 'success' : 'secondary'}
            outline={!boolean}
            onClick={() => toggleSpendingPeriodDow(day)}
          >
            {day}
          </Button>
        ))}
        <Button
          color="danger"
          onClick={() => {
            setFieldValue('removeOperations', [
              ...removeOperations,
              {
                campaignID,
                spendingPeriodID,
                status: 'deleted',
              },
            ]);
            removeSpendingPeriod(period);
          }}
        >
          Remove
        </Button>
      </ButtonGroup>
      <FormGroup>
        <InputGroup style={{ padding: '12px 0' }}>
          <InputGroupAddon addonType="prepend">from</InputGroupAddon>
          <Input
            id={`${attributePrefix}-start-time`}
            onChange={(e) =>
              setFieldValue(
                `${FIELD_PREFIX}.startTime`,
                timeToMinutes(e.target.value)
              )
            }
            type="time"
            value={minutesToTime(startTime)}
          />
          <InputGroupAddon addonType="append" style={{ marginRight: '-1px' }}>
            to
          </InputGroupAddon>
          <Input
            id={`${attributePrefix}-end-time`}
            onChange={(e) =>
              setFieldValue(
                `${FIELD_PREFIX}.endTime`,
                timeToMinutes(e.target.value)
              )
            }
            type="time"
            value={minutesToTime(endTime)}
          />
          <InputGroupAddon addonType="append">
            {/* Leaving for now but in new work it will also be the same day */}
            {startTime >= endTime ? 'next day' : 'that day'}
          </InputGroupAddon>
        </InputGroup>
        <FormText color="danger">
          {errors.spendingPeriods?.[period]?.startTime &&
            errors.spendingPeriods?.[period]?.startTime}
          {errors.spendingPeriods?.[period]?.endTime &&
            errors.spendingPeriods?.[period]?.endTime}
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label>Lead Throttling</Label>
        &nbsp;
        <input
          name="lead-throttling-checkbox"
          type="checkbox"
          checked={!isThrottleZero}
          onChange={toggleLeadThrottling}
        />
        {!isThrottleZero && (
          <InputGroup style={{ paddingTop: 12 }}>
            <InputGroupAddon addonType="prepend">No More than</InputGroupAddon>
            <Input
              id={`${attributePrefix}-throttle-leads-amount`}
              onChange={(e) => throttleNoMoreThanChangeHandler(e.target.value)}
              onBlur={(e) =>
                setDelaySpendBy('throttleNoMoreThan', e.target.value || '1')
              }
              min={1}
              max={60}
              step={1}
              type="number"
              value={throttleNoMoreThan}
            />
            <InputGroupAddon addonType="prepend">lead(s) per </InputGroupAddon>
            <Input
              id={`${attributePrefix}-throttle-lead-time`}
              onChange={(e) => throttleLeadsPerChangeHandler(e.target.value)}
              onBlur={(e: any) =>
                setDelaySpendBy(
                  'throttleLeadsPer',
                  e.target.value * 60000 || '60000'
                )
              }
              min={1}
              max={60}
              step={1}
              type="number"
              value={throttleLeadsPer / 60000 || ''}
            />
            <InputGroupAddon addonType="prepend">min </InputGroupAddon>
          </InputGroup>
        )}
        <FormText color="danger">
          {errors.spendingPeriods?.[period]?.throttleLeadsPer}
        </FormText>
      </FormGroup>
      {isLiveTransfer && (
        <Field
          name={`${FIELD_PREFIX}.phone`}
          placeholder="4079140293"
          label="Transfer Phone"
          type="text"
        />
      )}
    </div>
  );
};

export default SpendingPeriod;
