/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
// import Helmet from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  ApolloClient,
} from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { QueryClient, QueryClientProvider } from 'react-query';
import Amplify, { Storage } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn';
import AmplifyAuthenticator from '@/components/Auth/AmplifyAuthenticator';
import { Vars } from '@/utils';
import { useApolloLink } from '@/hooks';
import App from './App';
import CacheBuster from './CacheBuster';
import introspectionQueryResultData from './fragmentTypes.json';
import './styles/index.scss';
import { ErrorBoundary } from './components';
import { getImageUrl } from './utils/get-host';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const queryClient = new QueryClient();
// Use awsmobile from Env-var
Amplify.configure(Vars.awsmobile);

Storage.configure({
  AWSS3: {
    bucket: `reports.leadarena-${Vars.env}`,
    region: 'us-east-1',
  },
});

const cache = new InMemoryCache({
  fragmentMatcher,
  addTypename: false,
  dataIdFromObject: (o: any) => o?.id,
  cacheRedirects: {
    Query: {
      node: (_, args, { getCacheKey }) => {
        return getCacheKey({
          id: args.id,
        });
      },
    },
  },
});

const AuthenticatedApp: React.FunctionComponent<ISignInProps> = ({
  authState,
}) => {
  const link = useApolloLink(authState);

  const client = useMemo(() => {
    if (!link) {
      return null;
    }

    const client = new ApolloClient({
      link,
      connectToDevTools: true,
      cache,
    });

    return client;
  }, [link]);

  if (!link) return null;

  return (
    <ApolloProvider client={client as any}>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </ApolloProvider>
  );
};

const getTitle = () => {
  if (window.location.href.includes('sonicleads')) {
    return 'Sonic Leads';
  }

  if (window.location.href.includes('leadtopia')) {
    return 'LeadTopia';
  }

  if (window.location.href.includes('americafirstleads')) {
    return 'America First Leads';
  }

  return 'Lead Arena | Marketplace';
};

const getHelmet = () => {
  return (
    <Helmet>
      <title>{getTitle()}</title>
      <link
        rel="icon"
        type="image/png"
        href={getImageUrl('favicon.ico')}
        sizes="16x16"
      />
    </Helmet>
  );
};

const render = async (): Promise<any> => {
  ReactDOM.render(
    <CacheBuster>
      {getHelmet()}
      <QueryClientProvider client={queryClient}>
        <AmplifyAuthenticator>
          <AuthenticatedApp />
        </AmplifyAuthenticator>
      </QueryClientProvider>
    </CacheBuster>,
    document.querySelector('#root')
  );
};

// Render once
render();
