import { formatPhoneNumber } from '@/areas/Campaigns/Utils';
import moment from 'moment';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Col, Row, Table, TabPane } from 'reactstrap';
import { leadNodeQuery } from '@/graphql/queries';

const LTDetailsTabPane: React.FC<{
  lead: any;
  tabId: string;
  active: boolean;
}> = ({ lead, tabId, active }) => {
  return (
    <TabPane tabId={tabId}>
      <Row className="pt-2">
        <Col xs={10}>
          <span className="lead text-uppercase">Call Links</span>
          <Table borderless>
            <tbody>
              <tr>
                <td>
                  {' '}
                  {lead.recording ? (
                    <a
                      href={lead.recording}
                      rel="noopener noreferrer"
                      // style={{ color: 'blue' }}
                      target="_blank"
                    >
                      Listen to Qualifier Recording
                    </a>
                  ) : (
                    'No Qualifier Recording Availible'
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  {lead.callerReadyLink ? (
                    <a
                      href={lead.callerReadyLink}
                      rel="noopener noreferrer"
                      // style={{ color: 'blue' }}
                      target="_blank"
                    >
                      View Caller Ready Lead Form
                    </a>
                  ) : (
                    'No Caller Ready Lead Form Availible'
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  {lead.callerReadyRefundLink ? (
                    <a
                      href={lead.callerReadyRefundLink}
                      rel="noopener noreferrer"
                      // style={{ color: 'blue' }}
                      target="_blank"
                    >
                      Refund Request
                    </a>
                  ) : (
                    'No Refunds Availible'
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </TabPane>
  );
};

export default LTDetailsTabPane;
