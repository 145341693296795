import React, { Suspense } from 'react';

const VoyagerComponent = React.lazy(() => import('./Voyager'));

const VoyagerPage: React.SFC = () => (
  <Suspense fallback="loading...">
    <VoyagerComponent />
  </Suspense>
);

export default VoyagerPage;
