import gql from 'graphql-tag';
import { ConnectionVsoftFragment } from '../fragments';

export default gql`
  mutation updateVsoftConnection($criteria: String!) {
    updateVsoftConnection(criteria: $criteria) {
      ...ConnectionVsoftFragment
    }
  }
  ${ConnectionVsoftFragment}
`;
