import React, { useState, useMemo, useContext, useEffect } from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  Media,
  NavLink,
  NavbarProps,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge,
} from 'reactstrap';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { FaBars } from 'react-icons/fa';
import { NotificationIcon } from '@/components';
import { NotificationContext } from '@/context';
import { useUser } from '@/hooks';
import { getImageUrl } from '@/utils/get-host';

const isLeadtopia = window.location.href.includes('leadtopia');
const isAmericafirst = window.location.href.includes('americafirstleads');

const getWidth = (corpID?: string): number => {
  if (corpID === 'sonic') {
    return 57;
  }

  if (corpID === 'americafirst') {
    return 57;
  }

  return corpID === 'leadtopia' ? 160 : 50;
};

const getCallerReadyMessage = (user: any): any => {
  const { agentCallerReadyID, callerReadyAllowedTiers } = user;
  if (agentCallerReadyID) {
    const {
      services: {
        callerready: { minimumAccountBalance, minimumAccountBalances = [] },
      },
    } = user?.network;
    const minBalance = minimumAccountBalances.reduce((min: number, t: any) => {
      const index = callerReadyAllowedTiers.findIndex((tierID: string) => {
        return tierID === t.tierID;
      });
      if (index < 0) {
        return min;
      }
      return min > t.price ? min : t.price;
    }, minimumAccountBalance);
    if (user?.totalCredit < minBalance) {
      return (
        <Badge
          color="danger"
          title="CallerReady Balance Warning"
          style={{ marginLeft: 74 }}
        >
          WARNING! BALANCE BELOW THE CALLER READY THRESHOLD OF $
          {minBalance / 100}
        </Badge>
      );
    }
  }
  return null;
};

const NavigationBar: React.FunctionComponent<NavbarProps> = (props) => {
  const apolloClient = useApolloClient();
  const { user } = useUser();
  const [opened, setOpened] = useState(false);
  const toggle = (): void => setOpened((o: boolean): boolean => !o);
  const history = useHistory();
  const location = useLocation();

  const { tabNotification, setTabNotification } = useContext(
    NotificationContext
  );

  useEffect(() => {
    setTabNotification({
      ...tabNotification,
      report: !(location.pathname === '/reports') && tabNotification.report,
    });
  }, [tabNotification.report]);

  const banner = useMemo(() => {
    const domain = window.location.href;
    if (domain.includes('beta')) {
      return (
        <Badge
          color="warning"
          title="This is the marketplace beta version"
          style={{ marginLeft: 74 }}
        >
          Beta
        </Badge>
      );
    }
    if (
      domain.includes('test') ||
      domain.includes('dev') ||
      domain.includes('localhost')
    ) {
      return (
        <Badge
          color="warning"
          title="This is the marketplace test version."
          style={{ marginLeft: user?.corpID === 'leadtopia' ? 176 : 74 }}
        >
          Test
        </Badge>
      );
    }

    if (window.location.href.includes('ushamarketplace')) {
      return (
        <Badge color="info" title="USHA Marketplace" style={{ marginLeft: 74 }}>
          USHA
        </Badge>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <Navbar expand="md" color="dark" {...props}>
      <NavbarBrand
        onClick={() => history.push('/')}
        className="d-flex align-items-center cursor-pointer"
        title="Home"
      >
        <span className="d-flex align-items-center">
          <Media
            src={getImageUrl('square.png')}
            className="position-absolute"
            style={{ width: getWidth(user?.corpID) }}
          />
          {banner}
          {user ? getCallerReadyMessage(user) : null}
        </span>
      </NavbarBrand>
      <NavbarToggler onClick={toggle}>
        <FaBars className="text-white" />
      </NavbarToggler>
      <Collapse isOpen={opened} navbar>
        <Nav className="ml-auto" navbar>
          {!isLeadtopia && !isAmericafirst ? (
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                className={classnames('text-white', {
                  'font-weight-bold': location.pathname === '/',
                })}
              >
                Home
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem active>
            <NavLink
              disabled={!user?.agentApproved}
              tag={Link}
              to={isLeadtopia || isAmericafirst ? '/' : '/leads'}
              className={classnames('text-white', {
                'font-weight-bold':
                  location.pathname ===
                  (isLeadtopia || isAmericafirst ? '/' : '/leads'),
              })}
            >
              Leads
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={!user?.agentApproved}
              tag={Link}
              to="/campaigns"
              className={classnames('text-white', {
                'font-weight-bold': location.pathname === '/campaigns',
              })}
            >
              <div className="d-flex align-items-center">Campaigns</div>
            </NavLink>
          </NavItem>
          {user?.flags.allowBulkSales && (
            <NavItem>
              <NavLink
                disabled={!user?.agentApproved}
                tag={Link}
                to="/bulk-purchase"
                className={classnames('text-white', {
                  'font-weight-bold': location.pathname === '/bulk-purchase',
                })}
              >
                <div className="d-flex align-items-center">Bulk</div>
              </NavLink>
            </NavItem>
          )}
          {user?.network.agentDashboards.length > 0 && (
            <NavItem>
              <NavLink
                disabled={!user?.agentApproved}
                tag={Link}
                to="/analytics"
                className={classnames('text-white', {
                  'font-weight-bold': location.pathname === '/analytics',
                })}
              >
                <div className="d-flex align-items-center">Analytics</div>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              disabled={!user?.agentApproved}
              onClick={() =>
                setTabNotification({ ...tabNotification, report: false })
              }
              tag={Link}
              to="/reports"
              className={classnames('text-white', {
                'font-weight-bold': location.pathname === '/reports',
              })}
            >
              <div className="d-flex align-items-center">
                {' '}
                Reports{' '}
                {tabNotification.report && location.pathname !== '/reports' && (
                  <NotificationIcon color="danger" />
                )}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              to="/support"
              className={classnames('text-white', {
                'font-weight-bold': location.pathname === '/support',
              })}
            >
              Help
            </NavLink>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle nav caret className="text-white">
              Settings
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                tag={Link}
                to="/profile/me"
                active={location.pathname === '/profile/me'}
              >
                Profile
              </DropdownItem>
              {!isLeadtopia && (
                <DropdownItem
                  disabled={!user?.agentApproved}
                  tag={Link}
                  to="/agent-transfers"
                  active={location.pathname === '/agent-transfers'}
                >
                  Transfers
                </DropdownItem>
              )}
              {user?.flags.allowMyAgents && (
                <DropdownItem
                  disabled={!user?.agentApproved}
                  tag={Link}
                  to="/myagents"
                  active={location.pathname === '/myagents'}
                >
                  My Agents
                </DropdownItem>
              )}
              {user?.flags?.allowVendors && (
                <DropdownItem
                  disabled={!user?.agentApproved}
                  tag={Link}
                  to="/vendors"
                  active={location.pathname === '/vendors'}
                >
                  Vendors
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() => {
                  Auth.signOut({ global: true });
                  apolloClient.resetStore();
                  localStorage.clear();
                }}
              >
                Sign Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavigationBar;
