const VendorPartial = `
    id
    vendorID
    vendorName
    vendorPriceIncluded
    flags {
      allowAgentDirect
      allowDuplicateRejects
      allowDisconnectedRejects
      sendsPricesWithLeads
    }
    __typename
`;

export default VendorPartial;
