/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Table, Button, Row, Col, Badge } from 'reactstrap';
import { Field, Form } from '@availity/form';
import { FieldArray } from 'formik';
import * as yup from 'yup';
import omit from 'lodash.omit';
import classNames from 'classnames';
import { FaHashtag, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { SelectField } from '@availity/select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getTextdripCampaigns } from '@/graphql/queries';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import { useHistory } from 'react-router-dom';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';

interface CRMProfileProps {
  email: string;
  apiToken: string;
  profiles: any[];
  fields: any[];
}

const TextdripProfile: React.FC<CRMProfileProps> = ({
  profiles = [],
  email = '',
  apiToken = '',
  fields = [],
}) => {
  const authUrl = `https://app.textdrip.com/tauth?redirect=${window.location.origin}/auth`;

  const { user } = useUser();
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();

  const { data } = useQuery(getTextdripCampaigns, { variables: { apiToken } });

  const [updateProfile] = useMutation(updateUserProfile, {
    onCompleted: () => {
      createNotification({
        message: 'Successfully updated Profile.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
      history.push('/profile/me');
    },
    // NOT UPDATING PROFILES
    update(cache, { data: { accountUpdate } }) {
      cache.writeFragment({
        id: accountUpdate.id,
        fragment: AgentFragment,
        data: accountUpdate,
      });
    },
    onError: (error) => {
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });

      complete();
    },
  });
  const textDripCampaigns = data?.getTextdripCampaigns || [];

  return (
    <Form
      initialValues={{ profiles, email, apiToken }}
      onSubmit={(values) => {
        updateProfile({
          variables: {
            input: {
              agentID: user.agentID,
              crmProfiles: {
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  label: 'VanillaSoft',
                  profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  label: 'iSalesCRM',
                  profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                textdrip: {
                  ...user!.crmProfiles!.textdrip,
                  profiles: values.profiles.map((p) => {
                    if (!p.id) {
                      return {
                        ...omit(p, ['__typename', 'profileID']),
                        ...p,
                        id: `textdrip-${uuid()}`,
                      };
                    }
                    return p;
                  }),
                },
                onlysales: {
                  ...user!.crmProfiles!.onlysales,
                  label: 'OnlySales',
                  profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      integrationLink: p?.crmFields.integrationLink.trim(),
                      authToken: p?.crmFields.authToken.trim(),
                    },
                  })),
                  // profiles: values.profilesOnlySales.map((p) => ({
                  //   ...omit(p, ['__typename', 'profileID']),
                  //   id: p.profileID || p.id,
                  // })),
                },
              },
            },
          },
        });
      }}
    >
      {({ values, submitCount }) => {
        return (
          <>
            {!email || !apiToken ? (
              <Row>
                <Badge color="warning" className="mx-auto">
                  Please select both email and api token to authorize Textdrip
                </Badge>
              </Row>
            ) : (
              <Row className="mb-2">
                <Col>
                  <Field name="email" label="Textdrip Account" disabled />
                </Col>
              </Row>
            )}
            <Table
              className={classNames('border-bottom border-secondary', {
                'd-none': !email || !apiToken,
              })}
            >
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.profiles?.map((profile, profileIdx) => (
                          <tr key={profile.id}>
                            <td className="d-flex flex-column">
                              <Row>
                                <Col>
                                  <span>
                                    <FaHashtag className="mb-2" />
                                    <h4 className="d-inline">
                                      {profileIdx + 1}
                                    </h4>
                                  </span>

                                  <div>
                                    <Button
                                      color="danger"
                                      title="Delete Profile"
                                      id={`delete-profile-${profileIdx}`}
                                      onClick={() =>
                                        arrayHelpers.remove(profileIdx)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  {!profile.id && !submitCount ? (
                                    <SelectField
                                      name={`profiles.${profileIdx}.crmFields`}
                                      label="Textdrip Campaigns"
                                      options={textDripCampaigns}
                                      labelKey="title"
                                      valueKey="tdCampaignID"
                                      placeholder="Select a campaign..."
                                      raw
                                    />
                                  ) : (
                                    <Field
                                      label="Campaign Title"
                                      name={`profiles.${profileIdx}.crmFields.title`}
                                      disabled
                                    />
                                  )}
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2}>
                            <Button
                              color="success"
                              className="w-100"
                              onClick={() =>
                                arrayHelpers.push({
                                  crmFields: fields.reduce(
                                    (acc: any, field: any) => ({
                                      ...acc,
                                      [field.name]: '',
                                    }),
                                    {}
                                  ),
                                })
                              }
                            >
                              Add New Profile
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  }}
                />
              </tbody>
            </Table>
            {!email || !apiToken ? (
              <Button
                color="success"
                className="w-100"
                onClick={() => window.location.replace(authUrl)}
              >
                Textdrip Authorization
              </Button>
            ) : (
              <>
                <Button color="secondary" type="reset">
                  Reset Changes
                </Button>
                <Button color="primary" className="float-right" type="submit">
                  Save Changes
                </Button>
              </>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default TextdripProfile;
