import gql from 'graphql-tag';

export default gql`
  query destinationAttributeByIdQuery($id: ID!) {
    destinationAttributeById(id: $id) {
      id
      __typename
      attributesID
      attributes {
        field
        mapTo
      }
    }
  }
`;
