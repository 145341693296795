import React, { useContext, useState } from 'react';
import {
  Card,
  Button,
  CardTitle,
  ButtonGroup,
  CardText,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  FaEdit,
  FaFlask,
  FaTrash,
  FaUser,
  FaUserFriends,
  FaLink,
  FaSms,
  FaEnvelope,
} from 'react-icons/fa';
import { useMutation } from '@apollo/react-hooks';
import { testDestinationMutation } from '@/graphql/mutations';
import { NotificationContext } from '@/context';
import { useUser } from '@/hooks';

const getColor = (type: string): string => {
  switch (type) {
    case 'agent':
      return 'primary';
    case 'team':
      return 'info';
    case 'api':
    case 'url':
      return 'success';
    case 'marketplace':
      return 'success';
    case 'sms':
      return 'warning';
    case 'email':
      return 'warning';
    default:
      return 'danger';
  }
};

const makeIcon = (name: string): React.ReactNode => {
  switch (name) {
    case 'agent':
      return <FaUser size={24} />;
    case 'team':
      return <FaUserFriends size={24} />;
    case 'api':
    case 'url':
      return <FaLink size={24} />;
    case 'sms':
      return <FaSms size={24} />;
    case 'email':
      return <FaEnvelope size={24} />;
    default:
      return null;
  }
};

const getDescription = (
  type: string,
  corpID: string,
  destination: string
): string | React.ReactNode => {
  switch (type) {
    case 'agent':
      return 'Delivered based on user setup';
    case 'team':
      return 'Delivered based on user setup';
    case 'api':
    case 'url':
      return (
        <div>
          <span className="mr-4">
            Delivered to an outside webhook URL or API
          </span>
          <span className="font-weight-bold">URL: </span>
          {destination}
        </div>
      );
    case 'sms':
      return 'Text messaging rates may apply see terms and conditions';
    case 'email':
      if (corpID === 'leadtopia') {
        return 'bob@leadtopia.com';
      }
      if (corpID === 'americafirst') {
        return 'bob@americafirstleads.com';
      }
      return (
        <div>
          <span className="font-weight-bold">Sent From: </span>
          <span className="mr-4">
            {
              corpID === 'usha'
                ? 'leads@ushamarketplace.com'
                : 'Sent from bob@sonicleads.com' // will need to make based on whitelabel
            }
          </span>
          <span className="font-weight-bold">Email Destination: </span>
          {destination}
        </div>
      );
    case 'marketplace':
      return (
        <div>
          <span className="mr-4">Delivered to internal leads source</span>
        </div>
      );
    default:
      return 'This is an unknown destination type';
  }
};

const DestinationRow: React.FC<{
  remove: () => void;
  edit: () => void;
  destID: string;
  destinationType: string;
  label: string;
  destination: string;
  destinationName: string;
}> = ({
  destID,
  destinationType,
  label,
  destination,
  destinationName,
  remove,
  edit,
}) => {
  const { user } = useUser();
  const { create } = useContext(NotificationContext);
  const [testDestination] = useMutation(testDestinationMutation, {
    update() {
      create({
        message: 'Successfully sent test',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
        timeout: 1000,
      });
    },
  });
  return (
    <Card
      body
      inverse
      key={destID}
      color={getColor(destinationType)}
      className="mb-2"
    >
      <CardTitle>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {makeIcon(destinationType)}
            <h3 className="ml-2 mb-0">{label}</h3>
          </div>
          <div>
            <ButtonGroup>
              <Button
                id={`destDeleteButton-${destID.replaceAll(':', '_')}`}
                color="danger"
                onClick={() => remove()}
              >
                <FaTrash />
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`destDeleteButton-${destID.replaceAll(':', '_')}`}
              >
                Delete
              </UncontrolledTooltip>
              {destinationType === 'user' ||
              destinationType === 'agent' ||
              destinationType === 'team' ||
              destinationType === 'url' ||
              destinationType === 'URL' ||
              destinationType === 'marketplace' ||
              destinationType === 'api' ? null : (
                <div>
                  <Button
                    id={`destTestButton-${destID.replaceAll(':', '_')}`}
                    color="info"
                    onClick={() => {
                      testDestination({
                        variables: {
                          input: {
                            destAddress: destID,
                          },
                        },
                      });
                    }}
                  >
                    <FaFlask />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`destTestButton-${destID.replaceAll(':', '_')}`}
                  >
                    Test Destination
                  </UncontrolledTooltip>
                  {/* <Button
                    id={`destEditButton-${destID}`}
                    color="secondary"
                    onClick={() => edit()}
                  >
                    <FaEdit />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`destEditButton-${destID}`}
                  >
                    Edit
                  </UncontrolledTooltip> */}
                </div>
              )}
            </ButtonGroup>
          </div>
        </div>
      </CardTitle>
      <CardText>
        {getDescription(destinationType, user?.corpID, destination)}
      </CardText>
    </Card>
  );
};

export default DestinationRow;
