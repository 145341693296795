/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useEffect } from 'react';
import { currencyFormater } from '@/utils';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  InputGroup,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';
import {
  FaSort,
  FaSortDown,
  FaSortUp,
  FaSync,
  FaCheckCircle,
  FaCalendarMinus,
} from 'react-icons/fa';

import { Formik, useFormikContext } from 'formik';
import { DateRangeField } from '@availity/date';
import { SelectField } from '@availity/select';
import { useApolloClient } from '@apollo/react-hooks';
import { agentTransferPagination, agentPagination } from '@/graphql/queries';
import { SearchControls, FilterSelect, SearchSummary } from '@/components';
import { TableOptionsModal, TransfersTransferMoneyModal } from '@/modals';
import { useUser } from '@/hooks';

// TODO - Abstract
const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

const transferTypeOptions = [
  {
    label: 'Outgoing',
    value: 'OUTGOING',
  },
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Incoming',
    value: 'INCOMING',
  },
];

const availableColumns = [
  {
    dataField: 'activityDate',
    displayName: 'Date',
    formatter: (value: string): string =>
      moment(value).format('MM/DD/YY hh:mm A'),
  },
  {
    dataField: 'otherAgentFirstName',
    displayName: 'First Name',
  },
  {
    dataField: 'otherAgentLastName',
    displayName: 'Last Name',
  },
  {
    dataField: 'otherAgentEmail',
    displayName: 'Email',
  },
  {
    dataField: 'diffAmount',
    displayName: 'Transfer Amount',
    formatter: (value: number): string => currencyFormater.format(value),
  },
];

// TODO - abstract
interface ColumnConfig {
  dataField: string;
  displayName: string;
  formatter?: (value: any) => any;
}

interface TableRowProps {
  [key: string]: any;
  columns: ColumnConfig[];
  sortKey: string;
  descending: boolean;
}

const TableRow: React.SFC<TableRowProps> = ({
  columns,
  sortKey,
  descending,
  ...rest
}) => (
  <tr>
    {columns.map((column: any, key: any): any => {
      const { dataField, formatter } = column;
      return (
        <td key={`${key}-${dataField}`}>
          {formatter !== undefined
            ? formatter(rest[dataField])
            : rest[dataField]}
        </td>
      );
    })}
  </tr>
);

const initialColumns = localStorage
  .getItem('transfers-table-columns')
  ?.split(',');

const TransferTable: React.SFC = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { user } = useUser();
  const client = useApolloClient();
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const [transferModalOpen, setTransferModal] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const toggleTransferModal = () => setTransferModal((o) => !o);

  useEffect(() => {
    localStorage.setItem('transfers-table-columns', values.columns.toString());
  }, [values.columns]);

  const displayColumns = useMemo(
    () =>
      availableColumns.filter((aC) =>
        values.columns.find((c) => c === aC.dataField)
      ),
    [values.columns]
  );

  const fetchItems = async (
    currentPage: number,
    itemsPerPage: number
  ): Promise<any> => {
    const response = await client.query<any>({
      query: agentTransferPagination,
      fetchPolicy: 'network-only',
      variables: {
        perPage: itemsPerPage,
        page: currentPage,
        filter: {
          q: values.searchText,
          otherAgentID: values.leftFilter?.agentID || undefined,
          transferType: values.transferType,
          activityDate: {
            gte: values.dateRange.startDate,
            lte: values.dateRange.endDate,
          },
        },
        orderBy: values.sort,
      },
    });

    const currentPageData = response.data?.transferPagination.items;

    return {
      totalCount: response.data.transferPagination.count,
      items: currentPageData,
    };
  };

  const getSortIcon = (dataField: string): React.ReactNode => {
    const { [dataField]: sort } = values.sort;

    if (sort === 'DESC') {
      return <FaSortDown />;
    }

    if (sort === 'ASC') {
      return <FaSortUp />;
    }

    return <FaSort />;
  };

  const updateSort = (dataField: string): void => {
    const { [dataField]: sort, ...restSort } = values.sort;

    if (!sort) {
      setFieldValue('sort', {
        ...values.sort,
        [dataField]: 'DESC',
      });
    } else if (sort === 'DESC') {
      setFieldValue('sort', {
        ...values.sort,
        [dataField]: 'ASC',
      });
    } else {
      setFieldValue('sort', restSort);
    }
  };

  return (
    <>
      <Pagination
        items={fetchItems}
        itemsPerPage={values.size}
        watchList={[values.sort]}
        resetParams={[
          values.size,
          values.leftFilter,
          values.transferType,
          values.searchText,
          values.dateRange,
          resetCount,
        ]}
      >
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <CardTitle
              tag="h3"
              className="d-flex justify-content-between align-items-center mb-0"
            >
              My Transfers
            </CardTitle>
            {user.flags.allowCrossNetworkTransfers && (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="primary" caret>
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleTransferModal}>
                    Transfer Money
                  </DropdownItem>
                  {/* <DropdownItem>Request Money</DropdownItem> */}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </CardHeader>
          <CardBody>
            <SearchControls
              advancedSearch={
                <Row style={{ maxWidth: 530 }}>
                  <Col xs={6}>
                    <FilterSelect
                      label="Agent"
                      queryName="agentPagination"
                      placeholder="Filter By Agent"
                      className="left-filter-select"
                      name="leftFilter"
                      variables={{
                        filter: {
                          agentID: user!.agentID,
                          q: '',
                        },
                        orderBy: {
                          firstName: 'ASC',
                        },
                      }}
                      getOptionLabel={(agent) => {
                        return `${agent.firstName} ${agent.lastName}`;
                      }}
                      raw
                      query={agentPagination}
                    />
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex align-items-center">
                      <InputGroup className="d-flex justify-content-end w-auto">
                        <SelectField
                          label="Transfer Type"
                          className="left-filter-select"
                          options={transferTypeOptions}
                          labelKey="label"
                          valueKey="value"
                          name="transferType"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <DateRangeField
                      className="leads-date-range"
                      name="dateRange"
                      id="dateRange"
                      label="Date Range"
                      customArrowIcon="-"
                      calendarIcon={
                        <div>
                          <FaCalendarMinus />
                        </div>
                      }
                      datepickerProps={{
                        renderMonthElement: undefined,
                      }}
                      ranges
                    />
                  </Col>
                </Row>
              }
              openTableOptionsModal={() => setTableOptionsModal((o) => !o)}
            />
            <SearchSummary className="d-flex justify-content-between py-3" />
            <Table striped size="sm" hover>
              <thead>
                <tr>
                  {displayColumns.map((column: any) => (
                    <th key={column.displayName}>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        role="button"
                        tabIndex={0}
                        onClick={() => updateSort(column.dataField)}
                        onKeyDown={(e: React.KeyboardEvent) =>
                          e.charCode === 13 && updateSort(column.dataField)
                        }
                      >
                        {column.displayName} {getSortIcon(column.dataField)}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <PaginationContent
                itemKey="id"
                component={TableRow}
                containerTag="tbody"
                columns={displayColumns}
              />
            </Table>
            <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
              There are no items left to view
            </NoItemsMessage>
          </CardBody>
        </Card>
      </Pagination>
      <TableOptionsModal
        availableColumns={availableColumns}
        isOpen={showTableOptionsModal}
        toggle={() => setTableOptionsModal((o) => !o)}
      />
      <TransfersTransferMoneyModal
        isOpen={transferModalOpen}
        toggle={toggleTransferModal}
        onCompleted={() => {
          setResetCount((i) => i + 1);
          toggleTransferModal();
        }}
      />
    </>
  );
};

const AgentTransfers: React.SFC = ({ children }) => (
  <Formik
    initialValues={{
      size: 25,
      searchText: '',
      sort: {
        activityDate: 'DESC',
      },
      columns: availableColumns
        .filter(
          (aC) =>
            !initialColumns || initialColumns.find((c) => c === aC.dataField)
        )
        .map((aC) => aC.dataField),
      transferType: 'ALL',
      dateRange: {
        startDate: moment({ hour: 0 }).subtract(29, 'days'),
        endDate: moment({ hour: 0 }),
      },
      leftFilter: undefined,
    }}
    validationSchema={yup.object().shape({
      size: yup.number(),
      searchText: yup.string(),
      dateRange: dateRange({}),
      sort: yup.object(),
      leftFilter: yup.string().nullable(), // agentID
      transferType: yup.string(),
    })}
    onSubmit={() => {}}
  >
    <TransferTable />
  </Formik>
);

export default AgentTransfers;
