/* eslint-disable no-empty */
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import {
  Card,
  CardBody,
  ButtonGroup,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Pagination, { PaginationContent } from '@availity/pagination';
import { Form } from '@availity/form';
import { Formik } from 'formik';
import {
  FaSortAmountDown,
  FaSortAmountUp,
  FaSync,
  FaCheckCircle,
} from 'react-icons/fa';
import AvSelect from '@availity/select';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { usePageLoader, useUser, useNotifications } from '@/hooks';
import { agentPagination, currentAgentQuery } from '@/graphql/queries';
import { creditTransfer as transferMutation } from '@/graphql/mutations';

import { MyAgentConfirmModal } from '@/modals';
import { PaginationControls, DebouncedSearch } from '@/components';
import { Vars } from '@/utils';
import formatCurrencyForDisplay from './Utils';
import AgentTable from './Table';
import AgentRow from './Row';

const sortOptions = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
];

const MyAgents: React.SFC = (props) => {
  const [updateCount, setUpdateCount] = useState(0);
  const [agents, setAgents] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    page: 1,
    searchTerm: '',
    statusFilter: null,
    transferStatus: null,
    sort: 'firstName',
    order: true,
    showSpinner: false,
    time: new Date().toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }),
  });
  const { complete, start } = usePageLoader();

  const { create: createNotification } = useNotifications();

  const [isOpen, setIsOpen] = useState(false);

  const [transferStore, setTransferStore] = useState<any>({});

  const setAmount = (nextAmount: number, id: string): any => {
    if (nextAmount !== transferStore[id]) {
      setTransferStore({
        ...transferStore,
        [id]: nextAmount,
      });
    }
  };

  const reset = () => setTransferStore({});

  const { query, reFetchObservableQueries } = useApolloClient();
  const { user: currentAgent = {} as any, loading: loadingUser } = useUser();

  const totalTransferAmount = (): number => {
    return Object.entries(transferStore).reduce((sum, [_, value]) => {
      sum += parseInt(value as string, 10);
      return sum;
    }, 0);
  };

  const [creditTransfer, { loading: syncing }] = useMutation(transferMutation, {
    update(cache) {
      const { userCurrent, account } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          account,
          userCurrent: {
            ...userCurrent,
            totalCredit: currentAgent.totalCredit - totalTransferAmount(),
          },
        },
      });
    },
    onError: () =>
      createNotification({
        color: 'danger',
        message: 'Failed to transfer funds.',
        type: 'alert',
        action: 'MESSAGE',
      }),
    onCompleted: ({ creditTransfer }) => {
      const { transfers } = creditTransfer || {};
      if (transfers.some((t) => t.errors && t.errors.length > 0)) {
        createNotification({
          message: 'Some transfers failed.',
          type: 'alert',
          action: 'MESSAGE',
          color: 'warning',
        });
      } else {
        createNotification({
          message: 'Successfully transfered funds.',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
      }
      setTransferStore({});
      setUpdateCount((i) => i + 1);
    },
  });

  useEffect(() => {
    if (!loadingUser) {
      complete();
    } else {
      start();
    }
  }, [complete, loadingUser, start]);

  const save = () => {
    creditTransfer({
      variables: {
        input: {
          transfers: Object.keys(transferStore).map((id) => ({
            id,
            value: transferStore[id],
          })),
        },
      },
    });
  };
  return (
    <span>
      <Formik initialValues={{ ...initialValues }}>
        {({ values, setFieldValue }) => {
          setInitialValues(values);
          const spinnerColor = () => {
            switch (values.statusFilter) {
              case null:
                return 'primary';
              case false:
                return 'warning';
              case true:
                return 'success';
              default:
                return 'primary';
            }
          };

          let saveText = '';
          const refreshText =
            values.showSpinner || syncing ? (
              <div style={{ marginLeft: '90px' }}>
                <FaSync
                  className={`ml-1 text-${spinnerColor()} loading-sync`}
                  title="Searching Transfers..."
                  size={20}
                />
              </div>
            ) : (
              `Last refreshed ${values.time}`
            );

          const refreshButton = () => {
            return (
              <div style={{ width: '250px' }}>
                <span style={{ width: '180px', display: 'inline-block' }}>
                  {refreshText}
                </span>

                <span style={{ float: 'right' }}>
                  <Button
                    size="md"
                    onClick={async () => {
                      setFieldValue('showSpinner', true);
                      await reFetchObservableQueries();
                      reset();
                      setFieldValue('showSpinner', false);
                      setFieldValue(
                        'time',
                        new Date().toLocaleString('en-US', {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })
                      );
                    }}
                  >
                    <FaSync />
                  </Button>
                </span>
              </div>
            );
          };

          const fetchAgents = async (
            currentPage: number,
            itemsPerPage: number
          ): Promise<any> => {
            const response = await query({
              query: agentPagination,
              fetchPolicy: 'network-only',
              variables: {
                filter: {
                  q: values.searchTerm,
                  agentID: currentAgent.agentID || '',
                  agentIDs:
                    values.transferStatus === 'pending'
                      ? Object.keys(transferStore)
                      : undefined,
                  approved:
                    values.statusFilter === null
                      ? undefined
                      : values.statusFilter,
                },
                orderBy: {
                  [values.sort]: values.order ? 'ASC' : 'DESC',
                },
                page: Math.max(currentPage, values.page),
                perPage: itemsPerPage,
              },
            });

            const agents = response.data?.agentPagination.items;

            setAgents(agents);

            return {
              totalCount: response.data.agentPagination.count,
              items: agents,
            };
          };

          const agentsWithFinalNegativeBalances = agents.filter(
            (agent: any) => {
              const { id, totalCredit } = agent;
              return (transferStore[id] || 0) + totalCredit < 0;
            }
          );

          const finalUserCreditIsNegative =
            totalTransferAmount() > agents[0]?.credit;

          const noChange = totalTransferAmount() === 0;

          const disableReset = noChange;

          const disableSave =
            disableReset ||
            agentsWithFinalNegativeBalances.length > 0 ||
            finalUserCreditIsNegative;

          if (finalUserCreditIsNegative) {
            saveText = 'Cannot save until your remaining credit is positive.';
          } else if (!disableReset) {
            saveText = 'Press Transfer to Complete ->';
          } else {
            saveText = '';
          }
          return (
            <div className="d-flex">
              <div
                className={classNames('flex-fill d-flex flex-column', {
                  'border-primary': values.statusFilter === null,
                  'border-warning': values.statusFilter === false,
                  'border-success': values.statusFilter === true,
                })}
                // style={{ marginRight: 270 }}
              >
                <Card className="mb-3 ml-0 py-0">
                  <Pagination
                    watchList={[
                      values.showSpinner,
                      values.searchTerm,
                      values.sort,
                      values.order,
                      values.statusFilter,
                      updateCount,
                    ]}
                    resetParams={[values.transferStatus]}
                    items={fetchAgents}
                    itemsPerPage={Vars.agentCount}
                    page={values.page}
                    onPageChange={(page: number) => setFieldValue('page', page)}
                  >
                    <div
                      className={classNames(
                        'd-flex justify-content-between py-3',
                        {
                          'bg-primary border-primary':
                            values.statusFilter === null,
                          'bg-warning border-warning':
                            values.statusFilter === false,
                          'bg-success border-success':
                            values.statusFilter === true,
                        }
                      )}
                      style={{
                        borderLeft: '4px solid #f8f8f8',
                      }}
                    >
                      <h3 className="mb-0 pl-2">My Agents</h3>
                      <ButtonGroup className="w-50">
                        <Button
                          onClick={() => setFieldValue('statusFilter', null)}
                          active={values.statusFilter === null}
                        >
                          All
                        </Button>
                        <Button
                          onClick={() => setFieldValue('statusFilter', false)}
                          active={values.statusFilter === false}
                        >
                          Pending
                        </Button>
                        <Button
                          onClick={() => setFieldValue('statusFilter', true)}
                          active={values.statusFilter === true}
                        >
                          Approved
                        </Button>
                      </ButtonGroup>
                      <div />
                    </div>
                    <div
                      className={classNames({
                        'border-primary': values.statusFilter === null,
                        'border-warning': values.statusFilter === false,
                        'border-success': values.statusFilter === true,
                      })}
                      onSubmit={() => {}}
                      initialValues={{}}
                      style={{
                        borderLeft: '4px solid #f8f8f8',
                      }}
                    >
                      <div className="bg-amplify-white py-3 px-4 d-flex align-items-center justify-content-between">
                        <div className="d-flex alignt-items-center">
                          <span className="mr-2 font-weight-bold text-white">
                            Credit Transfer Status
                          </span>
                          <ButtonGroup size="sm">
                            <Button
                              onClick={() =>
                                setFieldValue('transferStatus', null)
                              }
                              active={values.transferStatus === null}
                              color={spinnerColor()}
                              outline
                            >
                              All
                            </Button>
                            <Button
                              onClick={() =>
                                setFieldValue('transferStatus', 'pending')
                              }
                              active={values.transferStatus === 'pending'}
                              color={spinnerColor()}
                              outline
                            >
                              Pending
                            </Button>
                          </ButtonGroup>
                        </div>{' '}
                        <InputGroup className="d-flex justify-content-end w-auto">
                          <AvSelect
                            styles={{
                              menuList: (provided) => ({
                                ...provided,
                                color: '#232323',
                              }),
                            }}
                            name="sort"
                            id="sort-select"
                            options={sortOptions}
                            onChange={(option: any) =>
                              setFieldValue('sort', option)
                            }
                            value={values.sort}
                            inputProps={{
                              width: 200,
                            }}
                          />
                          <InputGroupAddon
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setFieldValue('order', !values.order)
                            }
                            addonType="append"
                          >
                            <InputGroupText>
                              {values.order ? (
                                <FaSortAmountDown />
                              ) : (
                                <FaSortAmountUp />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {refreshButton()}
                      </div>

                      <CardBody className="d-flex py-2">
                        <DebouncedSearch
                          type="text"
                          name="searchTerm"
                          placeholder="Search..."
                          className="mr-2"
                        />
                        <PaginationControls
                          className="d-flex justify-content-center"
                          listClassName="mb-0"
                          pageRange={3}
                          marginPages={0}
                          directionLinks
                          endLinks
                        />
                      </CardBody>
                      <Card
                        body
                        className="d-flex flex-row justify-content-between"
                        // style={{
                        //   width: 250,
                        //   position: 'fixed',
                        //   right: 24,
                        //   height: 'fit-content',
                        // }}
                      >
                        <div>
                          <span className="d-flex align-items-center">
                            Your Credit{' '}
                            {syncing || loadingUser ? (
                              <FaSync
                                color={spinnerColor()}
                                className="ml-1 text-warning loading-sync"
                                title="Syncing your credit."
                              />
                            ) : (
                              <FaCheckCircle className="ml-1 text-success" />
                            )}
                          </span>

                          <h5>
                            {loadingUser
                              ? `--.--`
                              : formatCurrencyForDisplay(
                                  currentAgent.totalCredit
                                )}
                          </h5>
                        </div>
                        <div>
                          Credit to Transfer
                          <br />
                          <h5>
                            {formatCurrencyForDisplay(-totalTransferAmount())}
                          </h5>
                        </div>
                        <div>
                          Remaining Credit
                          <h5
                            className={
                              currentAgent.totalCredit - totalTransferAmount() <
                              0
                                ? 'text-danger'
                                : ''
                            }
                          >
                            {formatCurrencyForDisplay(
                              (currentAgent.totalCredit || 0) -
                                totalTransferAmount()
                            )}
                          </h5>
                        </div>
                        <div>
                          <h5 className="text-danger">{saveText}</h5>
                        </div>
                        <div>
                          <ButtonGroup>
                            <Button disabled={disableReset} onClick={reset}>
                              Reset
                            </Button>
                            <Button
                              color="primary"
                              disabled={disableSave}
                              onClick={() => setIsOpen(true)}
                            >
                              Transfer
                            </Button>
                          </ButtonGroup>
                        </div>
                      </Card>
                    </div>
                    <AgentTable
                      className={classNames({
                        'border-primary': values.statusFilter === null,
                        'border-warning': values.statusFilter === false,
                        'border-success': values.statusFilter === true,
                      })}
                      style={{
                        borderLeft: '4px solid #f8f8f8',
                      }}
                    >
                      <PaginationContent
                        containerTag="tbody"
                        component={AgentRow}
                        itemKey="agentID"
                        color={spinnerColor()}
                        transferStore={transferStore}
                        setAmount={setAmount}
                      />
                    </AgentTable>
                  </Pagination>
                </Card>
              </div>
            </div>
          );
        }}
      </Formik>
      <MyAgentConfirmModal
        closeModal={() => setIsOpen(false)}
        confirm={save}
        isOpen={isOpen}
        text="Are you sure you wish to transfer all indicated funds and save all schedule and auto-pay settings?"
      />
    </span>
  );
};

export default MyAgents;
