import gql from 'graphql-tag';
import { DestinationFragment } from '@/graphql/fragments';

export default gql`
  query destinationsQuery($agentID: String) {
    destinations(agentID: $agentID) {
      ...DestinationFragment
    }
  }
  ${DestinationFragment}
`;
