import React, { useState } from 'react';
import {
  Table,
  Button,
  Row,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Destination } from '@sonic-web-dev/types';
import DestinationsForm from '@/areas/Destinations/DestinationsForm';
import { useDestinations } from '@/hooks';
import EmailListItem from './custom/EmailListItem';
import useProfile from '../../useProfile';

const EmailDestinationProfile: React.FC = () => {
  const {
    data: { agentID },
  } = useProfile();

  const { destinations } = useDestinations(agentID);

  const [pendingEdit, setPendingEdit] = useState<Destination | null>(null);

  const closeModal = (): void => {
    setPendingEdit(null);
  };

  return (
    <>
      <div className="d-block my-2">
        <Button
          color="primary"
          block
          onClick={() => setPendingEdit({} as Destination)}
        >
          Create Destination
        </Button>
      </div>
      <Table className="border-bottom border-secondary">
        <thead>
          <tr>
            <th colSpan={2} className="border-0">
              Destinations
            </th>
          </tr>
        </thead>
        <tbody>
          <Row>
            {destinations
              .filter((n) => n.destinationType === 'email')
              .map((d) => {
                return (
                  <EmailListItem
                    key={d.destID}
                    name={d.destinationName}
                    email={d.destination}
                    edit={() => setPendingEdit(d)}
                  />
                );
              })}
          </Row>
        </tbody>
      </Table>
      {pendingEdit && (
        <Modal isOpen={!!pendingEdit} toggle={closeModal}>
          <ModalHeader wrapTag={CardHeader} toggle={closeModal}>
            {pendingEdit?.destID ? (
              <div>
                <FaExclamationTriangle size={24} color="yellow" /> Editing this
                destination will impact ALL associated campaigns
              </div>
            ) : (
              <div>Create New Destination</div>
            )}
          </ModalHeader>
          <ModalBody>
            <DestinationsForm onCreate={closeModal} destination={pendingEdit} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default EmailDestinationProfile;
