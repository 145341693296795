import React from 'react';
import { ListGroupItem, Row, Col, Badge } from 'reactstrap';
import { Agent } from '@sonic-web-dev/types';
import { useHistory } from 'react-router-dom';
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';
import { useNotifications, useUser } from '@/hooks';

const badgeFontSize = '.85rem';

const getRoleColor = (role: string) => {
  switch (role) {
    case 'user':
      return 'secondary';
    case 'manager':
      return 'warning';
    case 'admin':
    case 'super_admin':
      return 'danger';
  }
};

interface AgentProps extends Agent {
  id: string;
  managers: any[];
}

const getAgentStatus = (
  agentApproved?: boolean,
  status?: 'active' | 'deleted'
) => {
  if (status === 'deleted') {
    return <FaTimesCircle className="text-danger" title="Agent Inactive" />;
  }

  return agentApproved ? (
    <FaCheckCircle className="text-success" title="Agent Active" />
  ) : (
    <FaQuestionCircle className="text-warning" title="Agent Pending Approval" />
  );
};

const AgentListItem: React.SFC<AgentProps> = ({
  firstName,
  lastName,
  email,
  id,
  role,
  agentApproved,
  status,
  agentID,
}) => {
  const { user: currentUser } = useUser();
  const { create: createNotification } = useNotifications();
  const history = useHistory();
  return (
    <ListGroupItem
      className="mb-3 d-flex align-items-center cursor-pointer agent-list-item"
      tag={Row}
      noGutters
      // onClick={() => {
      //   if (currentUser.agentIDs.includes(agentID)) {
      //     return history.push(`/profile/${id}`);
      //   }
      //   createNotification({
      //     message: 'Cannot edit agent you are not directly managing.',
      //     type: 'alert',
      //     action: 'MESSAGE',
      //     color: 'warning',
      //     timeout: 3000,
      //   });
      // }}
    >
      <Col xs={4}>
        {getAgentStatus(agentApproved, status)} {firstName} {lastName}
      </Col>
      <Col xs={4}>{email}</Col>
      <Col xs={4} className="d-flex flex-column text-center align-items-center">
        <Badge
          pill
          color={getRoleColor(role!)}
          style={{
            fontSize: badgeFontSize,
          }}
        >
          {role}
        </Badge>
        <span>Role</span>
      </Col>
    </ListGroupItem>
  );
};

export default AgentListItem;
