import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import moment from 'moment';
import { ServiceChargeModel } from '@/modals';
import { formatCurrencyForDisplay } from '@/utils';

interface ServiceFee {
  description: string;
  amount: number;
}

interface Service {
  id: string;
  fee: ServiceFee;
  minimumAccountBalance: number;
}

export interface CallerReadyProps {
  values: any;
  setFieldValue: any;
  service: Service;
  user: any;
}

const getNextcallerReadyAccountStatus = (currentStatus: string): string => {
  if (currentStatus === 'none') {
    return 'pending';
  }
  if (currentStatus === 'active') {
    return 'paused';
  }
  if (currentStatus === 'pending') {
    return 'none';
  }
  return 'active';
};

const getCallerReadyMessage = (
  service: Service,
  callerReadyAccountStatus: string,
  lastCallerReadyCharge: string,
  lastCallerReadyChargeResult?: string
): string => {
  const nextChargeDate = moment(lastCallerReadyCharge).add(30, 'days');

  if (!lastCallerReadyChargeResult) {
    return `Please toggle on to begin to use Sonic's Caller Ready System.`;
  }

  if (lastCallerReadyChargeResult === 'Failure') {
    return 'Your last charge failed and your account is currently locked. Please click the charge button to manually processes the charge to gain access to Caller Ready account.';
  }

  if (callerReadyAccountStatus === 'active') {
    return `You're currently active. Your next charge will take place on your next login after ${nextChargeDate.format(
      'MM/DD/yyyy'
    )} for ${formatCurrencyForDisplay(service.fee.amount)}`;
  }
  if (callerReadyAccountStatus === 'pending') {
    return `Your setup is currently pending. You will receive an email confirmation with your username and password when your account has been approved. Your account will be charged when you first login to Sonic's Caller Ready System.`;
  }
  return 'Your account is currently paused. Please toggle on to gain access to Caller Ready account.';
};

const CallerReady: React.SFC<CallerReadyProps> = ({
  values,
  setFieldValue,
  service,
  user,
}) => {
  const [toggleServiceCharge, seTtoggleServiceCharge] = useState(false);
  const [serviceChargetModal, setServiceChargeModal] = useState(false);

  const toggleServiceChargeModal = (): void => {
    if (serviceChargetModal && user.lastCallerReadyChargeResult === 'Success') {
      const newCRStatus =
        values.callerReadyAccountStatus === 'none' ||
        values.callerReadyAccountStatus === 'pending'
          ? 'pending'
          : 'active';
      setFieldValue('callerReadyAccountStatus', newCRStatus);
    }
    setServiceChargeModal((o) => !o);
  };

  return (
    <>
      <Card className="mt-2">
        <CardHeader className="d-flex justify-content-between">
          <CardTitle
            tag="h5"
            className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
          >
            <span>{`Sonic's Caller Ready -              
                  ${formatCurrencyForDisplay(
                    service.fee.amount
                  )} Per Month`}</span>
          </CardTitle>
          <span>
            {user.lastCallerReadyChargeResult === 'Failure' ||
            !user.lastCallerReadyChargeResult ? (
              // <Button color="success" onClick={toggleServiceChargeModal}>
              //   Charge Card
              // </Button>

              <Switch
                checked={serviceChargetModal}
                onChange={() => {
                  if (!serviceChargetModal) {
                    toggleServiceChargeModal();
                  }
                }}
              />
            ) : (
              <Switch
                checked={
                  values.callerReadyAccountStatus === 'active' ||
                  values.callerReadyAccountStatus === 'pending'
                }
                onChange={() =>
                  setFieldValue(
                    'callerReadyAccountStatus',
                    getNextcallerReadyAccountStatus(
                      values.callerReadyAccountStatus
                    )
                  )
                }
              />
            )}
          </span>
        </CardHeader>
        <CardBody>
          <Col>
            <Row>
              {getCallerReadyMessage(
                service,
                values.callerReadyAccountStatus,
                user.lastCallerReadyCharge,
                user.lastCallerReadyChargeResult
              )}
            </Row>
            <Row>
              <span style={{ paddingTop: 20 }}>
                <i>
                  <p>{`A minimum account credit balance of ${formatCurrencyForDisplay(
                    service.minimumAccountBalance
                  )} is required to recieve calls. If your Marketplace balance drops below this amount you will not recieve phone calls in the Caller Ready system.`}</p>
                </i>
              </span>
            </Row>
          </Col>
        </CardBody>
      </Card>
      <ServiceChargeModel
        toggle={toggleServiceChargeModal}
        isOpen={serviceChargetModal}
        service={service}
      />
    </>
  );
};

export default CallerReady;
