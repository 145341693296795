import gql from 'graphql-tag';
import { VendorFragment } from '@/graphql/fragments';

export default gql`
  query vendorsQuery {
    vendors {
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
