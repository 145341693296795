import gql from 'graphql-tag';

const ReportFragment = gql`
  fragment ReportFragment on Report {
    id
    __typename
    url
    fileName
    creationDate
    cleanName
    status
  }
`;

export default ReportFragment;
