/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { FaCog, FaSearch } from 'react-icons/fa';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
} from 'reactstrap';
import PaginationControls from './PaginationControls';
import DebouncedSearch from './DebouncedSearch';

interface LeadControlsOptions {
  openTableOptionsModal?: () => void;
  advancedSearch: React.ReactNode;
  inputPlaceholder?: string;
  searchIcon?: boolean;
  maxWidth?: number;
}

const LeadControls: React.FunctionComponent<LeadControlsOptions> = ({
  openTableOptionsModal,
  advancedSearch,
  searchIcon,
  maxWidth = 550,
  inputPlaceholder = 'Search by keyword...',
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((o) => !o);

  return (
    <div className="d-flex py-3">
      <InputGroup>
        {advancedSearch && (
          <InputGroupButtonDropdown
            addonType="prepend"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
          >
            <DropdownToggle split caret>
              Advanced Search{' '}
            </DropdownToggle>
            <DropdownMenu className="p-4">
              {advancedSearch}
              <Button
                color="secondary"
                className="float-right"
                onClick={toggleDropdown}
                style={{
                  zIndex: 0,
                }}
              >
                Close
              </Button>
            </DropdownMenu>
          </InputGroupButtonDropdown>
        )}
        <DebouncedSearch
          name="searchText"
          placeholder={inputPlaceholder}
          style={{
            maxWidth,
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="primary">
            {searchIcon ? <FaSearch /> : 'Search'}
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <div className="d-flex">
        <PaginationControls
          className="d-flex justify-content-center"
          listClassName="mb-0"
          pageRange={3}
          marginPages={0}
          directionLinks
          endLinks
        />
        {openTableOptionsModal && (
          <Button
            color="secondary"
            className="ml-2"
            onClick={openTableOptionsModal}
          >
            <FaCog />
          </Button>
        )}
      </div>
    </div>
  );
};

export default LeadControls;
