/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { CardBody, Button, Row, Col } from 'reactstrap';
import { Form, Field } from '@availity/form';
import { useMutation } from '@apollo/react-hooks';
import updateUserProfile from '@/graphql/mutations/updateUserProfile';

import { useUser, usePageLoader, useNotifications } from '@/hooks';
import { AgentFragment } from '@/graphql/fragments';
import useProfile from '../useProfile';
import CopyButton from '../../../components/CopyButton';

// TODO - Just iterate over this for the dropdown items
// const roleLabels = {
//   super_admin: 'Super Admin',
//   admin: 'Admin',
//   user: 'User',
//   manager: 'Manager',
// };

const General: React.FC = () => {
  const { complete, start } = usePageLoader();
  const { create: createNotification } = useNotifications();

  const { data: user } = useProfile();
  const { user: currentUser } = useUser();
  const {
    flags: { allowSelfEdit = false },
  } = currentUser;
  const [updateUser, { loading: updating }] = useMutation(updateUserProfile, {
    onCompleted: () => {
      createNotification({
        message: 'Successfully updated Profile.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
    },
    update(cache, { data: { accountUpdate } }) {
      cache.writeFragment({
        id: accountUpdate.id,
        fragment: AgentFragment,
        data: accountUpdate,
      });
    },
    onError: () => {
      createNotification({
        message: 'Failed to update profile.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });
      complete();
    },
  });

  const isMe = user.agentID === currentUser.agentID;
  // TODO - Can admins not edit? Only super and managers??
  const canEdit =
    !isMe && ['manager', 'super_admin'].includes(currentUser.role);
  // const isAdminLike = admin_roles.includes(currentUser.role);

  return (
    <CardBody className="pb-2">
      <Form
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          role: user.role,
          managerIDs: user.managerIDs,
          agentApproved: user.agentApproved || false,
          companyID: user.companyID,
          networkID: user.networkID,
          agentCorpID: user.agentCorpID || '',
          corpID: user.corpID,
        }}
        onSubmit={(values) => {
          updateUser({
            variables: {
              input: {
                agentID: user.agentID,
                subID: user.subID,
                ...values,
              },
            },
          });
          start();
        }}
      >
        {({ resetForm }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => resetForm(), [user.agentID]);

          return (
            <>
              <Row>
                <Col xs={6}>
                  <Field
                    name="firstName"
                    label="First Name"
                    disabled={!allowSelfEdit}
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    name="lastName"
                    label="Last Name"
                    disabled={!allowSelfEdit}
                  />
                </Col>
                <Col xs={4}>
                  <Field name="phone" label="Phone" disabled={!canEdit} />
                </Col>
                <Col xs={8}>
                  <Field name="email" label="Email" disabled={!canEdit} />
                </Col>{' '}
                {currentUser?.corpID?.includes('usha') && (
                  <Col xs={6}>
                    <Field
                      name="agentCorpID"
                      label="Agent Corp ID"
                      disabled={!allowSelfEdit}
                    />
                  </Col>
                )}
              </Row>
              <Row style={{ alignItems: 'center' }}>
                <CopyButton content={user.agentID!} className="ml-3" />
                <span style={{ marginLeft: 10 }}>{user.agentID}</span>
              </Row>
              {(currentUser.role !== 'user' || allowSelfEdit) && (
                <Button
                  type="submit"
                  color="primary"
                  className="float-right"
                  disabled={updating}
                >
                  Save Changes
                </Button>
              )}
            </>
          );
        }}
      </Form>
    </CardBody>
  );
};

export default General;
