import gql from 'graphql-tag';
import { AgentServiceFragment } from '../fragments';

export default gql`
  mutation requestRefund($input: LeadRefundRequest!) {
    requestRefund(input: $input) {
      ...AgentServiceFragment
    }
  }
  ${AgentServiceFragment}
`;
