import gql from 'graphql-tag';

const BulkPurchaseFragment = gql`
  fragment BulkPurchaseFragment on Bulk {
    id
    __typename
    leadBulkID
    priceTierID
    priceTier
    agentID
    created
    addedDate
    status
    price
    requestedLeadCount
    completedLeadCount
    completedLeadsCSVFileName
    totalAmountCharged
    error
    filters {
      __typename
      filterType
      filterValue {
        max
        min
        regions
      }
    }
  }
`;

export default BulkPurchaseFragment;
