import gql from 'graphql-tag';

export default gql`
  mutation transferMutation($input: RelayCreditTransferAgentInput!) {
    creditTransfer(input: $input) {
      transfers {
        id
        agentID
        value
        errors
        status
      }
    }
  }
`;
