import gql from 'graphql-tag';
import { PriceTierFragment } from '../fragments';

export default gql`
  query tiersQuery($networkID: String) {
    tiers(networkID: $networkID) {
      ...PriceTierFragment
      __typename
      id
      description
      allowedDestinationTypes
      price
      liveTransferPrice
      dynamicPrice
      priceTierID
      vertical
      name
      liveTransfers
      allowedFilters
      minPrice
      maxPrice
      isAged
      maxCampaignCount
      maxLeadCountPerDay
    }
  }
  ${PriceTierFragment}
`;
