import gql from 'graphql-tag';
import { BulkPurchaseFragment } from '../fragments';

export default gql`
  mutation upsertBulkPurchase($input: BulkPurchaseInputType!) {
    upsertBulkPurchase(input: $input) {
      ...BulkPurchaseFragment
    }
  }
  ${BulkPurchaseFragment}
`;
