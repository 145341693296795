import React from 'react';
import { VerifyContact } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { Button, Card, CardTitle, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Radio, RadioGroup, Field } from '@availity/form';
import * as yup from 'yup';
import AuthHeader from './AuthHeader';

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
class VerifyEmail extends VerifyContact {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['verifyContact'];
  }

  public showComponent(): JSX.Element {
    return (
      <AuthHeader cardTitle="Account recovery requires verified contact information">
        {this.state.verifyAttr ? (
          <Formik
            onSubmit={async (values) => {
              try {
                await Auth.verifyCurrentUserAttributeSubmit(
                  this.state.verifyAttr,
                  values.code
                );
                this.changeState('signedIn', this.props.authData);
                this.setState({
                  verifyAttr: null,
                });
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              code: '',
            }}
            validationSchema={yup.object().shape({
              code: yup.string().required('Please enter your code.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <BlockUI blocking={isSubmitting}>
                  <Field name="code" type="text" placeholder="Code" />
                </BlockUI>
                <div className="d-flex justify-content-between">
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    block
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    color="warning"
                    className="mt-0 ml-3"
                    block
                    onClick={() =>
                      this.changeState('signedIn', this.props.authData)
                    }
                  >
                    Skip
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            onSubmit={async (values) => {
              try {
                await Auth.verifyCurrentUserAttribute(values.contactType);

                this.setState({
                  verifyAttr: values.contactType,
                });
              } catch (error) {
                this.error(error);
              }
            }}
            initialValues={{
              contactType: 'email',
            }}
            validationSchema={yup.object().shape({
              contactType: yup
                .string()
                .required('Please select a verification method.'),
            })}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <BlockUI blocking={isSubmitting}>
                  <RadioGroup
                    groupClassName="bg-dark text-white"
                    label="Select a contact method to verify with."
                    name="contactType"
                  >
                    <Radio label="Email" value="email" />
                    <Radio label="Phone Number" value="phone_number" />
                  </RadioGroup>
                </BlockUI>
                <div className="d-flex justify-content-between">
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    block
                  >
                    Verify
                  </LoadingButton>
                  <Button
                    color="warning"
                    className="mt-0 ml-3"
                    block
                    onClick={() =>
                      this.changeState('signedIn', this.props.authData)
                    }
                  >
                    Skip
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </AuthHeader>
    );
  }
}

export default VerifyEmail;
