import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { Button, Card, CardTitle, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import * as yup from 'yup';
import AuthHeader from './AuthHeader';

const isSonic = window.location.href.includes('sonicleads');
// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
class CustomSignIn extends ConfirmSignUp {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['confirmSignUp'];
  }

  public showComponent(): JSX.Element {
    return (
      <AuthHeader cardTitle="Confirm Your Account">
        <Formik
          onSubmit={async (values) => {
            try {
              const username = isSonic
                ? values.username.toLowerCase().replace('@', '+1@')
                : values.username.toLowerCase();
              const res = await Auth.confirmSignUp(username, values.code);

              if (res === 'SUCCESS') {
                this.changeState('signedUp');
              }
            } catch (error) {
              this.error(error);
            }
          }}
          initialValues={{
            username: '',
            code: '',
          }}
          validationSchema={yup.object().shape({
            username: yup
              .string()
              .required('Please enter an email.')
              .email('Please provide a valid email.'),
            code: yup.string().required('Please enter your code.'),
          })}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <BlockUI blocking={isSubmitting}>
                <Field
                  id="username"
                  name="username"
                  type="email"
                  onChange={this.handleInputChange}
                  placeholder="Email"
                  label="Email"
                />
                <Field
                  name="code"
                  type="text"
                  onChange={this.handleInputChange}
                  placeholder="Code"
                  label="Code"
                />
              </BlockUI>
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                color="primary"
                size="lg"
                type="submit"
                block
              >
                Sign In
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <div className="d-flex flex-column pt-2">
          <span>
            Forgot your password?{' '}
            <Button
              color="link"
              onClick={() => this.changeState('forgotPassword')}
            >
              Reset Password
            </Button>
          </span>
          <span>
            No Account?{' '}
            <Button color="link" onClick={() => this.changeState('signUp')}>
              Create Account
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomSignIn;
