import React from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';

const Toggle = ({ active, loading, onToggle }) =>
  loading ? (
    <FaSync className="loading-sync" size={24} />
  ) : (
    <Switch checked={active} onChange={() => onToggle()} />
  );

export default Toggle;
