/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-expressions */
import React, { useState, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { uuid } from 'uuidv4';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FaSync } from 'react-icons/fa';
import { SpendingPeriod as SpendingPeriodType } from '@sonic-web-dev/types';
import {
  sumSpendingPeriods,
  deLocalizeSpendingPeriod,
} from '@/areas/Campaigns/Utils';
import { formatCurrencyForDisplay } from '@/utils';
import { NotificationContext } from '@/context';
import { campaignByID } from '@/graphql/queries';
import { updateSpendingPeriods as updateSpendingPeriodsMutation } from '@/graphql/mutations';
import { Form } from '@availity/form';
import * as yup from 'yup';
import { FieldArray } from 'formik';
import { useUser } from '@/hooks';
import SpendingPeriod from './SpendingPeriod';

const MAX_SPENDING_PERIODS = 4;
const MIN_DAILY_SPEND = 100;
const attributePrefix = 'edit-spending-period-modal';

const EditSpendingPeriodModal: React.SFC<any> = ({
  campaign,
  closeModal,
  isOpen,
  saving,
}) => {
  const {
    campaignID,
    campaignName,
    id,
    paused,
    sidelineIntegration,
    isAged,
  } = campaign;

  const { create } = useContext(NotificationContext);
  const {
    user: { corpID },
  } = useUser();

  const {
    data: {
      node: {
        priceTier: {
          liveTransferPrice,
          liveTransfers: isLiveTransfer,
          price,
          maxLeadCountPerDay = 0,
        },
      },
    },
    loading,
  } = useQuery<any>(campaignByID, {
    variables: {
      id,
    },
  });

  const [updateSpendingPeriods] = useMutation(updateSpendingPeriodsMutation, {
    update(cache, data) {
      const {
        updateSpendingPeriods: { success, values },
      } = data.data as any;

      if (success) {
        const spendingPeriods = values.filter(
          ({ status }: SpendingPeriodType) => status !== 'deleted'
        );
        cache.writeData({
          id,
          data: {
            ...campaign,
            paused: spendingPeriods.length === 0 ? true : paused,
            spendingPeriods,
          },
        });

        create({
          message: 'Successfully updated',
          color: 'success',
          timeout: 1000,
          type: 'alert',
          action: 'MESSAGE',
        });
      }
    },
    onCompleted: () => {
      closeModal();
    },
    onError: (error) =>
      create({
        message: error.message.replace('GraphQL error:', ''),
        color: 'danger',
        type: 'alert',
        action: 'MESSAGE',
        timeout: 3000,
      }),
  });

  // Done
  const close = () => {
    closeModal();
  };

  const actualPrice = isLiveTransfer ? liveTransferPrice : price;

  const createSpendingPeriodValidation = (): any => {
    if (sidelineIntegration) {
      return yup.object().shape({
        phone: isLiveTransfer
          ? yup
              .string()
              .matches(/^\d{10}$/, 'Must be a valid phone number')
              .isRequired()
          : yup.string().nullable(),
        dailySpendLimit: yup
          .number()
          .min(
            MIN_DAILY_SPEND,
            `The minimum daily spend per period is ${formatCurrencyForDisplay(
              MIN_DAILY_SPEND
            )}`
          )
          .max(
            maxLeadCountPerDay * (actualPrice || 100),
            `The maximum daily spend across all periods is ${formatCurrencyForDisplay(
              maxLeadCountPerDay * (actualPrice || 100)
            )}`
          ),
        throttleLeadsPer: yup
          .number()
          .min(120000, 'No more than 1 lead per 2 min for sideline campaigns'),
        startTime: yup
          .number()
          .min(540, 'Start time for sideline campaigns cannot start before 9am')
          .test(
            'test-endTime',
            'Start Date cannot come before start date and must end at most by 12 A.M. the next day.',
            function testEndTime(endTime: number) {
              const { startTime } = this.parent;

              if (startTime === 540) {
                return true;
              }

              return startTime > endTime;
            }
          ),
        endTime: yup
          .number()
          .max(1200, 'End time cannot exceed 8pm') // theoretically with the current controls this should never happen
          .min(541, 'End time cannot exceed 8pm') // theoretically with the current controls this should never happen
          .test(
            'test-endTime',
            'End Date cannot come before start date and must end at most by 12 A.M. the next day.',
            function testEndTime(endTime: number) {
              const { startTime } = this.parent;

              if (endTime === 0) {
                return true;
              }

              return startTime < endTime;
            }
          ),
      });
    }

    return yup.object().shape({
      phone: isLiveTransfer
        ? yup
            .string()
            .matches(/^\d{10}$/, 'Must be a valid phone number')
            .isRequired()
        : yup.string().nullable(),
      dailySpendLimit: yup
        .number()
        .min(
          MIN_DAILY_SPEND,
          `The minimum daily spend per period is ${formatCurrencyForDisplay(
            MIN_DAILY_SPEND
          )}`
        )
        .max(
          maxLeadCountPerDay * (actualPrice || 100),
          `The maximum daily spend across all periods is ${formatCurrencyForDisplay(
            maxLeadCountPerDay * (actualPrice || 100)
          )}`
        ),
      endTime: yup
        .number()
        .max(1440, 'End time cannot exceed the 24 hour period.') // theoretically with the current controls this should never happen
        .test(
          'test-endTime',
          'End Date cannot come before start date and must end at most by 12 A.M. the next day.',
          function testEndTime(endTime: number) {
            const { startTime } = this.parent;

            if (endTime === 0) {
              return true;
            }

            return startTime < endTime;
          }
        ),
    });
  };

  const createPresetSpendingPeriodSettings = (): any => {
    if (isAged) {
      return {
        phone: isLiveTransfer ? '' : undefined,
        campaignID,
        spendingPeriodID: uuid(),
        dailySpendLimit: 100,
        delaySpendBy: 60000,
        dowSun: true,
        dowMon: true,
        dowTue: true,
        dowWed: true,
        dowThu: true,
        dowFri: true,
        dowSat: true,
        endTime: 1440,
        lastPurchaseEpoch: 0,
        lastPurchasePeriodSpend: 0,
        startTime: 0,
        throttleNoMoreThan: 1,
        throttleLeadsPer: 60000,
        status: 'active',
      };
    }

    if (sidelineIntegration) {
      return {
        phone: isLiveTransfer ? '' : undefined,
        campaignID,
        spendingPeriodID: uuid(),
        dailySpendLimit: 100,
        delaySpendBy: 120000,
        dowSun: true,
        dowMon: true,
        dowTue: true,
        dowWed: true,
        dowThu: true,
        dowFri: true,
        dowSat: true,
        endTime: 1200,
        lastPurchaseEpoch: 0,
        lastPurchasePeriodSpend: 0,
        startTime: 540,
        throttleNoMoreThan: 1,
        throttleLeadsPer: 120000,
        status: 'active',
      };
    }

    return {
      phone: isLiveTransfer ? '' : undefined,
      campaignID,
      spendingPeriodID: uuid(),
      dailySpendLimit: 100,
      delaySpendBy: 0,
      dowSun: true,
      dowMon: true,
      dowTue: true,
      dowWed: true,
      dowThu: true,
      dowFri: true,
      dowSat: true,
      endTime: 1440,
      lastPurchaseEpoch: 0,
      lastPurchasePeriodSpend: 0,
      startTime: 0,
      throttleNoMoreThan: 0,
      throttleLeadsPer: 0,
      status: 'active',
    };
  };

  // const remainingMaxSpend =
  //   maxLeadCountPerDay > 0
  //     ? campaign.spendingPeriods.reduce((t, v) => {
  //         return t - v.dailySpendLimit;
  //       }, maxLeadCountPerDay * price) || 99999999999999999
  //     : 99999999999999999;

  return (
    <Modal isOpen={isOpen} toggle={close} id={attributePrefix}>
      <Form
        initialValues={{
          spendingPeriods: campaign.spendingPeriods || [],
          removeOperations: [],
        }}
        validationSchema={yup.object().shape({
          spendingPeriods: yup
            .array()
            .of(createSpendingPeriodValidation())
            .test(
              'sum',
              'The total number of elements must match the total.',
              (spendingPeriods = []) => {
                const total = spendingPeriods.reduce(
                  (total: number, sp: SpendingPeriodType) => {
                    return total + (sp.dailySpendLimit || 0);
                  },
                  0
                );
                return total <= maxLeadCountPerDay * (actualPrice || 100);
              }
            ),
        })}
        onSubmit={(values) => {
          updateSpendingPeriods({
            variables: {
              input: {
                campaignID,
                spendingPeriods: [
                  ...values.spendingPeriods.map((s: any) =>
                    deLocalizeSpendingPeriod(s, moment().utcOffset())
                  ),
                  ...values.removeOperations,
                ].map((period) => {
                  delete period.__typename;
                  delete period.id;
                  return period;
                }),
              },
            },
          });
        }}
      >
        {({ values, errors }) => (
          <>
            <ModalHeader toggle={close} id={`${attributePrefix}-card-title`}>
              Spending Periods - {campaignName}
            </ModalHeader>
            <ModalBody>
              <FieldArray
                name="spendingPeriods"
                render={(arrayHelpers) => (
                  <>
                    {values.spendingPeriods.length === 0 && (
                      <div>No Active Spending Periods.</div>
                    )}
                    {values.spendingPeriods.map((s: any, periodIdx: number) => (
                      <SpendingPeriod
                        isLiveTransfer={isLiveTransfer}
                        price={actualPrice}
                        maxLeadCountPerDay={maxLeadCountPerDay}
                        {...s}
                        key={s.spendingPeriodID}
                        removeSpendingPeriod={arrayHelpers.remove}
                        period={periodIdx}
                      />
                    ))}
                    <div>
                      {saving ? (
                        <FaSync className="loading-sync" size={24} />
                      ) : (
                        <ButtonGroup>
                          <Button
                            id={`${attributePrefix}-add-new-spending-period`}
                            color="primary"
                            disabled={
                              values.spendingPeriods.length >=
                              MAX_SPENDING_PERIODS
                            }
                            onClick={() =>
                              arrayHelpers.push(
                                createPresetSpendingPeriodSettings()
                              )
                            }
                          >
                            Add New Spending Period
                          </Button>
                          <Button
                            color="success"
                            type="submit"
                            id={`${attributePrefix}-save-spending-period`}
                          >
                            Save Spending Periods
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </>
                )}
              />
            </ModalBody>
            <ModalFooter>
              Weekly maximum:{' '}
              {formatCurrencyForDisplay(
                sumSpendingPeriods(values.spendingPeriods)
              )}
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditSpendingPeriodModal;
