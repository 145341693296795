import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
} from 'reactstrap';

interface LeadModalProps extends Omit<ModalProps, 'toggle'> {
  toggle: () => void;
}

const LeadsShowExportModal: React.SFC<LeadModalProps> = ({
  toggle,
  isOpen,
  ...rest
}) => (
  <Modal size="md" isOpen={isOpen} toggle={toggle} {...rest}>
    <ModalHeader toggle={toggle}>Download Processing!</ModalHeader>
    <ModalBody>
      Your download is processing. You will receive a notification once it is
      ready!
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggle}>
        Ok
      </Button>
    </ModalFooter>
  </Modal>
);

export default LeadsShowExportModal;
