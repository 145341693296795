import { formatPhoneNumber } from '@/areas/Campaigns/Utils';
import moment from 'moment';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Col, Row, Table, TabPane } from 'reactstrap';
import { leadNodeQuery } from '@/graphql/queries';

const LeadDetailsTabPane: React.FC<{
  lead: any;
  tabId: string;
  active: boolean;
}> = ({ lead, tabId, active }) => {
  const { data, loading: loadingPhones } = useQuery(leadNodeQuery, {
    variables: {
      id: lead.id,
    },
    skip: !active,
  });

  let phones = data?.node?.phones || [];

  if (phones.length === 0) {
    phones = [
      {
        carrier: null,
        confidenceScore: null,
        isPrimary: true,
        phone: lead.orgPhone,
        status: null,
        timeZone: null,
        type: null,
      },
    ];
  }

  return (
    <TabPane tabId={tabId}>
      <Row className="pt-2">
        <Col xs={2}>
          <span className="lead text-uppercase">Overview</span>
          <Table borderless>
            <tbody>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Date Of Birth
                </td>
                <td>{moment(lead.dateOfBirth).format('MMM Do YYYY')}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Age
                </td>
                <td>{lead.age} yrs</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Height
                </td>
                <td>{lead.height || '-'}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Weight
                </td>
                <td>{lead.weight || '-'}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col xs={2}>
          <span className="lead text-uppercase">Geographics</span>
          <Table borderless>
            <tbody>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Address
                </td>
                <td className="d-flex flex-column">
                  <span>{lead.addressOne}</span>
                  <span>{lead.addressTwo}</span>
                  <span>{lead.addressThree}</span>
                </td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  City
                </td>
                <td>{lead.city}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  State
                </td>
                <td>{lead.region}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Postal Code
                </td>
                <td>{lead.postalCode}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col xs={2}>
          <span className="lead text-uppercase">FPL</span>
          <Table borderless>
            <tbody>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Income
                </td>
                <td>{lead.income || '-'}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Household
                </td>
                <td>{lead.household || '-'}</td>
              </tr>
              <tr>
                <td className="text-muted" style={{ width: 130 }}>
                  Povery Level
                </td>
                <td>{lead.povertyLevel}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col xs={6}>
          <span className="lead text-uppercase">Phones</span>
          <Table
            borderless
            style={{
              borderCollapse: 'separate',
              borderSpacing: '0 10px',
            }}
          >
            <thead>
              <tr>
                <th>Phone</th>
                <th>Carrier</th>
                <th>Type</th>
                <th>Confidence</th>
              </tr>
            </thead>
            <tbody>
              {loadingPhones ? (
                <tr>
                  <td colSpan={4}>Loading Phones</td>
                </tr>
              ) : (
                phones?.map((p) => (
                  <tr key={p.phone}>
                    <td className="pl-3 py-2 border-left border-top border-bottom border-secondary rounded-lg">
                      {formatPhoneNumber(p.phone)}
                    </td>
                    <td className="py-2 border-top border-bottom border-secondary">
                      {p.carrier || '-'}
                    </td>
                    <td className="py-2 border-top border-bottom border-secondary">
                      {p.type || '-'}
                    </td>
                    <td className="py-2 border-right border-top border-bottom border-secondary rounded-lg">
                      {p.confidenceScore || '-'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </TabPane>
  );
};

export default LeadDetailsTabPane;
