import { agentBulkPagination } from '@/graphql/queries';
import Pagination, {
  PaginationContent,
  PaginationControls,
} from '@availity/pagination';
import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { useUser, useNotifications } from '@/hooks';
import displayColumns from './DisplayColumns';
import TableRow from './TableRow';

const BulkPurchaseTable: React.FC<{
  refresh: boolean;
  newBulkCount: number;
}> = ({ refresh, newBulkCount }) => {
  const { user } = useUser();
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [
    pagination,
    { data, refetch, updateQuery, networkStatus },
  ] = useLazyQuery(agentBulkPagination, { notifyOnNetworkStatusChange: true });

  const fetchBulkPurchases = useCallback(
    (currentPage) => {
      // async (currentPage: number): Promise<any> => {
      if (user?.agentID) {
        pagination({
          variables: {
            agentID: user?.agentID,
            key: pageKeys[currentPage - 1],
          },
        });

        setPageKeys((prevState) =>
          prevState
            .concat(data?.agentBulkPagination?.key)
            .filter((key, pos, self) => self.indexOf(key) === pos)
        );

        if (refresh) {
          pagination({
            variables: {
              agentID: user?.agentID,
              key: '',
            },
          });
        }

        return {
          items: data?.agentBulkPagination.items.sort(
            (a: string, b: string) => new Date(b.created) - new Date(a.created)
          ),
          totalCount: data?.agentBulkPagination.totalCount,
        };
      }
      return null;
    },
    [user, data, pageKeys, pagination, refresh]
  );

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, newBulkCount]);

  // FUCK APOLLO CACHE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Doesn't update in memory cache on refetch and Pagination calls over and over on network-only settings
  if (networkStatus === 4) {
    return null;
  }

  return (
    <Pagination
      items={fetchBulkPurchases}
      itemsPerPage={10}
      watchList={[data, refresh]}
    >
      <Card>
        <CardHeader>
          <CardTitle
            tag="h3"
            className="d-flex justify-content-between align-items-center mb-0"
          >
            Bulk Purchase History
          </CardTitle>
        </CardHeader>
        {pageKeys.length > 0 ? (
          <CardBody>
            <Table hover striped size="sm" responsive>
              <thead>
                <tr>
                  {displayColumns.map((column) => (
                    <th
                      key={column.dataField}
                      className="align-middle py-3 no-wrap-centered"
                      style={{ fontSize: 16 }}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div>{column.displayName}</div>{' '}
                        {column.dataField === 'estimatedPrice' ? (
                          <>
                            <FaQuestionCircle
                              className="ml-1 cursor-pointer"
                              id="bulk-table-estimated-price-tooltip"
                            />
                            <UncontrolledTooltip target="bulk-table-estimated-price-tooltip">
                              Prices are estimated until the status of the order
                              is completed.
                            </UncontrolledTooltip>
                          </>
                        ) : null}
                        {column.dataField === 'requestedLeadCount' ? (
                          <>
                            <FaQuestionCircle
                              className="ml-1 cursor-pointer"
                              id="bulk-table-leads-tooltip"
                            />
                            <UncontrolledTooltip target="bulk-table-leads-tooltip">
                              Final lead count can vary based on available leads
                              at time of processing.
                            </UncontrolledTooltip>
                          </>
                        ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <PaginationContent
                itemKey="leadBulkID"
                component={TableRow}
                containerTag="tbody"
                columns={displayColumns}
              />
            </Table>
          </CardBody>
        ) : (
          <Spinner className="my-2 ml-5" color="primary" />
        )}
      </Card>
      <PaginationControls
        directionLinks
        listClassName="mt-3"
        pageRange={1}
        marginPages={0}
        endLinks
      />
    </Pagination>
  );
};

export default BulkPurchaseTable;
