import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ListGroup, Card, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { SelectField } from '@availity/select';
import Pagination, { PaginationContent } from '@availity/pagination';
import { usePageLoader } from '@/hooks';
import { Form } from '@availity/form';
import { NoItemsMessage, SearchSummary, SearchControls } from '@/components';
import { agentServicePagination } from '@/graphql/queries';
import RefundListItem from './RefundListItem';

import useProfile from '../useProfile';

const LeadRefunds: React.FunctionComponent = () => {
  const { start, complete } = usePageLoader();
  const client = useApolloClient();
  const { data: user } = useProfile();

  return (
    <Form
      initialValues={{
        searchText: '',
        status: 'pending',
        refundType: undefined,
        sort: {
          created: 'DESC',
        },
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, resetForm }) => {
        useEffect(() => {
          resetForm();
        }, [user?.agentID]);

        useEffect(() => {
          if (values.status === 'pending') {
            setFieldValue('refundType', undefined);
          }
        }, [values.status]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: agentServicePagination,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                q: values.searchText,
                status: values.status,
                refundType: values.refundType,
              },
              orderBy: values.sort,
              perPage: itemsPerPage,
              page: currentPage,
            },
          });

          const currentPageData = response.data?.agentServicePagination.items;

          complete();
          return {
            totalCount: response.data.agentServicePagination.count,
            items: currentPageData,
          };
          // }
        };

        return (
          <div>
            <Card className="mb-3">
              <CardHeader>
                <CardTitle tag="h5" className="py-2 h4 mb-0">
                  Lead Refunds
                </CardTitle>
              </CardHeader>
            </Card>
            <Pagination
              items={fetchItems}
              itemsPerPage={25}
              resetParams={[
                user?.agentID,
                values.searchText,
                values.status,
                values.sort,
                values.refundType,
              ]}
            >
              <SearchControls
                searchIcon
                maxWidth={270}
                advancedSearch={
                  <Row style={{ minWidth: 530 }}>
                    <Col xs={6}>
                      <SelectField
                        name="status"
                        label="Status"
                        isClearable
                        options={[
                          { label: 'Pending', value: 'pending' },
                          { label: 'Completed', value: 'completed' },
                        ]}
                      />
                    </Col>
                    {values.status === 'completed' && (
                      <Col xs={6}>
                        <SelectField
                          name="refundType"
                          label="Refund Type"
                          isClearable
                          options={[
                            { label: 'Denied', value: 'DENY' },
                            { label: 'Partial', value: 'PARTIAL' },
                            { label: 'Full', value: 'FULL' },
                          ]}
                        />
                      </Col>
                    )}
                  </Row>
                }
                inputPlaceholder="Search by Case or Lead ID..."
              />
              <SearchSummary className="d-flex justify-content-between py-3" />
              <PaginationContent
                component={RefundListItem}
                itemKey="serviceCaseID"
                containerTag={ListGroup}
              />
              <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2 h5">
                No Items Found.
              </NoItemsMessage>
            </Pagination>
          </div>
        );
      }}
    </Form>
  );
};

export default LeadRefunds;
