import React from 'react';
import {
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import { useField } from 'formik';
import MoneyInput from './MoneyInput';

const MoneyField: React.FunctionComponent<any> = ({
  id,
  name,
  minimum,
  maximum,
  label,
  size,
  placeholder,
  helpText,
}) => {
  const [field, { error }, { setValue }] = useField(name);

  return (
    <FormGroup style={{ margin: 0 }}>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup id={id} size={size || 'sm'} style={{ minWidth: '100px' }}>
        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
        <MoneyInput
          placeholder={placeholder}
          minimum={minimum}
          maximum={maximum}
          onChange={(v: number) => setValue(v)}
          value={field.value}
        />
      </InputGroup>
      {helpText && <FormText>{helpText}</FormText>}
      <div className="text-danger">{error}</div>
    </FormGroup>
  );
};

export default MoneyField;
