import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Label,
  FormGroup,
  Form,
} from 'reactstrap';
import LoadingButton from '@gopro16/button';
import { FaLock } from 'react-icons/fa';
import { useNotifications, useUser, useAccount } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { currentAgentQuery, currentAccountQuery } from '@/graphql/queries';
import { formatCurrencyForDisplay } from '@/utils';
import { serviceCharge } from '@/graphql/mutations';

interface ServiceFee {
  description: string;
  amount: number;
}

interface Service {
  id: string;
  fee: ServiceFee;
  minimumAccountBalance: number;
}

const ServiceChargeModal: React.SFC<{
  service: Service;
  isOpen: boolean;
  toggle: () => void;
}> = ({ service, isOpen, toggle }) => {
  const { account } = useAccount();
  const cardId = account?.card?.id;
  const { create: createNotification } = useNotifications();

  const [confirmServiceChargeToAdd, setConfirmServiceChargeToAdd] = useState(
    false
  );

  const [chargeServiceFee, { loading: updating }] = useMutation(serviceCharge, {
    update(cache, { data: { accountServiceCharge } }) {
      const { userCurrent } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      const { account } = cache.readQuery({
        query: currentAccountQuery,
      }) as any;

      cache.writeQuery({
        query: currentAccountQuery,
        data: {
          account: {
            ...account,
            ...accountServiceCharge.result,
          },
        },
      });

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          userCurrent: {
            ...userCurrent,
            ...accountServiceCharge.result,
          },
        },
      });
    },
    onCompleted: () => {
      createNotification({
        message: 'Account Charged Service Fee',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      toggle();
    },
    onError: (error) =>
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      }),
  });
  useEffect(() => {
    setConfirmServiceChargeToAdd(false);
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Service Charge</ModalHeader>
      <ModalBody>
        <Container>
          <Form
            onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
              event.preventDefault();

              chargeServiceFee({
                variables: {
                  serviceID: service.id, //
                  cardId,
                },
              });
            }}
          >
            <br />
            <span className="h4 text-warning">
              Your card will be charged:{' '}
              {formatCurrencyForDisplay(service.fee.amount)}
            </span>
            <br />
            <FormGroup className="mt-3 d-flex flex-column">
              <div>
                <Button
                  color="success"
                  onClick={() => setConfirmServiceChargeToAdd(true)}
                  disabled={
                    service.fee.amount === 0 || confirmServiceChargeToAdd
                  }
                >
                  <span className="mr-2">Charge Card</span>
                  <FaLock />
                </Button>
                {confirmServiceChargeToAdd && (
                  <LoadingButton
                    className="ml-3"
                    color="warning"
                    loading={updating}
                    disabled={updating}
                  >
                    <span className="mr-2">Confirm</span>
                    <FaLock />
                  </LoadingButton>
                )}
              </div>
              <br />
              <small className="text-muted">
                Pressing this button charges your card. Please review the total
                amount above before pressing this button.
              </small>
            </FormGroup>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceChargeModal;
