import gql from 'graphql-tag';
import { AgentFragment } from '../fragments';

export default gql`
  mutation updateAgentsMutation($input: UpdateAgentsInput) {
    agentsUpdate(input: $input) {
      ...AgentFragment
    }
  }
  ${AgentFragment}
`;
