import gql from 'graphql-tag';
import { AgentFragment } from '@/graphql/fragments';

export default gql`
  mutation autoFundSettingsMutation($input: UpdateOneAgentInput) {
    accountUpdate(input: $input) {
      ...AgentFragment
    }
  }
  ${AgentFragment}
`;
