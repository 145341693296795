import gql from 'graphql-tag';

export default gql`
  subscription creditNotification {
    notification: creditNotification {
      action
      data
      timeout
      notificationID
    }
  }
`;
