import React, { FC, useEffect } from 'react';
import { Form } from '@availity/form';
import omit from 'lodash.omit';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import { Button, Container, Row, Spinner } from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { uuid } from 'uuidv4';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { useHistory } from 'react-router-dom';

const TextdripAuth: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const apiToken = queryParams.get('apiToken');

  const { user } = useUser();
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();

  const [updateProfile] = useMutation(updateUserProfile, {
    onCompleted: () => {
      createNotification({
        message: 'Successfully Authorized Textdrip.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
      history.push('/profile/me');
    },
    update(cache, { data: { accountUpdate } }) {
      cache.writeFragment({
        id: accountUpdate.id,
        fragment: AgentFragment,
        data: accountUpdate,
      });
    },
    onError: (error) => {
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });

      complete();
    },
  });

  useEffect(() => {
    console.log(user!.crmProfiles);
    if (email && apiToken) {
      updateProfile({
        variables: {
          input: {
            agentID: user.agentID,
            crmProfiles: {
              vanillaSoftDirect: {
                ...user!.crmProfiles!.vanillaSoftDirect,
                label: 'VanillaSoft',
                profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                  (p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })
                ),
              },
              iSalesCRM: {
                ...user!.crmProfiles!.iSalesCRM,
                label: 'iSalesCRM',
                profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map((p) => ({
                  ...omit(p, ['__typename', 'profileID']),
                  id: p.profileID || p.id,
                })),
              },
              textdrip: {
                apiToken: apiToken?.trim(),
                email: email?.trim(),
                label: 'Textdrip',
                profiles: [],
              },
              onlysales: {
                ...user!.crmProfiles!.onlysales,
                label: 'OnlySales',
                profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                  ...omit(p, ['__typename', 'profileID']),
                  id: p.profileID || p.id,
                  description: p?.description.trim(),
                  crmFields: {
                    integrationLink: p?.crmFields.integrationLink.trim(),
                    authToken: p?.crmFields.authToken.trim(),
                  },
                })),
              },
            },
          },
        },
      });
    }
  }, []);

  return (
    <>
      {email && apiToken ? (
        <Spinner color="primary" className="h4 mx-auto" />
      ) : (
        <>
          <Row className="d-flex justify-content-center">
            <h3>Missing Textdrip {apiToken ? 'Email' : 'API Token'}</h3>
          </Row>
          <Row className="d-flex justify-content-center">
            <h4>
              Please reauthorize Textdrip and check both the email and API Token
              boxes.
            </h4>
          </Row>
          <Row className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={() => {
                const authUrl = `https://app.textdrip.com/tauth?redirect=${window.location.origin}/auth`;
                window.location.replace(authUrl);
              }}
            >
              Back
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default TextdripAuth;
