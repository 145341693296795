import gql from 'graphql-tag';
import { VendorLayerFragment } from '@/graphql/fragments';

export default gql`
  query vendorLayersQuery {
    vendorLayers {
      ...VendorLayerFragment
    }
  }
  ${VendorLayerFragment}
`;
