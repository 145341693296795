/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

interface SwitchButtonProps {
  onlabel?: string | 'On';
  offlabel?: string | 'Off';
  onstyle?: string | 'primary';
  offstyle?: string | 'light';
  size?: string;
  width?: number;
  height?: number;
  checked?: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  checked,
  onstyle,
  offstyle,
  size,
  width,
  height,
  onlabel,
  offlabel,
  onChange,
  disabled,
}) => {
  const labelStyle: any = {};
  if (height) labelStyle.lineHeight = `calc(${height}px * 0.8)`;

  return (
    <div
      className={`switch btn ${
        checked ? `on btn-${onstyle}` : `off btn-${offstyle}`
      }${size ? ` btn-${size}` : ''}`}
      style={{
        width,
        height,
      }}
      onClick={() => !disabled && onChange()}
    >
      <div className="switch-group">
        <span
          className={`switch-on btn btn-${onstyle}${
            size ? ` btn-${size}` : ''
          }`}
          style={labelStyle}
        >
          {onlabel}
        </span>
        <span
          className={`switch-off btn btn-${offstyle}${
            size ? ` btn-${size}` : ''
          }`}
          style={labelStyle}
        >
          {offlabel}
        </span>
        <span
          className={`switch-handle btn btn-light${size ? `btn-${size}` : ''}`}
        />
      </div>
    </div>
  );
};

export default SwitchButton;
