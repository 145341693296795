/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Form } from '@availity/form';
import * as yup from 'yup';
import { Button, Card, CardTitle, Container, Row } from 'reactstrap';
import {
  useCampaignsNetwork,
  useNotifications,
  usePriceTier,
  useUser,
} from '@/hooks';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { upsertBulkPurchaseMutation } from '@/graphql/mutations';
import moment from 'moment';
import classNames from 'classnames';
import { BulkPurchaseModal } from '@/modals';
import { bulkPrice } from '@/utils';
import tierTimeoutQuery from '@/graphql/queries/tierTimeoutQuery';
import Table from './components/Table';
import PurchaseBulkLeads from './components/PurchaseBulkLeads';

export const filters = (values: any): any =>
  [
    {
      filterType: 'regions',
      filterValue: { regions: values?.regions },
    },
    { filterType: 'age', filterValue: values?.ageInt },
    { filterType: 'household', filterValue: values?.householdInt },
    { filterType: 'income', filterValue: values?.incomeInt },
  ].filter((f: any): boolean => {
    if (f.filterValue?.min === 0 && f.filterValue?.max === 0) {
      return false;
    }
    if (f.filterType === 'regions' && f.filterValue?.regions?.length === 0) {
      return false;
    }
    return true;
  }) || [];

const BulkPurchase: React.FC = () => {
  const idPrefix = 'bulk-purchase';
  const { user } = useUser();
  const { newBulkCount, create: createNotification } = useNotifications();
  const { networkID } = useCampaignsNetwork();

  const [refetch, setRefetch] = useState(false);
  const [bulkIsOpen, setBulkIsOpen] = useState(false);
  // If the order fails to place, this prevents users from opening the modal and placing another order
  const [lockModal, setLockModal] = useState(false);
  const toggle = (): void => setBulkIsOpen((o) => !o);

  const [upsertBulkPurchase, { data }] = useMutation(
    upsertBulkPurchaseMutation,
    {
      onCompleted: () => {
        createNotification({
          message: 'Bulk Purchase Ordered',
          color: 'success',
          type: 'alert',
          action: 'MESSAGE',
        });
        toggle();
        setRefetch(true);
      },
      onError: (e) => {
        createNotification({
          message: `Bulk Purchase Failed: ${e.message}`,
          color: 'danger',
          type: 'alert',
          action: 'MESSAGE',
        });
        toggle();
        setLockModal(true);
        console.error(e);
      },
    }
  );

  const [
    fetchTierTimeout,
    { data: tierTimeoutData },
  ] = useLazyQuery(tierTimeoutQuery, { fetchPolicy: 'network-only' });

  const timeoutExpiration = moment(
    Date.now() + tierTimeoutData?.tierTimeout * 1000
  ).format('MMMM Do, hh:mm a');

  if (!user) return null;
  return (
    <>
      <Form
        initialValues={{
          newBulkPurchase: false,
          agentCredit: 0,
          requestedLeadCount: 0,
          estimatedLeadCount: 1,
          totalPrice: 0,
          priceTierID: '',
          priceTier: { name: '', price: 0, bulkPurchaseTimeout: 0 },
          regions: user?.network?.allowedStates,
          agreed: false,
          ageInt: { min: 0, max: 0 },
          householdInt: { min: 0, max: 0 },
          incomeInt: { min: 0, max: 0 },
          tierTimeout: null, // Redis returns -2 when ttl is not set
          failedOrder: false,
        }}
        validationSchema={yup.object().shape({
          requestedLeadCount: yup
            .number()
            .required('Required')
            .min(1, 'Must be greater than 0')
            .when(
              'priceTier.bulkPurchaseMax',
              (max: number, schema: yup.NumberSchema) =>
                schema.max(max, `Exceeds max bulk purchase limit of: ${max}`)
            )
            .when(
              'priceTier.bulkPurchaseMin',
              (min: number, schema: yup.NumberSchema) =>
                schema.min(min, `Does not meet miniumum quantity of: ${min}`)
            )
            .test(
              'is-decimal',
              'Lead count cannot contain decimals.',
              (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
            ),
          agreed: yup.boolean().oneOf([true], 'Please agree to terms.'),
          agentCredit: yup
            .number()
            .required('Required')
            .when('totalPrice', (total: number, schema: yup.NumberSchema) =>
              schema.min(total, 'Not Enough Credit')
            ),
          estimatedLeadCount: yup.number().min(1, 'No Available Leads'),
          priceTierID: yup
            .string()
            .when(
              'tierTimeout',
              (timeout: number, schema: yup.NumberSchema) => {
                if (timeout === -2 || timeout === null) {
                  return schema;
                }
                return schema.max(
                  0,
                  `Please change tier, cannot purchase from selected tier until ${timeoutExpiration}`
                );
              }
            ),
          failedOrder: yup.boolean().oneOf([false]),
          regions: yup
            .array()
            .nullable()
            .test('has-value', 'Required', (value) => {
              if (value?.length === 0) {
                return false;
              }
              if (value === null) {
                return false;
              }
              return true;
            }),
        })}
        onSubmit={(values) => {
          upsertBulkPurchase({
            variables: {
              input: {
                priceTierID: values.priceTierID,
                priceTier: values?.priceTier?.name,
                agentID: user?.agentID,
                requestedLeadCount: values.requestedLeadCount,
                priceTierTimeout: values.priceTier.bulkPurchaseTimeout,
                filters: filters(values),
                networkID,
              },
            },
          });
        }}
      >
        {({ values, setFieldValue, resetForm }) => {
          const tier = usePriceTier(values.priceTierID);
          useEffect(() => {
            setFieldValue('priceTier', tier);
            setFieldValue('agentCredit', user?.totalCredit / 100);
          }, [tier]);
          useEffect(() => {
            if (values.priceTierID) {
              fetchTierTimeout({
                variables: {
                  priceTierID: values.priceTierID,
                  networkID,
                  agentID: user?.agentID,
                },
              });
            }
          }, [values.priceTierID]);
          useEffect(() => {
            if (tierTimeoutData) {
              setFieldValue('tierTimeout', tierTimeoutData.tierTimeout);
            }
          }, [tierTimeoutData, values.priceTierID]);
          useEffect(() => {
            setFieldValue(
              'totalPrice',
              (bulkPrice(values.priceTier, values.requestedLeadCount) / 100) *
                values.requestedLeadCount
            );
          }, [values.requestedLeadCount]);
          useEffect(() => {
            if (data) {
              setFieldValue('newBulkPurchase', false);
              resetForm();
            }
            if (lockModal) {
              setFieldValue('failedOrder', true);
            }
          }, [data, lockModal]);
          return (
            <>
              <Row className="justify-content-end mr-1">
                <div
                  className={classNames('mb-2', {
                    'd-none': values.newBulkPurchase,
                  })}
                >
                  <Button
                    id={`${idPrefix}-new-button`}
                    size="lg"
                    color="primary"
                    onClick={() => setFieldValue('newBulkPurchase', true)}
                  >
                    Purchase Leads
                  </Button>
                </div>
              </Row>
              <Container>
                <Card
                  className={classNames('mb-2', {
                    'd-none': !values.newBulkPurchase,
                  })}
                >
                  <Row className="m-3">
                    <CardTitle
                      tag="h3"
                      className="d-flex justify-content-between align-items-center mb-0"
                    >
                      New Bulk Purchase
                    </CardTitle>
                    <div className="ml-auto">
                      <Button
                        id={`${idPrefix}-cancel-button`}
                        color="secondary"
                        type="reset"
                        onClick={() => setFieldValue('newBulkPurchase', false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Row>
                </Card>
              </Container>
              {values.newBulkPurchase ? (
                <PurchaseBulkLeads toggle={toggle} />
              ) : (
                <Table refresh={refetch} newBulkCount={newBulkCount} />
              )}
              <BulkPurchaseModal isOpen={bulkIsOpen} toggle={toggle} />
            </>
          );
        }}
      </Form>
    </>
  );
};

export default BulkPurchase;
