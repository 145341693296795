/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable promise/always-return */
import React, { useRef, useState } from 'react';
import Pagination, { PaginationContent } from '@availity/pagination';
import { Storage } from 'aws-amplify';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { FaDownload, FaSync, FaTimes } from 'react-icons/fa';
import { reports } from '@/graphql/queries';
import { useUser } from '@/hooks';
import ReportControls from './ReportControls';

interface ReportRowProps {
  fileName: string;
  url: string;
  cleanName: string;
  creationDate: number;
  status: string;
}
const ReportRow: React.SFC<ReportRowProps> = ({
  url,
  cleanName,
  creationDate,
  status,
}) => {
  const downloadLink = useRef<any>();

  const [link, setLink] = useState<any>(
    'https://i.ytimg.com/vi/j3GoK90M5aM/maxresdefault.jpg'
  );

  Storage.get(url)
    .then((data: any) => setLink(data))
    .catch((error) => console.error(error));

  const getStatus = () => {
    switch (status) {
      case 'FAILURE':
        return (
          <span>
            Failed <FaTimes className="text-danger" />
          </span>
        );
      case 'COMPLETE':
        return (
          <Button
            size="sm"
            title="Download Report"
            color="primary"
            className="ml-1"
            onClick={() => {
              downloadLink.current.click();
            }}
          >
            <FaDownload />
          </Button>
        );
      default:
        return (
          <span>
            Generating <FaSync className="loading-sync text-warning" />
          </span>
        );
    }
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {moment(creationDate).format('MMM Do YYYY LTS').toString()}
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">{cleanName}</div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {getStatus()}
          <a ref={downloadLink} href={link} download>
            &nbsp;
          </a>
        </div>
      </td>
    </tr>
  );
};

const Reports: React.SFC = () => {
  const { data, loading: reportLoading } = useQuery(reports);

  const {
    user: { agentID, corpID },
    loading,
  } = useUser();
  const history = useHistory();
  if (loading || reportLoading) {
    return null;
  }

  const { reports: reportData = [] } = data;

  return (
    <Pagination
      items={reportData.sort((a: ReportRowProps, b: ReportRowProps) => {
        if (a.creationDate > b.creationDate) {
          return -1;
        }
        if (a.creationDate < b.creationDate) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })}
      itemsPerPage={50}
    >
      <Card>
        <CardHeader>
          <CardTitle
            tag="h3"
            className="d-flex justify-content-between align-items-center mb-0"
          >
            Reports
          </CardTitle>
        </CardHeader>
        <CardBody>
          {corpID !== 'leadtopia' && <ReportControls agentID={agentID} />}
          <Row className="my-2">
            <Col>
              Generate lead reports{' '}
              <a
                href="#"
                onClick={() => {
                  history.push('/leads');
                }}
              >
                here
              </a>
            </Col>
          </Row>
          <Table striped size="sm" hover>
            <thead>
              <tr>
                <th>Time of Creation</th>
                <th>Report Name</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <PaginationContent
              itemKey="fileName"
              component={ReportRow}
              containerTag="tbody"
            />
          </Table>
        </CardBody>
      </Card>
    </Pagination>
  );
};

export default Reports;
