/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
} from 'reactstrap';
import { useFormikContext, FieldArray } from 'formik';
import { FaPlus } from 'react-icons/fa';
import {
  AgentSelect,
  DestinationSelect,
  MyAgentDestinationsSelect,
} from '@/components';
import { useUser } from '@/hooks';
import DestinationRow from './DestinationRow';

const Destinations: React.SFC = () => {
  const { user } = useUser();
  const { values, setFieldValue } = useFormikContext<{
    destinations: any[];
  }>();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((o) => !o);

  const agentDestination = values.destinations.find(
    (d) => d.agentID !== user.agentID
  );

  return (
    <FieldArray
      name="destinations"
      render={({ push, remove, replace }) => (
        <>
          <div className="d-flex flex-row mb-3">
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              id="addDestination-dropdown"
            >
              <DropdownToggle>
                <FaPlus className="mr-2" /> Add Destination
              </DropdownToggle>
              <DropdownMenu className="p-3" style={{ minWidth: 500 }}>
                <DestinationSelect
                  name="tempAgentDestinations"
                  label="My Destinations"
                  hideSelectedOptions
                  filterOption={({ data }: any) => {
                    return values.destinations.every(
                      (v) => v.uiID !== data.uiID
                    );
                  }}
                  onChange={(i: any) => {
                    push({
                      ...i,
                      label: i.destinationName || i.label,
                    });
                    setFieldValue('tempAgentDestinations', null);
                    toggleDropdown();
                  }}
                />
                <AgentSelect
                  name="tempAgentDestinations"
                  label="Agent Destinations"
                  placeholder="Search Across Agents..."
                  hideSelectedOptions
                  filterOption={({ data }: any) => {
                    const isNotSelected = values.destinations.every(
                      (v) => v.uiID !== data.uiID
                    );
                    if (
                      !user.flags.allowProportionalDestinations &&
                      agentDestination
                    ) {
                      if (user.flags.allowSameDestination) {
                        return true;
                      }
                      return (
                        data.agentID === agentDestination.agentID &&
                        isNotSelected
                      );
                    }

                    return isNotSelected;
                  }}
                  onChange={(i: any) => {
                    push(i);
                    setFieldValue('tempAgentDestinations', null);
                    toggleDropdown();
                  }}
                />
                {user.flags.allowMyAgentEmails ? (
                  <MyAgentDestinationsSelect
                    name="tempAgentDestinations"
                    label="My Agent Email Destinations"
                    hideSelectedOptions
                    filterOption={({ data }: any) => {
                      return values.destinations.every(
                        (v) => v.uiID !== data.uiID
                      );
                    }}
                    onChange={(i: any) => {
                      push({
                        ...i,
                        label: i.destinationName || i.label,
                      });
                      setFieldValue('tempAgentDestinations', null);
                      toggleDropdown();
                    }}
                  />
                ) : null}
                <Button
                  color="secondary"
                  className="float-right"
                  onClick={toggleDropdown}
                >
                  Close
                </Button>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div>
            {values.destinations.map((d, index) => (
              <DestinationRow
                key={d.uiID}
                remove={() => remove(index)}
                {...d}
              />
            ))}
          </div>
        </>
      )}
    />
  );
};

export default Destinations;
