import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapse, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { CopyButton } from '@/components';
import LeadDetailsTabPane from './tabs/LeadDetailsTab';
import LeadRefundTab from './tabs/LeadRefundTab';
import LTDetailsTab from './tabs/LTDetailsTab';

interface RowDetailsDropdownProps {
  isOpen: boolean;
  lead: any;
  colSpan: number;
  liveTransferOnlyView: boolean;
}

const RowDetailsDropdown: React.FC<RowDetailsDropdownProps> = ({
  isOpen,
  lead,
  colSpan,
  liveTransferOnlyView,
}) => {
  const [delayExpanded, setDelayExpanded] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [activeTab, setActiveTab] = useState('details');

  return (
    <tr
      className={classNames('no-hover', {
        'd-none': !isOpen && !delayExpanded && !isOpening,
      })}
    >
      <td colSpan={colSpan} className="border-0">
        <Collapse
          isOpen={isOpen}
          onEntering={() => setIsOpening(true)}
          onEntered={() => {
            setDelayExpanded(true);
            setIsOpening(false);
          }}
          onExited={() => setDelayExpanded(false)}
          style={{
            width: 'calc(100vw - 126px)',
            minHeight: delayExpanded && isOpen ? 280 : 'auto',
          }}
        >
          <div className="h4 d-flex py-2">
            <div className="d-flex align-items-center">
              <span className="text-muted mr-2">Lead:</span>
              <span className="text-normal">{lead.name || 'No Data'}</span>
              <CopyButton className="ml-2" content={lead.leadID} />
            </div>
            {lead.leadType === 'agentDirect' ? (
              <div className="ml-5 d-flex align-items-center">
                <span className="text-muted mr-2">Lead Source:</span>
                <span className="text-normal">
                  {lead.vendorLayer.vendorSourceCampaign}
                </span>
                <CopyButton
                  className="ml-2"
                  content={lead.vendorLayer.layerID}
                />
              </div>
            ) : (
              <>
                <div className="ml-5 d-flex align-items-center">
                  <span className="text-muted mr-2">Tier:</span>
                  <span className="text-normal">{lead.tier.name}</span>
                  <CopyButton
                    className="ml-2"
                    content={lead.tier.priceTierID}
                  />
                </div>
                <div className="ml-5 d-flex align-items-center">
                  <span className="text-muted mr-2">Campaign:</span>
                  <span className="text-normal">{lead.campaignName}</span>
                  <CopyButton className="ml-2" content={lead.campaignID} />
                </div>
              </>
            )}
          </div>
          <Nav tabs className="cursor-pointer">
            <NavItem>
              <NavLink
                active={activeTab === 'details'}
                onClick={() => setActiveTab('details')}
              >
                Details
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                active={activeTab === 'destinations'}
                onClick={() => setActiveTab('destinations')}
              >
                Destinations
              </NavLink>
            </NavItem> */}
            {lead.liveTransferSource === 'callerready' ? (
              <NavLink
                active={activeTab === 'ltdetails'}
                onClick={() => setActiveTab('ltdetails')}
              >
                Live Transfer Details
              </NavLink>
            ) : null}
            {lead.refundStatus && lead.refundStatus !== '' && (
              <NavItem>
                <NavLink
                  active={activeTab === 'refund'}
                  onClick={() => setActiveTab('refund')}
                >
                  Refund
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <LeadDetailsTabPane
              tabId="details"
              lead={lead}
              active={isOpen && activeTab === 'details'}
            />
            <LTDetailsTab
              tabId="ltdetails"
              lead={lead}
              active={isOpen && activeTab === 'ltdetails'}
            />
            <LeadRefundTab tabId="refund" lead={lead} />
          </TabContent>
        </Collapse>
      </td>
    </tr>
  );
};

export default RowDetailsDropdown;
