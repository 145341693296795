import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
  Col,
  Row,
  Button,
  Label,
} from 'reactstrap';
import { RadioGroup, Radio, CheckboxGroup, Checkbox } from '@availity/form';
import { sizeOptions } from '@/utils/leadTableHelpers';
import { useUser } from '@/hooks';

interface TableOptionsModalProps extends Omit<ModalProps, 'toggle'> {
  availableColumns?: any[];
}

const TableOptionsModal: React.SFC<TableOptionsModalProps> = ({
  isOpen,
  toggle,
  availableColumns,
}) => {
  const {
    user: { role, network },
  } = useUser();
  const corpID = network?.corpID;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Table Options</ModalHeader>
      <ModalBody tag={Row} noGutters>
        <Col
          xs={{
            size: 5,
          }}
        >
          <RadioGroup
            name="size"
            label={
              <Label className="border-bottom w-100 mr-3">Page size</Label>
            }
            groupClassName="bg-dark text-white"
          >
            {sizeOptions.map((o) => (
              <Radio value={o.value} label={o.label} />
            ))}
          </RadioGroup>
        </Col>
        {availableColumns && (
          <Col
            xs={{
              size: 5,
              offset: 1,
            }}
          >
            <CheckboxGroup
              name="columns"
              label={<Label className="border-bottom w-100">Columns</Label>}
              for="columns"
              groupClassName="bg-dark text-white d-flex flex-column"
            >
              {availableColumns
                .filter((aC) => !aC.visible || aC.visible(role, corpID))
                .map((c) => (
                  <Checkbox
                    name={c.dataField}
                    key={c.dataField}
                    value={c.dataField}
                    label={c.displayName}
                  />
                ))}
            </CheckboxGroup>
          </Col>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="float-right" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TableOptionsModal;
