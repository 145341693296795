import React from 'react';
import classnames from 'classnames';
import { DocumentNode } from 'graphql';
import { SelectField } from '@availity/select';
import { InputGroup } from 'reactstrap';
import { useQuery } from '@apollo/react-hooks';
import { SelectFieldProps } from '@availity/select/typings/SelectField';

interface FilterProps<T> extends SelectFieldProps<T> {
  query: DocumentNode;
  className: string;
  queryName?: string;
  variables: any;
  groupClassName?: string;
}

const FilterSelect: React.SFC<FilterProps<any>> = ({
  query,
  queryName,
  groupClassName,
  variables,
  ...props
}) => {
  const { data } = useQuery(query, {
    variables,
  });

  return (
    <div className="d-flex align-items-center">
      <InputGroup
        className={classnames(
          'd-flex justify-content-end w-auto',
          groupClassName
        )}
      >
        <SelectField
          {...props}
          isClearable
          options={
            data?.agg
              ?.map((l: any) => (l.node !== undefined ? l.node : l))
              .filter((l) => !!l) ||
            data?.[queryName!].items.filter((l) => !!l) ||
            []
          }
        />
      </InputGroup>
    </div>
  );
};

export default FilterSelect;
