import React from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { currentAgentQuery } from '@/graphql/queries';
import { updateAutoFundSettings } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { MoneyField } from '@/components';

interface AutoFundSettingsProps {
  amount: number;
  enabled: boolean;
  threshold: number;
  viewLoading: boolean;
  agentID: string;
}

const AutoFundSettings: React.FunctionComponent<AutoFundSettingsProps> = ({
  amount,
  enabled,
  threshold,
  viewLoading,
  agentID,
}) => {
  const { create: createNotification } = useNotifications();

  const [
    saveAutoFundSettings,
    { loading: updating },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useMutation(updateAutoFundSettings, {
    update(cache, { data: { accountUpdate } }) {
      const { userCurrent, account } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          account,
          userCurrent: {
            ...userCurrent,
            autoFundSettings: accountUpdate.autoFundSettings,
          },
        },
      });
    },
    onCompleted: () => {
      createNotification({
        color: 'success',
        type: 'alert',
        action: 'MESSAGE',
        message: 'Auto-fund settings saved.',
      });
    },
    onError: () =>
      createNotification({
        color: 'danger',
        type: 'alert',
        action: 'MESSAGE',
        message: 'Failed to save auto-fund settings. Please try again later.',
      }),
  });

  return (
    <Form
      initialValues={{
        threshold: threshold || Vars.AUTO_FUND_MINIMUM_THRESHOLD!,
        amount: amount || Vars.AUTO_FUND_MINIMUM_AMOUNT!,
        enabled: enabled || false,
      }}
      validationSchema={yup.object().shape({
        enabled: yup.bool(),
        amount: yup
          .number()
          .when(['enabled'], (isEnabled: boolean, schema: yup.NumberSchema) => {
            if (isEnabled) {
              return schema.min(
                Vars.AUTO_FUND_MINIMUM_AMOUNT,
                `The minimum amount is ${formatCurrencyForDisplay(
                  Vars.AUTO_FUND_MINIMUM_AMOUNT!
                )}.`
              );
            }
            return schema;
          }),
        threshold: yup
          .number()
          .when(['enabled'], (isEnabled: boolean, schema: yup.NumberSchema) => {
            if (isEnabled) {
              return schema.min(
                Vars.AUTO_FUND_MINIMUM_THRESHOLD,
                `The minimum threshold is ${formatCurrencyForDisplay(
                  Vars.AUTO_FUND_MINIMUM_THRESHOLD!
                )}.`
              );
            }
            return schema;
          }),
      })}
      onSubmit={(values) => {
        saveAutoFundSettings({
          variables: {
            input: {
              agentID,
              autoFundSettings: values,
            },
          },
        });
      }}
    >
      {({ setFieldValue, values, handleReset }) => (
        <Card className="mt-2">
          <CardHeader className="d-flex justify-content-between">
            <CardTitle
              tag="h5"
              className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
            >
              <span>Auto-Fund Settings</span>
              {updating ? (
                <FaSync className="loading-sync" size={24} />
              ) : (
                <ButtonGroup>
                  <Button
                    disabled={viewLoading}
                    color="dark"
                    onClick={(e) => handleReset(e)}
                  >
                    Reset
                  </Button>
                  <Button color="primary" disabled={viewLoading} type="submit">
                    Save
                  </Button>
                </ButtonGroup>
              )}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <table>
              <thead>
                <tr>
                  <td style={{ width: 240 }} />
                  <td style={{ width: 500 }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: 12 }}>
                    <Switch
                      checked={values.enabled}
                      disabled={viewLoading}
                      onChange={() => setFieldValue('enabled', !values.enabled)}
                    />
                  </td>
                  <td style={{ padding: 12 }}>
                    Enable automatic funding through your card on file.
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 12 }}>
                    <MoneyField
                      name="amount"
                      minimum={Vars.AUTO_FUND_MINIMUM_AMOUNT}
                      size="md"
                    />
                  </td>
                  <td style={{ padding: 12 }}>
                    <i>
                      This amount will be added to your credit when an automatic
                      funding transaction occurs. The minimum amount is{' '}
                      {formatCurrencyForDisplay(Vars.AUTO_FUND_MINIMUM_AMOUNT!)}
                    </i>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 12 }}>
                    <MoneyField
                      name="threshold"
                      minimum={Vars.AUTO_FUND_MINIMUM_AMOUNT}
                      size="md"
                    />
                  </td>
                  <td style={{ padding: 12 }}>
                    <i>
                      When your credit falls below this amount, an automatic
                      funding transaction will be triggered.
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      )}
    </Form>
  );
};

export default AutoFundSettings;
