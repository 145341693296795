import React from 'react';

import { Col, Card, CardBody } from 'reactstrap';

interface EmailDestinationProps {
  name: string;
  email: string;
  edit?: () => void;
}

const EmailListItem: React.FC<EmailDestinationProps> = ({
  name,
  email,
  edit,
}) => {
  return (
    <Col xs={6} className="mb-2" onClick={edit}>
      <Card
        className="email-list-item cursor-pointer px-2 pt-1"
        style={{
          borderLeft: '5px solid #f0ad4e',
          backgroundColor: '#404040',
        }}
      >
        <span className="font-weight-bold">{name}</span>
        <CardBody className="py-2 px-0">{email}</CardBody>
      </Card>
    </Col>
  );
};

export default EmailListItem;
