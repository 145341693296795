import { useQuery } from '@apollo/react-hooks';
import { currentAccountQuery } from '@/graphql/queries';
import { QueryResult } from '@apollo/react-common';

type BucketType = {
  //agentID:string
  creditBucketID: string;
  balance: number;
  description: string;
};

type AccountType = {
  card: {
    id: string;
    brand: string;
    exp_month: number;
    exp_year: number;
    address_zip: string;
    customer: string;
    last4: string;
    name?: string;
  };
  charges: any[];
};

interface UseAccountResult extends Omit<QueryResult, 'data'> {
  account?: AccountType;
  buckets: BucketType[];
}

export default (): UseAccountResult => {
  const { data, loading, error, ...rest } = useQuery(currentAccountQuery);

  return {
    account: data?.account,
    buckets: [
      {
        description: 'Contributed',
        balance: 233,
        creditBucketID: 'dfgdfg:contributed',
      },
      {
        description: 'Dorne Region',
        balance: 5633,
        creditBucketID: 'fghfghf:owned',
      },
      {
        description: 'WPB Team',
        balance: 343,
        creditBucketID: 'fghfhfg:owned',
      },
      {
        description: 'Monopoly',
        balance: 2133,
        creditBucketID: 'dfgdfgdfg:monopoly',
      },
    ],
    loading,
    error,
    ...rest,
  };
};
