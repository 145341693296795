import gql from 'graphql-tag';
import { DestinationFragment } from '@/graphql/fragments';

export default gql`
  mutation updateDestinationMutation(
    $input: RelayUpdateDestinationDestinationInput!
  ) {
    updateDestination(input: $input) {
      ...DestinationFragment
    }
  }
  ${DestinationFragment}
`;
