import React, { useState } from 'react';
import { Input, InputProps } from 'reactstrap';

const MoneyInput: React.FunctionComponent<
  Omit<InputProps, 'onChange'> & {
    maximum?: number;
    minimum?: number;
    touched?: boolean;
    onChange: (value: number) => void;
    value: number;
  }
> = ({
  minimum = 0,
  maximum = 99999999999999999,
  onChange,
  value,
  touched,
  ...extraProps
}) => {
  const [displayValue, updateDisplayValue] = useState<string>(
    (value / 100).toFixed(2)
  );
  const [useControlledValue, setUseControlledValue] = useState(true);

  const setDisplayValue = (nextDisplayValue: string, maximum: number): void => {
    let nextvalue = displayValue;
    if (
      nextDisplayValue !== displayValue &&
      /^-?\d*(?:\.\d*)?$/.test(nextDisplayValue)
    ) {
      if (nextDisplayValue === '') {
        nextvalue = '';
        onChange(0);
      } else {
        const [dollars, cents] = nextDisplayValue.split('.');

        if (typeof cents === 'string' && cents.length > 2) {
          const previousCents = displayValue.split('.')[1];

          if (cents.slice(1) === previousCents) {
            nextvalue = `${dollars}.${cents[0]}`;
          } else {
            nextvalue = `${dollars}.${cents.slice(0, 2)}`;
          }
        } else {
          nextvalue = nextDisplayValue;
        }
        const nextValue = parseFloat(nextvalue) * 100;

        if (Number.isInteger(nextValue)) {
          onChange(nextValue);
        }
      }
      updateDisplayValue(nextvalue);
    }
  };

  return (
    <Input
      {...extraProps}
      onBlur={() => {
        setUseControlledValue(true);
        setDisplayValue((value / 100).toFixed(2));
      }}
      onChange={(event) => setDisplayValue(event.target.value)}
      onFocus={() => {
        setUseControlledValue(false);
        setDisplayValue((value / 100).toFixed(2));
      }}
      style={
        value < minimum ||
        (value > maximum && (touched === undefined || touched === true))
          ? {
              borderColor: '#dc3545',
            }
          : {}
      }
      type="number"
      value={useControlledValue ? (value / 100).toFixed(2) : displayValue}
    />
  );
};

export default MoneyInput;
