import gql from 'graphql-tag';
import { CampaignFragment } from '@/graphql/fragments';

export default gql`
  mutation updateCampaignMutation($input: RelayUpdateCampaignCampaignInput!) {
    updateCampaign(input: $input) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`;
