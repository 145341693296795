import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import {
  FaEllipsisV,
  FaCaretSquareDown,
  FaCaretSquareUp,
} from 'react-icons/fa';
import { useLocation } from 'react-router';
import RowDetailsDropdown from './RowDetailsDropdown';

export interface ColumnConfig {
  dataField: string;
  displayName: string;
  formatter?: (value: any) => any;
}

export interface TableRowProps {
  [key: string]: any;
  columns: ColumnConfig[];
  sortKey: string;
  descending: boolean;
  setSelectedLeadID: (leadID: string) => void;
}

const getStatusBadge = (lead: any) => {
  if (!lead.refundStatus) {
    return (
      <Badge style={{ fontSize: 13 }} pill color="primary">
        Purchased
      </Badge>
    );
  }

  if (lead.refundStatus === 'pending') {
    return (
      <Badge style={{ fontSize: 13 }} pill color="warning">
        Pending Refund
      </Badge>
    );
  }

  return (
    <Badge style={{ fontSize: 13 }} pill color="danger">
      Denied Refund
    </Badge>
  );
};

const getActionButton = (lead: any, setSelectedLeadID: (i: string) => void) => {
  const disabled =
    !lead.refundEligible ||
    lead.refundStatus === 'pending' ||
    lead.refundStatus === 'completed';

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle
        id={`lead-action-togger-${lead.leadID}`}
        size="sm"
        color="primary"
        disabled={disabled}
        className={classNames('py-1 px-1 rounded')}
      >
        <FaEllipsisV fontSize={16} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setSelectedLeadID(lead.leadID)}>
          Refund
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

const TableRow: React.FunctionComponent<TableRowProps> = ({
  columns,
  sortKey,
  descending,
  setSelectedLeadID,
  index,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const liveTransferOnlyView = location.pathname === '/live-transfers';
  return (
    <>
      <tr
        className={classNames('lead-table-row', 'cursor-pointer', {
          // 'bg-secondary': index % 2 !== 0,
        })}
        onClick={() => setExpanded((o) => !o)}
      >
        <td className="py-2 no-wrap-centered">
          {getActionButton(rest, setSelectedLeadID)}
        </td>
        <td className="align-middle py-2 px-4 no-wrap-centered">
          {getStatusBadge(rest)}
        </td>
        {columns.map((column, key) => {
          const { dataField, formatter } = column;

          return (
            <td
              className="align-middle py-2 no-wrap-centered"
              key={`${key}-${dataField}`}
            >
              {formatter !== undefined
                ? formatter(rest[dataField])
                : rest[dataField]}
            </td>
          );
        })}
      </tr>
      <RowDetailsDropdown
        isOpen={expanded}
        lead={rest}
        colSpan={columns.length + 2}
        liveTransferOnlyView={liveTransferOnlyView}
      />
    </>
  );
};

export default TableRow;
