import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Container,
  Card,
  CardTitle,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';
import { SelectField } from '@availity/select';
import * as yup from 'yup';
import { ErrorMessage } from 'formik';
import { Form, Field } from '@availity/form';
import { FaInfoCircle } from 'react-icons/fa';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  vendors as vendorsQuery,
  nodeQuery,
  vendorLayers,
} from '@/graphql/queries';
import { VendorLayerFragment } from '@/graphql/fragments';
import { upsertVendorLayer } from '@/graphql/mutations';
import { useUser, usePageLoader, useNotifications } from '@/hooks';
import { MS_PER_DAY } from '@/utils';
import { BackButton } from '@/components';
import Destinations from '../Destinations';
import { QualityCard, CleanCard } from './components';

const DEFAULTS = {
  vendorSourceCampaign: '',
  destinations: [],
  passDuplicates: false,
  leadQualityTier: 'enhance_profile_check_phone',
  vendorPrice: 0,
  rejectDisconnected: true,
  rejectDuplicates: true,
  vendor: undefined,
  blockDuplicates: true,
  acceptsTermsAndConditions: false,
  phoneDupeMode: 'forever',
  destIds: [],
  enhanceProfile: true,
  rejectDNC: true,
  minimumRefresh: 30 * MS_PER_DAY,
  phoneDupeDays: 30,
};

const DetailView: React.SFC<RouteComponentProps<{
  id: string;
}>> = ({ match, history }) => {
  const { start, complete } = usePageLoader();
  const { create: creatNotification } = useNotifications();
  const { data: vendorLayerData, loading: loadingVendorLayer } = useQuery(
    nodeQuery,
    {
      variables: {
        id: match.params.id,
      },
      skip: match.params.id === 'new',
    }
  );
  const vendorLayer = vendorLayerData?.node;
  const isNew = !vendorLayer;
  const [createOrUpdate] = useMutation(upsertVendorLayer, {
    onCompleted: () => {
      creatNotification({
        message: `Successfully ${
          isNew ? 'created' : 'updated'
        } Vendor Campaign`,
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
    },
    update: (cache, { data: { upsertVendorLayer } }) => {
      if (isNew) {
        try {
          const data = cache.readQuery({
            query: vendorLayers,
          });
          cache.writeQuery({
            query: vendorLayers,
            data: {
              vendorLayers: [upsertVendorLayer, ...data.vendorLayers],
            },
          });
        } catch (_) {
          // Cache isn't populated yet
        }
      } else {
        cache.writeFragment({
          id: vendorLayer.id,
          fragment: VendorLayerFragment,
          data: upsertVendorLayer,
        });
      }

      history.push('/vendors');
    },
    onError: () => {
      creatNotification({
        message: `Failed to ${isNew ? 'create' : 'update'} Vendor Campaign`,
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });
    },
  });
  const { user, loading: loadingUser } = useUser();

  useEffect(() => {
    if (loadingUser) {
      start();

      return () => complete();
    }
  }, [loadingUser, complete, start]);

  if (loadingUser || loadingVendorLayer) {
    return null;
  }

  return (
    <Container>
      <Form<{
        vendorSourceCampaign: string;
        passDuplicates: string;
        leadQualityTier: string;
        defaultLeadPrice: number;
        blockDuplicates: boolean;
        phoneDupeMode: string;
        destinations: string[];
        vendorPrice: string;
        vendor?: {
          vendorID: string;
          vendorName: string;
          flags: any;
        };
        destIds: any[];
        enhanceProfile: boolean;
        rejectDisconnected: boolean;
        rejectDuplicates: boolean;
        rejectDNC: boolean;
        minimumRefresh: number;
        phoneDupeDays: number;
      }>
        initialValues={
          isNew
            ? DEFAULTS
            : {
                ...DEFAULTS,
                searchText: '',
                vendorSourceCampaign: vendorLayer.vendorSourceCampaign,
                leadQualityTier: vendorLayer.leadQualityTier,
                vendorPrice: vendorLayer.vendorPrice.toFixed(2),
                vendor: vendorLayer.vendor,
                blockDuplicates: vendorLayer.blockDuplicates,
                destinations: vendorLayer.destinations,
                passDuplicates: vendorLayer.passDuplicates,
                enhanceProfile: vendorLayer.enhanceProfile,
                rejectDisconnected: vendorLayer.rejectDisconnected,
                rejectDuplicates: vendorLayer.rejectDuplicates,
                rejectDNC: vendorLayer.rejectDNC,
                phoneDupeDays: vendorLayer.minimumRefresh
                  ? vendorLayer.minimumRefresh / MS_PER_DAY
                  : user?.corpID === 'usha'
                  ? 30
                  : 5,
              }
        }
        onSubmit={(values) => {
          start();
          createOrUpdate({
            variables: {
              input: {
                destinations: values.destinations.map((d: any) => d.uiID),
                vendorID: values.vendor!.vendorID,
                layerID: vendorLayer?.layerID, // todo - get from nodeId
                blockDisconnected: values.leadQualityTier === 'check_phone',
                blockDuplicates: values.blockDuplicates,
                enhanceProfile: values.enhanceProfile,
                minimumRefresh: values.phoneDupeDays * MS_PER_DAY,
                passDuplicates: values.passDuplicates,
                vendorPrice: parseFloat(values.vendorPrice), // todo - make sure in decimal?
                vendorSourceCampaign: values.vendorSourceCampaign,
                rejectDisconnected: values.rejectDisconnected,
                rejectDuplicates: values.rejectDuplicates,
                rejectDNC: values.rejectDNC,
              },
            },
          });
        }}
        validationSchema={yup.object().shape({
          vendor: yup.mixed().required('This field is required.'),
          vendorSourceCampaign: yup
            .string()
            .max(36, 'Vendor campaign name needs to be less than 36 characters')
            .required('This field is required.')
            .matches(
              new RegExp(/^[a-zA-Z0-9_-\s]*$/),
              'Vendor campaign names may only contain letters, numbers, spaces, dashes, and underscores'
            ),
          vendorPrice: yup
            .number()
            .min(0, 'The default lead price must be positive.'),
          leadQualityTier: yup.string().required('This field is required.'),
          // acceptsTermsAndConditions: yup
          //   .boolean()
          //   .when(
          //     ['rejectDisconnected', 'rejectDuplicates'],
          //     (
          //       rejectDisconnected: boolean,
          //       rejectDuplicates: boolean,
          //       schema: yup.BooleanSchema
          //     ) => {
          //       if (rejectDisconnected || rejectDuplicates) {
          //         return schema.oneOf(
          //           [true],
          //           'You must accept the terms and conditions.'
          //         );
          //       }

          //       return schema;
          //     }
          //   ),
          destinations: yup
            .array()
            .of(yup.object())
            .test(
              'mustBeOfSize',
              'You must have at least 1 destination',
              (array: any[] = []) => {
                if (array.length === 0) {
                  return false;
                }
                return true;
              }
            ),
        })}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Row>
            <Col xs={12}>
              <Card className="mb-2">
                <CardHeader className="d-flex align-items-center">
                  <BackButton className="mr-2" path="/vendors" />
                  <CardTitle tag="h5" className="h4 mb-0 py-2">
                    {vendorLayer?.id
                      ? vendorLayer.vendorSourceCampaign
                      : 'New Vendor Campaign'}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col xs={12}>
              <Card body className="mb-3">
                <CardTitle tag="h4" className="lead text-uppercase">
                  Connect to a Lead Vendor
                </CardTitle>
                <Row noGutters>
                  <Col xs={12}>
                    <SelectField
                      name="vendor"
                      options={user.allowedVendors || []}
                      labelKey="vendorName"
                      valueKey="id"
                      raw
                      getOptionLabel={({ vendorName, vendorID }) => (
                        <>
                          {vendorName} <small>({vendorID})</small>
                        </>
                      )}
                      label="Vendor"
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      name="vendorSourceCampaign"
                      label="Vendor Campaign Name"
                      type="text"
                      helpMessage="This is a descriptive name for the leads being generated by
                    your vendor. Eg: Shared West Coast"
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      name="vendorPrice"
                      label="Default Lead Price"
                      type="number"
                      onBlur={({ target: { value } }) => {
                        setFieldValue(
                          'vendorPrice',
                          parseFloat(value).toFixed(2)
                        );
                        setFieldTouched('vendorPrice', true, true);
                      }}
                      prepend="$"
                      helpMessage="If no price is sent with a lead sent through this campaign, this is the price that will be given to it."
                    />
                    {values.vendor?.flags?.sendsPricesWithLeads && (
                      <p className="text-muted d-flex align-items-center">
                        <FaInfoCircle className="text-warning mr-2" />{' '}
                        {values.vendor!.vendorName} is known to send prices with
                        leads. In this case, the default lead price will be
                        overwritten.
                      </p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <QualityCard />
            </Col>
            <Col xs={6}>
              <CleanCard />
            </Col>
            <Col xs={12}>
              <Card body className="my-3">
                <CardTitle tag="h4" className="lead text-uppercase">
                  Deliver your leads
                </CardTitle>
                <Destinations />
                <div className="text-danger">
                  <ErrorMessage name="destinations" />
                </div>
              </Card>
            </Col>
            {/* {values.vendor && user.flags.allowBrokerRejects && (
              <Col xs={12}>
                <Card body className="mb-3">
                  <CardTitle tag="h4" className="lead text-uppercase">
                    Rejects Leads
                  </CardTitle>
                  <FormText>
                    Once a lead has been rejected it can no longer be delivered
                    to you. Reporting on rejected leads can be found in the
                    marketplace.
                  </FormText>
                  <Field
                    name="rejectDisconnected"
                    type="checkbox"
                    disabled={!values.vendor!.flags.allowDisconnectedRejects}
                    groupClassName="mb-2"
                    label={
                      <span className="d-flex align-items-center">
                        {values.vendor!.flags.allowDisconnectedRejects ? (
                          <FaInfoCircle className="text-warning mr-1" />
                        ) : (
                          <FaBan className="text-danger mr-1" />
                        )}
                        {values.vendor!.vendorName}{' '}
                        {values.vendor!.flags.allowDisconnectedRejects
                          ? 'may'
                          : 'does not'}{' '}
                        allow lead rejection based on the phone connection
                        status.
                      </span>
                    }
                  />
                  <Field
                    groupClassName="mb-2"
                    name="rejectDuplicates"
                    type="checkbox"
                    disabled={!values.vendor!.flags.allowDuplicateRejects}
                    label={
                      <span className="d-flex align-items-center">
                        {values.vendor!.flags.allowDuplicateRejects ? (
                          <FaInfoCircle className="text-warning mr-1" />
                        ) : (
                          <FaBan className="text-danger mr-1" />
                        )}
                        {values.vendor!.vendorName}{' '}
                        {values.vendor!.flags.allowDuplicateRejects
                          ? 'may'
                          : 'does not'}{' '}
                        allow lead rejection based on the phone duplicate
                        status.
                      </span>
                    }
                  />
                  <Field
                    name="acceptsTermsAndConditions"
                    groupClassName="align-items-start mb-2"
                    className="mt-1"
                    label='By turning on lead rejection, you are instructing the system to send an HTTP 400 status code with an error message back to your vendor. Somne vendors refer to this as "Cancel Logic". The vendor will recieve a response with the message "duplicate" for duplicate rejects and "disconnected - no append" for disconnected rejects.'
                    type="checkbox"
                  />
                </Card>
              </Col>
            )} */}
            <Col xs={12} className="mb-3">
              <Button color="primary" className="float-right" type="submit">
                {isNew ? 'Create' : 'Update'}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
};

export default DetailView;
