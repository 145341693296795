import gql from 'graphql-tag';

const AnalyticsFragment = gql`
  fragment AnalyticsFragment on AnalyticsUrl {
    __typename
    url
    timestamp
  }
`;
export default AnalyticsFragment;
