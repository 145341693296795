import moment from 'moment';

import { SpendingPeriod } from '@sonic-web-dev/types';
import formatCurrencyForDisplay from '../MyAgents/Utils';

export const getProgressColor = (
  progress: number,
  overwrite?: boolean
): string => {
  if (overwrite) {
    return '#444444';
  }
  if (progress === 100) {
    return '#004085';
  }
  if (progress > 60) {
    return '#ffc107';
  }

  return '#28a745';
};

export function formatPhoneNumber(phoneNumberString: string): string | null {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

const wrapNumber = (number, wrap) =>
  number < 0 ? (wrap + number) % wrap : number % wrap;

export const localizeSpendingPeriod = (
  spendingPeriod: any,
  utcOffsetMinutes: number
): any => {
  const nextSpendingPeriod = {
    ...spendingPeriod,
    endTime: wrapNumber(
      (spendingPeriod?.endTime || 0) + utcOffsetMinutes,
      1440
    ),
    startTime: (spendingPeriod?.startTime || 0) + utcOffsetMinutes,
  };

  if (
    nextSpendingPeriod.startTime >= 1440 ||
    nextSpendingPeriod.startTime < 0
  ) {
    let dowSun;
    let dowMon;
    let dowTue;
    let dowWed;
    let dowThu;
    let dowFri;
    let dowSat;

    if (nextSpendingPeriod.startTime >= 1440) {
      [dowSun, dowMon, dowTue, dowWed, dowThu, dowFri, dowSat] = [
        spendingPeriod.dowSat,
        spendingPeriod.dowSun,
        spendingPeriod.dowMon,
        spendingPeriod.dowTue,
        spendingPeriod.dowWed,
        spendingPeriod.dowThu,
        spendingPeriod.dowFri,
      ];
    } else {
      [dowSun, dowMon, dowTue, dowWed, dowThu, dowFri, dowSat] = [
        spendingPeriod.dowMon,
        spendingPeriod.dowTue,
        spendingPeriod.dowWed,
        spendingPeriod.dowThu,
        spendingPeriod.dowFri,
        spendingPeriod.dowSat,
        spendingPeriod.dowSun,
      ];
    }
    nextSpendingPeriod.dowSun = dowSun;
    nextSpendingPeriod.dowMon = dowMon;
    nextSpendingPeriod.dowTue = dowTue;
    nextSpendingPeriod.dowWed = dowWed;
    nextSpendingPeriod.dowThu = dowThu;
    nextSpendingPeriod.dowFri = dowFri;
    nextSpendingPeriod.dowSat = dowSat;
    nextSpendingPeriod.startTime = wrapNumber(
      nextSpendingPeriod.startTime,
      1440
    );
  }
  return nextSpendingPeriod;
};
export const deLocalizeSpendingPeriod = (
  spendingPeriod: SpendingPeriod,
  utcOffsetMinutes: any
): any => localizeSpendingPeriod(spendingPeriod, -utcOffsetMinutes);

export const getProgress = (initialPeriod, now) => {
  const {
    startTime,
    endTime,
    dowFri,
    dowMon,
    dowSun,
    dowSat,
    dowThu,
    dowTue,
    dowWed,
    lastPurchaseEpoch,
    dailySpendLimit,
    lastPurchasePeriodSpend,
  } = initialPeriod;
  const startOfToday = moment().startOf('day');
  const startMoment = startOfToday.clone().add(startTime);
  const endMoment = startOfToday.clone().add(endTime);
  const twoDays = endTime <= startTime;
  let scheduledDOW;

  // The spending period cuts across two days
  if (twoDays) {
    // It's currently the second of the two days
    if (now.isBefore(endMoment, 'minutes')) {
      startMoment.subtract(1, 'day');

      // The start time steps back to the last day of the week
      if (startOfToday.day() === 0) {
        scheduledDOW = 6;
        // The start time steps back to the previous day of the week
      } else {
        scheduledDOW = startOfToday.day() - 1;
      }
      // It's currently the first of the two days
    } else {
      endMoment.add(1, 'day');
      scheduledDOW = startOfToday.day();
    }
    // The spending period is constrained within a single day
  } else {
    scheduledDOW = startOfToday.day();
  }
  const lastPurchaseMoment = moment(lastPurchaseEpoch);

  const isCurrentDay =
    [dowSun, dowMon, dowTue, dowWed, dowThu, dowFri, dowSat][scheduledDOW] ===
    true;

  if (
    now.isSameOrAfter(startMoment, 'minutes') &&
    now.isBefore(endMoment.endOf('day'), 'minutes') &&
    isCurrentDay &&
    lastPurchaseMoment.isSameOrAfter(startMoment, 'minutes') &&
    lastPurchaseMoment.isBefore(endMoment, 'minutes')
  ) {
    return [lastPurchasePeriodSpend, dailySpendLimit];
  }
  return [0, isCurrentDay ? dailySpendLimit : 0];
};

export const minutesToTime = (minutes: number): string => {
  // Remove additional days
  minutes %= 1440;

  let hours = Math.floor(minutes / 60);
  minutes %= 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export const timeToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map((s) => parseInt(s, 10));

  if (Number.isNaN(hours)) {
    return 0;
  }
  return hours * 60 + (Number.isNaN(minutes) ? 0 : minutes);
};

const formatTimeString = (minutes: number): string => {
  if (minutes === 0) {
    return 'midnight';
  }

  if (minutes === 720) {
    return 'noon';
  }
  const hours = Math.floor(minutes / 60);
  let hoursString;

  if (hours === 0) {
    hoursString = '12';
  } else if (hours > 12) {
    hoursString = `${hours - 12}`;
  } else {
    hoursString = `${hours}`;
  }
  minutes %= 60;

  let minutesString;

  if (minutes < 10) {
    minutesString = `0${minutes}`;
  } else {
    minutesString = `${minutes}`;
  }
  return `${hoursString}:${minutesString}${hours >= 12 ? 'pm' : 'am'}`;
};

const formatTimeRangeString = (startTime, endTime) => {
  let nextDayString;

  if (startTime > endTime) {
    nextDayString = ' the next day';
  } else {
    nextDayString = '';
  }

  if (endTime === 1440) {
    endTime = 0;
  }

  if (startTime === endTime) {
    return '';
  }

  if (startTime === 0) {
    return ` until ${formatTimeString(endTime)}`;
  }

  if (endTime === 0) {
    return ` starting at ${formatTimeString(startTime)}`;
  }
  return ` from ${formatTimeString(startTime)} until ${formatTimeString(
    endTime
  )}${nextDayString}`;
};

const getDays = (spendingPeriod) => [
  spendingPeriod.dowSun,
  spendingPeriod.dowMon,
  spendingPeriod.dowTue,
  spendingPeriod.dowWed,
  spendingPeriod.dowThu,
  spendingPeriod.dowFri,
  spendingPeriod.dowSat,
];

const getActiveDaysCount = (days) => days.filter((dow) => !!dow);

export const sumSpendingPeriods = (spendingPeriods) =>
  spendingPeriods.reduce(
    (runningTotal, spendingPeriod) =>
      runningTotal +
      getActiveDaysCount(getDays(spendingPeriod)).length *
        spendingPeriod.dailySpendLimit,
    0
  );

export const getSpendingPeriodString = (spendingPeriod) => {
  const { dailySpendLimit, endTime, startTime } = spendingPeriod;
  const days = getDays(spendingPeriod);
  const activeDays = getActiveDaysCount(days);

  let dowString;

  if (activeDays.length === 0) {
    return 'Never active.';
  }

  if (activeDays.length === 1) {
    dowString = `each ${
      [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ].filter((dow, idx) => !!days[idx])[0]
    }`;
  } else if (activeDays.length === 7) {
    dowString = 'per day';
  } else if (activeDays.length === 2 && days[0] && days[6]) {
    dowString = 'per weekend day';
  } else if (activeDays.length === 5 && !days[0] && !days[6]) {
    dowString = 'per weekday';
  } else if (activeDays.length > 4) {
    const notActive = [
      'Sundays',
      'Mondays',
      'Tuesdays',
      'Wednesdays',
      'Thursdays',
      'Fridays',
      'Saturdays',
    ].filter((dow, idx) => !days[idx]);

    dowString = `per day except ${notActive[0]}${
      activeDays.length === 5 ? ` and ${notActive[1]}` : ''
    }`;
  } else {
    const active = [
      'Sundays',
      'Mondays',
      'Tuesdays',
      'Wednesdays',
      'Thursdays',
      'Fridays',
      'Saturdays',
    ].filter((dow, idx) => !!days[idx]);

    dowString = `per day on ${active
      .slice(0, -1)
      .join(', ')} and ${active.slice(-1)}`;
  }
  let timeRangeString = formatTimeRangeString(startTime, endTime);

  if (timeRangeString !== '') {
    timeRangeString = ` ${timeRangeString}`;
  }
  return `Can spend up to ${formatCurrencyForDisplay(
    dailySpendLimit
  )} ${dowString}${timeRangeString}.`;
};

export const getSpendingPeriodsString = (spendingPeriods) => {
  if (spendingPeriods.length === 0) {
    return 'No active spending periods.';
  }

  if (spendingPeriods.length === 1) {
    return getSpendingPeriodString(spendingPeriods[0]);
  }
  return `${spendingPeriods.length} active spending periods.`;
};
