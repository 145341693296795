import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SelectField } from '@availity/select';
import { SelectFieldProps } from '@availity/select/typings/SelectField';
import { getMyAgentEmailDestinations } from '@/graphql/queries';
import { useUser } from '@/hooks';

const MyAgentDestinationsSelect: React.FC<SelectFieldProps<any>> = ({
  ...props
}) => {
  const { user } = useUser();
  // console.log(user.agentID);
  const { data, loading } = useQuery(getMyAgentEmailDestinations, {
    variables: { agentID: user.agentID },
  });
  console.log(data);

  const destinations = data?.getMyAgentEmailDestinations || [];

  return (
    <SelectField
      additional={{
        page: 1,
      }}
      isLoading={loading}
      getOptionLabel={(opt: any) => {
        return `${opt.label || opt.destinationName}`;
      }}
      defaultOptions
      raw
      options={destinations}
      {...props}
    />
  );
};

export default MyAgentDestinationsSelect;
