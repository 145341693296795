import React from 'react';
import { Badge } from 'reactstrap';
import { useFormikContext } from 'formik';
import { FaTimes } from 'react-icons/fa';
import relativeRanges from '@/utils/relativeRanges';

interface SearchPageProps {
  name: string;
  value: string;
  remove?: () => void;
}

// TODO - abstract
const leadTypeOptions = [
  {
    label: 'Marketplace',
    value: 'vendor',
  },
  {
    label: 'Agent Direct',
    value: 'agentDirect',
  },
  {
    label: 'Live Transfer',
    value: 'liveTransfer',
  },
];

const getValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'true' : undefined;
  }

  if (typeof value === 'object') {
    if (value?.startDate === undefined || value?.endDate === 'undefined') {
      return null;
    }
    // date range
    const found = Object.entries(relativeRanges).find(([_, range]) => {
      if (
        range?.startDate.isSame(value.startDate) &&
        range?.endDate.isSame(value.endDate)
      ) {
        return true;
      }
    });

    return found ? found[0] : `${value.startDate} - ${value.endDate}`;
  }
  return value;
};

const SearchBadge: React.SFC<SearchPageProps> = ({ name, value, remove }) => {
  const displayValue = getValue(value);
  return displayValue ? (
    <div className="h5 mr-2 mb-0">
      <Badge pill color="primary" className="d-flex">
        {name} : {displayValue}{' '}
        {remove && <FaTimes className="ml-1 cursor-pointer" onClick={remove} />}
      </Badge>
    </div>
  ) : null;
};
function toTitleCase(str: string) {
  return str?.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const SearchBadges: React.FC = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      <span className="mr-3 pr-3 border-right">Filtering Search on: </span>
      <SearchBadge
        name="search"
        value={values.searchText}
        remove={() => setFieldValue('searchText', '')}
      />
      <SearchBadge
        name="Type"
        value={leadTypeOptions.find((o) => o.value === values.leadType)?.label}
        remove={() => setFieldValue('leadType', null)}
      />
      <SearchBadge
        name="Type"
        value={toTitleCase(values.transferType)}
        remove={() => setFieldValue('leadType', null)}
      />
      {/* <SearchBadge
        name="Vendor"
        value={values.rightFilter?.key}
        remove={() => setFieldValue('rightFilter', null)}
      /> */}
      <SearchBadge
        name="Campaign"
        value={values.campaign?.campaignName}
        remove={() => setFieldValue('campaign', null)}
      />
      <SearchBadge
        name="Status"
        value={values.status}
        remove={() => setFieldValue('status', undefined)}
      />
      <SearchBadge
        name="Refund Type"
        value={values.refundType?.toLowerCase()}
        remove={() => setFieldValue('refundType', undefined)}
      />
      <SearchBadge
        name="Agent"
        value={
          values.leftFilter
            ? `${values.leftFilter.firstName} ${values.leftFilter.lastName}`
            : null
        }
        remove={() => setFieldValue('leftFilter', null)}
      />
      <SearchBadge name="Range" value={values.dateRange} />
    </div>
  );
};

export default SearchBadges;
