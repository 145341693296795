import React from 'react';
import classNames from 'classnames';
import { Table } from 'reactstrap';
import { Tooltip } from '@/components';

interface TableProps {
  className: string;
  children: any;
}
const AgentTable: React.SFC<TableProps> = ({
  children,
  className,
  ...props
}) => (
  <Table className={classNames(className, 'text-left')} hover {...props}>
    <thead>
      <tr>
        <th className="pl-4 text-left" scope="col">
          {/* Automatic Credit Transfer Rules */}
        </th>
        <th scope="col" style={{ width: '15%', wordWrap: 'break-word' }}>
          Agent Name
        </th>
        <th scope="col" style={{ width: '20%', textAlign: 'left' }}>
          Email
        </th>
        <th scope="col" style={{ width: '15%' }}>
          Credit{' '}
          <Tooltip
            id="agentTableCredit"
            helpMessage="Reflects the balance of the agent based on the your bucket selection above"
          />{' '}
        </th>
        <th scope="col" style={{ width: '15%' }}>
          Contributed{' '}
          <Tooltip
            id="agentTableContributed"
            helpMessage="Reflects the balance you contributed to the agent based on the your bucket selection above"
          />{' '}
        </th>
        <th
          scope="col"
          style={{
            width: '15%',
          }}
        >
          Transfer Amount
        </th>
        <th
          scope="col"
          style={{
            width: '15%',
          }}
        >
          Final Credit
        </th>
      </tr>
    </thead>
    {children}
    <tfoot>
      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    </tfoot>
  </Table>
);
export default AgentTable;
