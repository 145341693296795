import moment from 'moment';
import React from 'react';
import { FaCheckCircle, FaHourglassStart, FaTimesCircle } from 'react-icons/fa';
import { formatCurrencyForDisplay } from '@/utils';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export interface ChargeRowProps {
  amount: number;
  chargeStatus: 'failed' | 'pending' | 'succeeded' | 'uncaptured';
  currency: 'USD';
  last4: number;
  timestamp: number;
}

const ChargeRow: React.SFC<ChargeRowProps> = ({
  amount,
  chargeStatus,
  currency,
  last4,
  timestamp,
}) => {
  const momentTimestamp = moment(timestamp);
  const date = momentTimestamp.format('YYYY-MM-DD');
  const time = momentTimestamp.format('h:mma');
  const formattedAmount = formatCurrencyForDisplay(amount);

  let chargeIcon;

  if (chargeStatus === 'succeeded') {
    chargeIcon = (
      <FaCheckCircle
        className="fa fa-check-circle"
        style={{ color: '#28a745' }}
      />
    );
  } else if (chargeStatus === 'pending') {
    chargeIcon = <FaHourglassStart style={{ color: '#ffc107' }} />;
  } else if (chargeStatus === 'uncaptured') {
    chargeIcon = <FaHourglassStart style={{ color: '#ffffff' }} />;
  } else {
    chargeIcon = <FaTimesCircle style={{ color: '#dc3545' }} />;
  }
  return (
    <tr>
      <td title={chargeStatus}>{chargeIcon}</td>
      <td title={chargeStatus}>{capitalize(chargeStatus)}</td>
      <td title={date}>{date}</td>
      <td title={time}>{time}</td>
      <td title={formattedAmount}>{formattedAmount}</td>
      <td title={currency}>{currency}</td>
      <td title={(last4 as unknown) as string}>**** **** **** {last4}</td>
    </tr>
  );
};

export default ChargeRow;
