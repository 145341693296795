export default [
  {
    dataField: 'leadBulkID',
    displayName: 'Order ID',
  },
  {
    dataField: 'priceTier',
    displayName: 'Tier',
  },
  {
    dataField: 'price',
    displayName: 'Tier Price',
  },
  {
    dataField: 'created',
    displayName: 'Order Date',
  },
  {
    dataField: 'status',
    displayName: 'Order Status',
  },
  {
    dataField: 'requestedLeadCount',
    displayName: 'Leads',
  },
  {
    dataField: 'estimatedPrice',
    displayName: 'Price',
  },
  {
    dataField: 'completedLeadsCSVFileName',
    displayName: 'Report',
  },
];
