import React, { useMemo } from 'react';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import { Card, CardTitle, Col, FormGroup, Row } from 'reactstrap';
import { formatCurrencyForDisplay } from '@/utils';
import { useUser, usePriceTiers } from '@/hooks';
import { useFormikContext } from 'formik';
import { getPrivacyPolicy, getTermsAndConditions } from '@/utils/documents';
import classNames from 'classnames';

const getTitle = () => {
  if (window.location.href.includes('leadtopia')) {
    return 'LeadTopia';
  }

  if (window.location.href.includes('americafirstleads')) {
    return 'America First Leads';
  }

  return window.location.href.includes('sonic')
    ? 'Sonic Leads'
    : 'USHA Marketplace';
};

const PriceTierCompliance: React.FC<{
  type: 'campaign' | 'bulk';
  activeTiers?: string[];
}> = ({ type, activeTiers = [] }) => {
  const { user } = useUser();

  const { initialValues = { priceTierID: '' }, values } = useFormikContext();

  const { data: tierData = {} } = usePriceTiers();

  let tiers: any[] =
    tierData?.tiers?.filter((t) => {
      return !activeTiers.includes(t.priceTierID);
    }) || [];

  const isLiveTransfers = useMemo(
    () =>
      (
        tiers.find((t: any) => t.priceTierID === initialValues?.priceTierID) ||
        {}
      ).liveTransfers,
    [tierData.tiers]
  );

  if (isLiveTransfers) {
    tiers = tiers.filter((t) => t.liveTransfers);
  } else if (type === 'bulk') {
    tiers = tiers.filter((t) => {
      return t.bulkPurchase;
    });
  } else {
    tiers = tiers.filter((t) => {
      return !t.bulkPurchaseOnly;
    });
  }

  const attributePrefix =
    type === 'campaign' ? 'edit-spending-period' : 'bulk-purchase';
  return (
    <Card body className="mb-3" id={`${attributePrefix}-general-card`}>
      <CardTitle
        id={`${attributePrefix}-general-card-title`}
        tag="h4"
        className="lead text-uppercase"
      >
        General Information
      </CardTitle>
      <div className={classNames({ 'd-none': type !== 'campaign' })}>
        <Field
          data-testid={`${attributePrefix}-general-campaign-name`}
          label="Campaign Name"
          name="campaignName"
          placeholder="Campaign Name...."
        />
      </div>
      <Row>
        <Col>
          <SelectField
            id={`${attributePrefix}-general-priceTier-dropdown`}
            name="priceTierID"
            placeholder="Select a Tier..."
            options={tiers}
            label="Tier"
            valueKey="priceTierID"
            getOptionLabel={(opt: any) => {
              if (opt.dynamicPrice) {
                return `${opt.description} - Price: ${formatCurrencyForDisplay(
                  opt.minPrice
                )} - ${formatCurrencyForDisplay(
                  opt.maxPrice
                )} (Avg: ${formatCurrencyForDisplay(opt.price)}) `;
              }
              return `${opt.description} - ${formatCurrencyForDisplay(
                opt.price
              )}`;
            }}
          />
        </Col>
        <Col
          xs={3}
          className={classNames({
            'd-none':
              type === 'campaign' ||
              !values.priceTier?.name ||
              values.tierTimeout > 0,
          })}
        >
          {values?.priceTier?.bulkPurchaseCustom ? (
            <Field
              id={`${attributePrefix}-general-custom-quantity`}
              name="requestedLeadCount"
              label="Quantity"
              type="number"
              placeholder="Lead Quantity..."
              max={values?.priceTier?.bulkPurchaseMax}
            />
          ) : (
            <SelectField
              id={`${attributePrefix}-general-dropdown-quantity`}
              name="requestedLeadCount"
              options={[{ value: 500 }, { value: 1000 }, { value: 2000 }]}
              valueKey="value"
              labelKey="value"
              label="Quantity"
            />
          )}
        </Col>
      </Row>
      <FormGroup>
        <Field
          data-testid="term-check-box"
          label={
            user?.corpID === 'sonic' || user?.corpID === 'leadtopia' ? (
              <p>
                I have read and agree to the{' '}
                <a
                  href={getTermsAndConditions(user.corpID)}
                  rel="noopener noreferrer"
                  // style={{ color: 'blue' }}
                  target="_blank"
                >
                  terms and conditions
                </a>
                .
              </p>
            ) : (
              <span>
                By agreeing, I recognize that any leads purchased through{' '}
                {getTitle()} have been offered at a previously discounted market
                price and that no lead returns will be issued accordingly.
                Please visit our full{' '}
                <a
                  href={getTermsAndConditions(user.corpID)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={getPrivacyPolicy(user.corpID)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                for more information about Marketplace lead services.
              </span>
            )
          }
          name="agreed"
          type="checkbox"
        />
      </FormGroup>
    </Card>
  );
};

export default PriceTierCompliance;
