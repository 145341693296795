import gql from 'graphql-tag';
import { AgentFragment } from '../fragments';

export default gql`
  mutation updateProfile($input: UpdateOneAgentInput) {
    accountUpdate(input: $input) {
      ...AgentFragment
    }
  }
  ${AgentFragment}
`;
