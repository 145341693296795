import React from 'react';
import {
  UncontrolledTooltip,
  Label,
  UncontrolledTooltipProps,
} from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';

interface TooltipProps extends Omit<UncontrolledTooltipProps, 'target'> {
  id: string;
  helpMessage: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  id,
  helpMessage,
  ...props
}) => (
  <>
    <Label className="mb-0 mr-3">
      <FaQuestionCircle className="ml-1" id={`tooltip-${id}`} />
    </Label>
    <UncontrolledTooltip placement="top" {...props} target={`tooltip-${id}`}>
      {helpMessage}
    </UncontrolledTooltip>
  </>
);

export default Tooltip;
