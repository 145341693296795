/* eslint-disable no-return-assign */
import React, { useMemo, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Table,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import Progress from 'react-progressbar';

import { campaignPagination, currentAgentQuery } from '@/graphql/queries';
import { campaignTogglePause } from '@/graphql/mutations';
import { LoadingComponent, Tooltip } from '@/components';
import {
  useAccount,
  useCampaignsNetwork,
  useCreditBuckets,
  useNotifications,
  usePageLoader,
  useUser,
} from '@/hooks';
import formatCurrencyForDisplay from '@/areas/MyAgents/Utils';
import classNames from 'classnames';
import CampaignTableRow from './CampaignRow';
import {
  getProgress,
  getProgressColor,
  localizeSpendingPeriod,
} from '../Utils';
import { isTemplateTail } from 'typescript';

const CampaignTable: React.FunctionComponent<any> = ({
  navigate,
  setCurrentCampaign,
  setOpenModal,
  paused,
}) => {
  const { user } = useUser();
  const { newLeadCount, create: createNotification } = useNotifications();
  const { networkID: selectedNetworkID, changeNetwork } = useCampaignsNetwork(
    user.networkID
  );
  const { account, loading: accountLoading } = useAccount();
  const { complete, start } = usePageLoader();
  let { data, loading: loadingCampaigns, refetch } = useQuery(
    campaignPagination,
    {
      variables: {
        networkID: selectedNetworkID,
      },
      skip: selectedNetworkID === '',
      pollInterval: 300000,
    }
  );

  useEffect(() => {
    if (newLeadCount > 1) {
      refetch();
    }
  }, [newLeadCount, refetch]);

  const campaigns = useMemo(() => {
    return (
      data?.campaigns.filter(({ networkID }: any) => {
        return networkID === selectedNetworkID;
      }) || []
    ).map((c) => ({
      ...c,
      spendingPeriods: (c.spendingPeriods || []).map((s) =>
        localizeSpendingPeriod(s, moment().utcOffset())
      ),
    }));
  }, [data, selectedNetworkID]);

  const [_togglePause, { loading: updatingPause }] = useMutation(
    campaignTogglePause,
    {
      update(cache, data) {
        const {
          campaignTogglePause: { success, value },
        } = data.data as any;

        if (success) {
          let { campaigns }: any = cache.readQuery({
            query: campaignPagination,
            variables: {
              networkID: selectedNetworkID,
            },
          });

          campaigns = campaigns.filter(({ networkID }: any) => {
            return networkID === selectedNetworkID;
          });

          campaigns.forEach(({ id, spendingPeriods, ...rest }: any) => {
            if (spendingPeriods.length > 0) {
              cache.writeData({
                id,
                data: {
                  ...rest,
                  paused: value,
                },
              });
            }
          });
          createNotification({
            message: 'Successfully updated',
            type: 'alert',
            action: 'MESSAGE',
            color: 'success',
            timeout: 1000,
          });
        }
      },
      onError: (error) =>
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          color: 'danger',
          type: 'alert',
          action: 'MESSAGE',
          timeout: 3000,
        }),
    }
  );

  const currentMoment = moment();
  let totalSpend = 0;
  let maxTotalSpend = 0;
  let maxWeeklySpend = 0;

  const activeCampaigns: string[] = [];

  campaigns.forEach(({ spendingPeriods, campaignID }: any) => {
    if (spendingPeriods.length > 0) activeCampaigns.push(campaignID);
  });

  const activeTierHash = campaigns.reduce(
    (
      temp: any,
      { priceTier: { priceTierID, maxCampaignCount } }: any
    ): string[] => {
      if (temp[priceTierID]) {
        temp[priceTierID].count += 1;
      } else {
        temp[priceTierID] = { count: 1, maxCount: maxCampaignCount };
      }
      return temp;
    },
    {}
  );

  const activeTiers = Object.keys(activeTierHash).reduce(
    (temp: string[], key: string) => {
      if (activeTierHash[key].count >= activeTierHash[key].maxCount) {
        temp.push(key);
      }
      return temp;
    },
    []
  );

  const pauseAll = () => {
    _togglePause({
      variables: {
        input: {
          IDs: activeCampaigns,
          on: true,
        },
      },
    });
  };

  const resumeAll = () => {
    if (user.totalCredit <= 0) {
      createNotification({
        message:
          'You have $0.00 in your account, please add funds or consider enabling auto-funding.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
        timeout: 4000,
      });
      return;
    }
    _togglePause({
      variables: {
        input: {
          IDs: activeCampaigns,
          on: false,
        },
      },
    });
  };
  campaigns
    .filter((campaign: any) => !campaign.paused)
    .forEach((campaign: any) => {
      const { spendingPeriods = [] } = campaign || {};
      spendingPeriods.forEach((s) => {
        const [dailySpend, maxSpend] = getProgress(s, currentMoment);

        totalSpend += dailySpend;
        maxTotalSpend += maxSpend;

        [s.dowSun, s.dowMon, s.dowTue, s.dowWed, s.dowThu, s.dowFri, s.dowSat]
          .filter((dow) => !!dow)
          .forEach(() => {
            maxWeeklySpend += maxSpend;
          });
      });
    });

  const progress = (totalSpend / maxTotalSpend) * 100;

  useEffect(() => {
    if (!loadingCampaigns) {
      complete();
    } else {
      start();
    }
  }, [complete, loadingCampaigns, start]);

  if (loadingCampaigns && !campaigns) {
    return <LoadingComponent />;
  }

  const showCreditSummary =
    user?.flags.allowCreditSummary && user.contributedCredit >= 0;
  return (
    <Container style={{ maxWidth: 1300 }}>
      <Nav tabs className="flex-fill border-0">
        {user.networks?.map((network) => (
          <NavItem key={network.networkID}>
            <NavLink
              className={classNames('cursor-pointer', {
                'bg-secondary': network.networkID === selectedNetworkID,
              })}
              onClick={() => changeNetwork(network.networkID)}
              active={network.networkID === selectedNetworkID}
            >
              {network.networkName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <CardTitle tag="h5" className="py-2 h4 mb-0">
            Campaigns
          </CardTitle>
          <div>
            {maxTotalSpend > 0 && (
              <>
                <div>
                  <Progress
                    color={getProgressColor(progress)}
                    completed={progress}
                  />
                </div>
                {formatCurrencyForDisplay(maxTotalSpend - totalSpend)} remaining
                today.
              </>
            )}
          </div>
          <div>
            <Button
              color="success"
              disabled={accountLoading}
              onClick={() => {
                if (!account?.card) {
                  createNotification({
                    message: 'Please add a credit card to your account.',
                    type: 'alert',
                    action: 'MESSAGE',
                    color: 'danger',
                    timeout: 4000,
                  });
                  return;
                }
                setOpenModal('add_credit');
              }}
            >
              {accountLoading ? (
                <div className="px-4">
                  <Spinner size="sm" />
                </div>
              ) : (
                'Add Credit'
              )}
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-block">
          <div>
            <Table>
              <thead>
                <tr>
                  <td
                    style={{
                      paddingTop: '26px',
                      width: '14%',
                    }}
                  >
                    <Button
                      color="primary"
                      data-testid="new-campaign-button"
                      onClick={() => {
                        navigate(`/campaigns/new`, activeTiers);
                      }}
                    >
                      New Campaign
                    </Button>
                  </td>
                  <td
                    style={{
                      paddingTop: '26px',
                      width: '16%',
                    }}
                  >
                    <ButtonGroup>
                      <Button
                        color="danger"
                        disabled={campaigns.every(
                          ({ paused }) => paused === true
                        )}
                        onClick={pauseAll}
                      >
                        Pause
                      </Button>
                      <Button
                        color="success"
                        disabled={campaigns.every(
                          ({ paused }) => paused === false
                        )}
                        onClick={resumeAll}
                      >
                        Resume
                      </Button>
                    </ButtonGroup>
                  </td>
                  <td />
                  {!showCreditSummary && <td />}
                  <td style={{ width: '17%' }}>
                    Max Total Spend
                    <br />
                    <h5>{formatCurrencyForDisplay(maxWeeklySpend)}/wk</h5>
                  </td>
                  {showCreditSummary ? (
                    <td style={{ width: '14%' }}>
                      Transfer Credit
                      <br />
                      <h5>
                        {formatCurrencyForDisplay(
                          user.totalCredit - user.contributedCredit || 0
                        )}
                      </h5>
                    </td>
                  ) : (
                    <td style={{ width: '14%' }}>
                      Total Credit
                      <br />
                      <h5>{formatCurrencyForDisplay(user.totalCredit || 0)}</h5>
                    </td>
                  )}
                  {showCreditSummary && (
                    <td style={{ width: '14%' }}>
                      <span className="d-flex align-items-center">
                        Your Credit{' '}
                        <Tooltip
                          id="contributed-credit"
                          helpMessage="Credit you have added to your account."
                        />
                      </span>
                      <h5>
                        {formatCurrencyForDisplay(user.contributedCredit || 0)}
                      </h5>
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <b>Campaign Name</b>
                  </td>
                  <td className="text-center">
                    <b>Running</b>
                  </td>
                  <td className="text-left">
                    <b>Active Spending Periods</b>
                  </td>
                  <td />
                  <td>
                    <b>Max Campaign Spend</b>
                  </td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign: any, index: number) => (
                  <CampaignTableRow
                    {...campaign}
                    key={campaign.campaignID}
                    updatingPause={updatingPause}
                    setCurrentCampaign={() =>
                      setCurrentCampaign({ campaign, index })
                    }
                    navigateToEditCampaignPage={() =>
                      navigate(`/campaigns/${campaign.id}`, activeTiers)
                    }
                    openModal={setOpenModal}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CampaignTable;
