import gql from 'graphql-tag';

export default gql`
  mutation serviceCharge($cardId: String, $serviceID: String) {
    accountServiceCharge(cardId: $cardId, serviceID: $serviceID) {
      result {
        lastCallerReadyChargeResult
        callerReadyStatus
        callerReadyAccountStatus
        lastCallerReadyChargeResult
        lastCallerReadyCharge
        callerReadyChargeStatus
      }
      charge {
        amount
        status
        id
        captured
        source {
          last4
        }
        created
      }
    }
  }
`;
