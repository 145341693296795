import gql from 'graphql-tag';
import { AgentFragment } from '@/graphql/fragments';

export default gql`
  query agentPagination(
    $filter: AgentCollectionFilters
    $orderBy: AgentOrderInput
    $page: Int
    $perPage: Int
  ) {
    agentPagination(
      filter: $filter
      orderBy: $orderBy
      page: $page
      perPage: $perPage
    ) {
      count
      items {
        ...AgentFragment
      }
      pageInfo {
        pageCount
        currentPage
        hasNextPage
      }
    }
  }
  ${AgentFragment}
`;
