import { useUser } from '@/hooks';
import { getPrivacyPolicy } from '@/utils/documents';
import React from 'react';
import { Container, Button } from 'reactstrap';
import pkg from '../../../package.json';

const Footer: React.FC = () => {
  const { user } = useUser();
  return (
    <Container
      fluid
      className="d-flex flex-row justify-content-center align-items-end flex-fill my-3"
    >
      <div className="d-flex align-items-center">
        <span>{`Version ${pkg.version}`}</span>
        <Button
          href={getPrivacyPolicy(user?.corpID)}
          rel="noopener noreferrer"
          target="_blank"
          color="link"
        >
          Privacy Policy
        </Button>
      </div>
    </Container>
  );
};

export default Footer;
