import gql from 'graphql-tag';

export default gql`
  subscription networkNotification {
    notification: networkNotification {
      action
      data
      timeout
      notificationID
    }
  }
`;
