/* eslint-disable no-unused-expressions */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardHeader,
  Badge,
} from 'reactstrap';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  FaPersonBooth,
  FaCog,
  FaLaptop,
  FaCreditCard,
  FaPhoneSlash,
  FaConnectdevelop,
} from 'react-icons/fa';
import { useUser, usePageLoader } from '@/hooks';
import classNames from 'classnames';
import { BackButton } from '@/components';
import { getDataDisclosure } from '@/utils/documents';
import Agents from './components/AgentTable';
import CRMProfiles from './components/CRMProfiles';
import General from './components/General';
import Services from './components/Services';
import Connections from './components/Connections';
import Account from './components/Account';
import LeadRefunds from './components/LeadRefunds';
import useProfile from './useProfile';

const tabs = [
  {
    label: 'General',
    Icon: FaCog,
    value: 'general',
  },
  {
    label: 'Account',
    Icon: FaCreditCard,
    value: 'account',
    currentUser: true,
  },
  {
    label: 'Lead Refunds',
    Icon: FaPhoneSlash,
    value: 'lead-refunds',
    currentUser: true,
  },
  {
    label: 'Integrations',
    Icon: FaPersonBooth,
    value: 'crm',
  },
  {
    label: 'Agents',
    Icon: FaLaptop,
    value: 'agents',
    hide: (user: any) => user?.role === 'user',
  },
  {
    label: 'Services',
    Icon: FaConnectdevelop,
    value: 'services',
    hide: (user: any) => !user?.network.services,
  },
  {
    label: 'Connections',
    Icon: FaConnectdevelop,
    value: 'connections',
    // hide: (user: any) => !user?.network.crmConnections,
  },
];

const ProfilePage: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ match }) => {
  const isMe = match.params.id === 'me';
  const [activeTab, setActiveTab] = useState('general');
  // eslint-disable-next-line unicorn/consistent-function-scoping
  useEffect(() => () => setActiveTab('general'), [match.params.id]);

  const { complete = () => {}, start = () => {} } = usePageLoader();
  const { loading: loadingUser, data: user } = useProfile();
  const { loading: loadingCurrentUser, user: currentUser } = useUser();

  const navTabs = useMemo(
    () =>
      tabs.map(
        ({ label, Icon, value, currentUser, hide }) =>
          (!hide || !hide(user)) && (
            <NavItem
              key={value}
              className={classNames(
                'profile-nav-item cursor-pointer rounded mb-0',
                {
                  'bg-dark border border-secondary': activeTab === value,
                  'd-none': !isMe && currentUser,
                }
              )}
              onClick={() => setActiveTab(value)}
              active={activeTab === value}
            >
              <NavLink
                active={activeTab === value}
                className="pl-2 py-3 h5 d-flex align-items-center mb-0"
              >
                <Icon className="mr-2" /> {label}
              </NavLink>
            </NavItem>
          )
      ),
    [activeTab, isMe, user]
  );

  useEffect(() => {
    if (!loadingUser && !loadingCurrentUser) {
      complete();
    } else {
      start();
    }
  }, [complete, loadingUser, loadingCurrentUser, start]);

  if (loadingUser || loadingCurrentUser || !user) {
    return null;
  }

  return (
    <Container className="d-flex">
      <div style={{ minWidth: 260 }} className="pt-3">
        <div className="mb-4 d-flex align-items-center">
          {currentUser && currentUser.agentApproved && (
            <BackButton
              hideText
              color="primary"
              path="/myagents"
              className="mr-1"
            />
          )}
          <h3 className="font-weight-bold mb-0">
            {isMe ? 'My' : 'Agent'} Profile
          </h3>
        </div>

        <Nav vertical>{navTabs}</Nav>
      </div>
      <Container>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="general">
            <Card className="mb-3">
              <CardHeader>
                <CardTitle
                  tag="h5"
                  className="py-2 h4 mb-0 d-flex align-items-center justify-content-between"
                >
                  <span className="d-flex">
                    Agent Information{' '}
                    <aside className="text-muted ml-2" style={{ fontSize: 16 }}>
                      {user.corpID}
                    </aside>
                  </span>
                  <Badge color="primary">{user.role}</Badge>
                </CardTitle>
              </CardHeader>
              <General />
            </Card>
            <a
              href={getDataDisclosure(user.corpID)}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-4"
            >
              Data Disclosure
            </a>
          </TabPane>
          {isMe && (
            <TabPane tabId="account">
              <Account />
            </TabPane>
          )}
          <TabPane tabId="crm">
            <CRMProfiles />
          </TabPane>
          <TabPane tabId="agents">
            <Agents />
          </TabPane>
          <TabPane tabId="lead-refunds">
            <LeadRefunds />
          </TabPane>
          {user.network.services ? (
            <TabPane tabId="services">
              <Services />
            </TabPane>
          ) : null}
          {/* {user.network.services ? ( */}
          <TabPane tabId="connections">
            <Connections />
          </TabPane>
          {/*  ) : null} */}
        </TabContent>
      </Container>
    </Container>
  );
};

export default ProfilePage;
