/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { FaLock } from 'react-icons/fa';
import LoadingButton from '@gopro16/button';
import { Vars } from '@/utils';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNotifications } from '@/hooks';

const sonicStripe = loadStripe(Vars.sonicStripeKey);
const ushaStripe = loadStripe(Vars.ushaStripeKey);
const leadtopiaStripe = loadStripe(Vars.leadtopiaStripeKey);
const americafirstStripe = loadStripe(Vars.americafirstStripeKey);

const styleOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a',
      },
    },
  },
};

const Checkout: React.SFC<{
  onCardAdded: (cardId: string) => void;
  updatingCard: boolean;
}> = ({ onCardAdded, updatingCard }) => {
  const stripe = useStripe();
  const [creatingToken, setCreatingToken] = useState(false);
  const { create: createNotification } = useNotifications();
  const elements = useElements();

  const handleSubmit = async (ev: React.SyntheticEvent<any>): Promise<void> => {
    ev.preventDefault();

    setCreatingToken(true);

    const cardElement = elements!.getElement(CardElement);

    if (stripe) {
      const { error, token } = await stripe.createToken(cardElement!);

      if (error) {
        createNotification({
          message: error.message!,
          color: 'danger',
          type: 'alert',
          action: 'MESSAGE',
        });
      } else {
        onCardAdded(token!.id);
      }
    }
    setCreatingToken(false);
  };

  return (
    <Card body>
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <label>
          <img
            alt="Credit Card Logos"
            src="https://www.merchantequip.com/image/?logos=v%7Cm%7Ca%7Cd&height=32(5 kB)
https://www.merchantequip.com/image/?logos=v%7Cm%7Ca%7Cd&height=32"
            width="152"
          />
          <CardElement
            options={{
              style: styleOptions.style,
            }}
          />
        </label>
        <LoadingButton color="success" loading={updatingCard || creatingToken}>
          <span style={{ marginRight: '10px' }}>Save Card</span>
          <FaLock />
        </LoadingButton>
      </form>
    </Card>
  );
};

const getStripe = (corpID: string) => {
  if (corpID === 'sonic') {
    return sonicStripe;
  }

  if (corpID === 'leadtopia') {
    return leadtopiaStripe;
  }

  if (corpID === 'americafirst') {
    return americafirstStripe;
  }

  return ushaStripe;
};

const Payment: React.SFC<{
  corpID: string;
  onCardAdded: (cardId: string) => void;
  updatingCard: boolean;
}> = ({ corpID, ...props }) => (
  <Card style={{ maxWidth: 550 }}>
    <Elements stripe={getStripe(corpID)}>
      <Checkout {...props} />
    </Elements>
  </Card>
);

export default Payment;
