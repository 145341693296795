import React from 'react';
import { usePagination } from '@availity/pagination';
import { FaSync, FaCheckCircle } from 'react-icons/fa';
import SearchBadges from './SearchBadges';

interface SummaryProps extends React.HTMLAttributes<HTMLParagraphElement> {
  hideBadges?: boolean;
}

const Summary: React.SFC<SummaryProps> = ({ hideBadges, ...props }) => {
  const { total, loading } = usePagination();

  return (
    <div {...props}>
      {!hideBadges && <SearchBadges />}
      <div className="d-flex align-items-center">
        {loading ? (
          <>
            <FaSync
              className="mr-2 text-warning loading-sync"
              title="Searching Transfers..."
              size={20}
            />{' '}
            Searching Leads...{' '}
          </>
        ) : (
          <>
            <FaCheckCircle
              title="Search Completed"
              className="mr-2 text-success"
              size={20}
            />{' '}
            Search Completed ( {total} Total )
          </>
        )}
      </div>
    </div>
  );
};

export default Summary;
