import gql from 'graphql-tag';
import { CampaignFragment } from '@/graphql/fragments';

export default gql`
  query nodeQuery($id: ID!) {
    node(id: $id) {
      __typename
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`;
