import React, { useState, useEffect } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { Button, ButtonProps } from 'reactstrap';

interface CopyButton extends Omit<ButtonProps, 'onClick'> {
  content: string;
}

const CopyButton: React.FC<CopyButton> = ({ content, ...props }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const tm = setTimeout(() => {
        setCopied(false);
      }, 1000);

      return () => clearTimeout(tm);
    }
    return () => {};
  }, [copied]);

  return (
    <Button
      color="secondary"
      {...props}
      onClick={() => {
        navigator.clipboard.writeText(content);
        setCopied(true);
      }}
    >
      {copied ? <FaCheck /> : <FaCopy />}
    </Button>
  );
};

export default CopyButton;
