import gql from 'graphql-tag';

export default gql`
  subscription reportNotification {
    notification: reportNotification {
      action
      data
      timeout
      notificationID
    }
  }
`;
