import gql from 'graphql-tag';
import LeadFragment from './leadFragment';

const AgentServiceFragment = gql`
  fragment AgentServiceFragment on AgentService {
    id
    __typename
    serviceCaseID
    agentID
    leadID
    networkID
    leadPhone
    modified
    refundOption
    refundReason
    refundType
    soldDate
    status
    created
    type
    amount
    summary
    lead {
      ...LeadFragment
    }
  }
  ${LeadFragment}
`;

export default AgentServiceFragment;
