import React, { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Button,
  Table,
  CardTitle,
  Container,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { FaEdit, FaShareAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useUser } from '@/hooks';
import { vendorLayers } from '@/graphql/queries';
import PriceBadge from '@/components/PriceBadge';
import { VendorLayersShareEmailModal } from '@/modals';

const VendorCampaigns: React.SFC = () => {
  const history = useHistory();
  const { loading, user } = useUser();
  const { data } = useQuery(vendorLayers);
  const [shareEmailId, setShareEmailId] = useState(undefined);

  useEffect(() => {
    if (!loading && user && user.flags?.allowVendors !== true) {
      history.push('/');
    }
  }, [user]);

  if (loading) {
    return null;
  }

  return (
    <Container>
      <VendorLayersShareEmailModal
        isOpen={!!shareEmailId}
        id={shareEmailId}
        dismiss={() => setShareEmailId(undefined)}
      />
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <CardTitle className="h4 mb-0 py-2" tag="h5">
            Vendor Campaigns
          </CardTitle>
          <Button color="success" onClick={() => history.push('/vendors/new')}>
            Create Vendor Campaign
          </Button>
        </CardHeader>
        <CardBody>
          <Table hover className="mt-5">
            <thead>
              <tr>
                <th className="border-top-0">Vendor</th>
                <th className="border-top-0">Campaign Name</th>
                <th className="border-top-0">Add On</th>
                <th className="border-top-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.vendorLayers.map((i) => (
                <tr key={i.layerID}>
                  <td>{i.vendor.vendorName}</td>
                  <td>{i.vendorSourceCampaign}</td>
                  <td>
                    <PriceBadge price={i.addonPrice} />
                  </td>
                  <td>
                    <ButtonGroup>
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        outline
                        onClick={() => history.push(`/vendors/${i.id}`)}
                      >
                        <FaEdit size={18} className="text-primary" />
                      </Button>
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        outline
                        onClick={() => setShareEmailId(i.id)}
                      >
                        <FaShareAlt size={18} className="text-primary" />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default VendorCampaigns;
