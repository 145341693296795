import gql from 'graphql-tag';
import { AgentFragment } from '@/graphql/fragments';

export default gql`
  query nodeQuery($id: ID!) {
    node(id: $id) {
      __typename
      ...AgentFragment
    }
  }
  ${AgentFragment}
`;
