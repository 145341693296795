import gql from 'graphql-tag';

export default gql`
  query currentAccountQuery {
    account: accountCurrent {
      __typename
      card {
        id
        last4
        brand
        exp_month
        exp_year
        address_zip
        customer
      }
      charges {
        amount
        status
        id
        captured
        source {
          last4
        }
        created
      }
    }
  }
`;
