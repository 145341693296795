import gql from 'graphql-tag';

export default gql`
  mutation createSupportTicket($description: String, $supportType: String) {
    accountCreateTicket(description: $description, supportType: $supportType) {
      __typename
      success
      ticket {
        __typename
        id
        subject
        description
        created
      }
    }
  }
`;
