import { useQuery } from '@apollo/react-hooks';
import { tiers as tiersQuery } from '@/graphql/queries';
import useCampaignsNetwork from './useCampaignsNetwork';

export default () => {
  const { networkID } = useCampaignsNetwork();

  return useQuery(tiersQuery, {
    variables: {
      networkID,
    },
  });
};
