import gql from 'graphql-tag';

const ConnectionVsoftFragment = gql`
  fragment ConnectionVsoftFragment on VsoftConnection {
    __typename
    filter
    username
    criteria {
      name
      value
    }
  }
`;

export default ConnectionVsoftFragment;
